import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/hunterform/event-types';

export const actions = {
	async HELPDESK_GET_HUNTERS_FROM_API({commit}) {
		return axios
			.get(VUE_APP_HELPDESK_API_URL + '/api/v1/hunters')
			.then((resp) => {
				commit(events.HELPDESK_SET_HUNTERS, resp.data);
				return resp.data;
			});
	},

	async HELPDESK_GET_HF_CITIES({commit}) {
		return axios
			.get(VUE_APP_HELPDESK_API_URL + '/api/v1/hunterform_cities')
			.then((resp) => {
				commit(events.HELPDESK_SET_HUNTERFORM_CITIES, resp.data);
				return resp.data;
			});
	},

	async HELPDESK_SEND_HUNTER_FORM({commit}, form) {
		return axios
			.post(VUE_APP_HELPDESK_API_URL + '/api/v1/hunterform', form)
			.then((resp) => {
				return resp.data;
			});
	},
};
