import {attributeNames, permissionNames} from '@/store/passport/auth/constants';
import {routeNames} from '@/router/constants';

export const navbarLinks = [
	{
		id: 1,
		referLink: '/helpdesk',
		icon: 'os-icon os-icon-layers',
		name: 'HelpDesk',
		permissionsList: [
			permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
			permissionNames.CAN_USE_HELPDESK_CITIES_API,
			permissionNames.CAN_USE_HELPDESK_PHONES_API,
			permissionNames.CAN_USE_HELPDESK_SITE_INFO_API,
			permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API,
			permissionNames.CAN_USE_HELPDESK_SIGNERS_API,
			permissionNames.CAN_USE_HELPDESK_SITES_API,
			permissionNames.CAN_USE_HELPDESK_PRIORITIES_API,
			permissionNames.CAN_USE_HELPDESK_HUNTERFORM_API,
			permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
			permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL,
			permissionNames.CAN_USE_HELPDESK_DISCOUNT,
			permissionNames.CAN_USE_HELPDESK_TARIFFS_API,
			permissionNames.CAN_USE_HELPDESK_BRANCH_CONSULTATIONS,
			permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
		],
		links: [
			{
				id: 1,
				name: routeNames.helpdesk.branches.read,
				displayedName: 'Филиалы',
				displayPermissions: [
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
					permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
					permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL,
				],
			},
			{
				id: 2,
				name: routeNames.helpdesk.branches.consultations,
				displayedName: 'Консультации в филиалах',
				displayPermission: permissionNames.CAN_USE_HELPDESK_BRANCH_CONSULTATIONS
			},
			{
				id: 3,
				name: routeNames.helpdesk.weekends.read,
				displayedName: 'Выходные в филиалах',
				displayPermission: permissionNames.CAN_USE_HELPDESK_WEEKENDS_API,
			},
			{
				id: 4,
				name: routeNames.helpdesk.cities.read,
				displayedName: 'Города',
				displayPermission: permissionNames.CAN_USE_HELPDESK_CITIES_API,
			},
			{
				id: 5,
				name: routeNames.helpdesk.clusters.read,
				displayedName: 'Кластеры',
				displayPermission: permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
			},
			{
				id: 6,
				name: routeNames.helpdesk.phones.read,
				displayedName: 'Телефоны',
				displayPermission: permissionNames.CAN_USE_HELPDESK_PHONES_API,
			},
			{
				id: 7,
				name: routeNames.helpdesk.siteInfo.update,
				displayedName: 'Информация сайтов',
				displayPermission: permissionNames.CAN_USE_HELPDESK_SITE_INFO_API,
			},
			{
				id: 8,
				name: routeNames.helpdesk.sms.send,
				displayedName: 'Отправка SMS',
				displayPermission: permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API,
			},
			{
				id: 9,
				name: routeNames.helpdesk.sms.read,
				displayedName: 'SMS шаблоны',
				displayPermission: permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API,
			},
			{
				id: 10,
				name: routeNames.helpdesk.signers.read,
				displayedName: 'Подписанты',
				displayPermission: permissionNames.CAN_USE_HELPDESK_SIGNERS_API,
			},
			{
				id: 11,
				name: routeNames.helpdesk.sites.read,
				displayedName: 'Сервисы',
				displayPermission: permissionNames.CAN_USE_HELPDESK_SITES_API,
			},
			{
				id: 12,
				name: routeNames.helpdesk.priorities.read,
				displayedName: 'Проекты',
				displayPermission: permissionNames.CAN_USE_HELPDESK_PRIORITIES_API,
			},
			{
				id: 13,
				name: routeNames.helpdesk.hunterForm,
				displayedName: 'HunterForm',
				displayPermission: permissionNames.CAN_USE_HELPDESK_HUNTERFORM_API,
			},
			{
				id: 14,
				name: routeNames.helpdesk.discounts.read,
				displayedName: 'Скидки',
				displayPermission: permissionNames.CAN_USE_HELPDESK_DISCOUNT,
			},
			{
				id: 15,
				name: routeNames.helpdesk.tariffs.read,
				displayedName: 'Тарифы',
				displayPermission: permissionNames.CAN_USE_HELPDESK_TARIFFS_API
			}
		],
	},
	{
		id: 2,
		referLink: '/passport',
		icon: 'os-icon os-icon-users',
		name: 'Passport',
		permissionsList: [
			permissionNames.PASSPORT_SU,
			permissionNames.CAN_READ_PASSPORT_USERS,
			permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
			permissionNames.CAN_READ_PASSPORT_GROUPS,
			permissionNames.CAN_READ_PASSPORT_PERMISSIONS,
			permissionNames.CAN_READ_PASSPORT_SCOPES,
			permissionNames.CAN_READ_PASSPORT_NAMESPACES,
			permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
			permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
		],
		links: [
			{
				id: 1,
				name: routeNames.passport.users.read,
				displayedName: 'Пользователи',
				displayPermission: permissionNames.CAN_READ_PASSPORT_USERS,
				suPermission: permissionNames.PASSPORT_SU,
			},
			{
				id: 2,
				name: routeNames.passport.schedule.read,
				displayedName: 'Расписание работы',
				displayPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
			},
			{
				id: 3,
				name: routeNames.passport.offices.read,
				displayedName: 'Офисы работы',
				displayPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
			},
			{
				id: 4,
				name: routeNames.passport.clusters.read,
				displayedName: 'Кластеры сотрудников',
				displayPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
				],
			},
			{
				id: 5,
				name: routeNames.passport.permissions.read,
				displayedName: 'Права доступа',
				displayPermission: permissionNames.CAN_READ_PASSPORT_PERMISSIONS,
				suPermission: permissionNames.PASSPORT_SU,
			},
			{
				id: 6,
				name: routeNames.passport.groups.read,
				displayedName: 'Группы прав',
				displayPermission: permissionNames.CAN_READ_PASSPORT_GROUPS,
				suPermission: permissionNames.PASSPORT_SU,
			},
			{
				id: 7,
				name: routeNames.passport.scopes.read,
				displayedName: 'Области видимости',
				displayPermission: permissionNames.CAN_READ_PASSPORT_SCOPES,
				suPermission: permissionNames.PASSPORT_SU,
			},
			{
				id: 8,
				name: routeNames.passport.namespaces.read,
				displayedName: 'Неймспейсы',
				displayPermission: permissionNames.CAN_READ_PASSPORT_NAMESPACES,
				suPermission: permissionNames.PASSPORT_SU,
			},
			{
				id: 9,
				name: routeNames.passport.attributes.read,
				displayedName: 'Атрибуты',
				displayPermission: permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
				suPermission: permissionNames.PASSPORT_SU,
			},
		],
	},
	{
		id: 3,
		referLink: '/helpback',
		icon: 'os-icon os-icon-user-check',
		name: 'HelpBack',
		permissionsList: [permissionNames.CAN_READ_HELPBACK_RESPONSIBLES],
		links: [
			{
				id: 1,
				name: routeNames.helpback.responsibles.read,
				displayedName: 'Ответственные',
				displayPermission: permissionNames.CAN_READ_HELPBACK_RESPONSIBLES,
			},
		],
	},
	{
		id: 4,
		referLink: '/docgen',
		icon: 'os-icon os-icon-file-text',
		name: 'DocGen',
		permissionsList: [permissionNames.CAN_USE_TERM_DOCGEN],
		links: [
			{
				id: 1,
				name: routeNames.term_docgen.main,
				displayedName: 'Договора расторжения',
				displayPermission: permissionNames.CAN_USE_TERM_DOCGEN,
			},
		],
	},
	{
		id: 5,
		referLink: '/advertising-cabinet/contacts',
		icon: 'os-icon os-icon-file-text',
		name: 'Кабинет рекламы',
		attributeList: [attributeNames.ADV_CAB_ACCESS, attributeNames.ADV_CAB_ROLE],
		links: [
			{
				id: 1,
				name: routeNames.adv_cab.contacts.read,
				displayedName: 'Контакты',
				//displayPermission: permissionNames.ADV_CAB_ACCESS,
				displayAttribute: [attributeNames.ADV_CAB_ACCESS, attributeNames.ADV_CAB_ROLE],
			},
		],
	},
	{
		id: 6,
		referLink: '/okk',
		icon: 'os-icon os-icon-file-text',
		name: 'ОКК',
		permissionsList: [
			permissionNames.CAN_READ_OKK_FILES,
			permissionNames.CAN_READ_OKK_FINES,
		],
		links: [
			{
				id: 1,
				name: routeNames.okk.files.read,
				displayedName: 'Хранилище',
				displayPermission: permissionNames.CAN_READ_OKK_FILES,
			}
		]
	}
];
