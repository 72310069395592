<template>
	<div class="element-box">

	</div>
</template>

<script>
export default {
	name: 'MainPage',
	mounted() {
	}
};
</script>

<style>

</style>
