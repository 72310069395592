<template>
	<div class="all-wrapper with-side-panel solid-bg-all">
		<div class="layout-w">

			<div class="menu-mobile menu-activated-on-click color-scheme-light">
				<div class="mm-logo-buttons-w">
					<router-link class="mm-logo" :to="{ path: '/' }">
						<img src="@/assets/logo.png">
						<span>HelpDesk</span>
					</router-link>
					<div class="mm-buttons">
						<div class="mobile-menu-trigger" @click="onMobileBurgerMenuClick">
							<div class="os-icon os-icon-hamburger-menu-1"></div>
						</div>
					</div>
				</div>
				<div id="menu-and-user" class="menu-and-user" :style="{ display: mobileMenu }">
					<div class="logged-user-w">
						<div class="avatar-w">
							<avatar v-if="isAuthenticated" :username="userName" :size="35"></avatar>
							<avatar v-else username="Гость" :size="35"></avatar>
						</div>
						<div class="logged-user-info-w">
							<div v-if="isAuthenticated" class="logged-user-name">
								{{ userName }}
							</div>
							<div v-else class="logged-user-name">
								Гость
							</div>
							<div v-if="isAuthenticated && passportSU" class="logged-user-role">
								Администратор
							</div>
							<div v-else class="logged-user-role">
								Пользователь
							</div>
						</div>
					</div>

					<ul class="main-menu">
						<li v-for="navItem in links" :key="navItem.id" class="has-sub-menu"
								:class="{ active: navItem.active }" @click="navItem.active = !navItem.active">
							<a href="#">
								<div class="icon-w">
									<div :class="navItem.icon"></div>
								</div>
								<span>{{ navItem.name }}</span>
							</a>
							<ul class="sub-menu">
								<li v-for="link in navItem.links" :key="link.id">
									<router-link
											:to="{ name: link.name }"
									>{{ link.displayedName }}
									</router-link>
								</li>
							</ul>
						</li>
					</ul>

				</div>
			</div>

			<div
					class="menu-w color-scheme-light color-style-default menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
				<div class="logo-w">
					<router-link class="logo" :to="{ path: '/' }">
						<div class="logo-element"></div>
					</router-link>
				</div>
				<div class="logged-user-w avatar-inline">
					<div class="logged-user-i">
						<div class="avatar-w">
							<avatar v-if="isAuthenticated" :username="userName" :size="35"></avatar>
							<avatar v-else username="Гость" :size="35"></avatar>
						</div>
						<div class="logged-user-toggler-arrow">
							<div class="os-icon os-icon-chevron-down"></div>
						</div>
						<div class="logged-user-menu color-style-bright">
							<div class="logged-user-avatar-info">
								<div class="avatar-w">
									<avatar v-if="isAuthenticated" :username="userName" :size="40"></avatar>
									<avatar v-else username="Гость" :size="40"></avatar>
								</div>
								<div class="logged-user-info-w">
									<div v-if="isAuthenticated" class="logged-user-name">
										{{ userName }}
									</div>
									<div v-else class="logged-user-name">
										Гость
									</div>
									<div v-if="isAuthenticated && passportSU" class="logged-user-role">
										Администратор
									</div>
									<div v-else class="logged-user-role">
										Пользователь
									</div>
								</div>
							</div>
							<div class="bg-icon">
								<i class="os-icon os-icon-user"></i>
							</div>
							<ul>
								<li hidden>
									<a href="users_profile_big.html"><i class="os-icon os-icon-user-male-circle2"></i><span>Профиль</span></a>
								</li>
								<li hidden>
									<a href="#"><i class="os-icon os-icon-others-43"></i><span>Уведомления</span></a>
								</li>
								<li>
									<a v-if="isAuthenticated" @click="logout"><i
											class="os-icon os-icon-log-out"></i><span>Выйти</span></a>
									<a v-else @click="login"><i class="os-icon os-icon-log-in"></i><span>Войти</span></a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<ul class="main-menu">
					<li v-for="navItem in links" :key="navItem.id" @mouseenter="onMenuMouseEnter" @mouseleave="onMenuMouseLeave"
							class="has-sub-menu">
						<a href="#">
							<div class="icon-w">
								<div :class="navItem.icon"></div>
							</div>
						</a>
						<div class="sub-menu-w">
							<div class="sub-menu-header">
								{{ navItem.name }}
							</div>
							<div class="sub-menu-icon">
								<i :class="navItem.icon"></i>
							</div>
							<div class="sub-menu-i">
								<ul class="sub-menu">
									<li v-for="link in navItem.links" :key="link.id">
										<router-link
												:to="{ name: link.name }"
										>{{ link.displayedName }}
										</router-link>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>

			<div class="content-w">
				<div class="top-bar color-scheme-transparent">
					<div class="top-menu-controls">
						<div class="logged-user-w">
							<div class="logged-user-i">
								<div class="avatar-w">
									<avatar v-if="isAuthenticated" :username="userName" :size="35"></avatar>
									<avatar v-else username="Гость" :size="35"></avatar>
								</div>
								<div class="logged-user-menu color-style-bright">
									<div class="logged-user-avatar-info">
										<div class="avatar-w">
											<avatar v-if="isAuthenticated" :username="userName" :size="35"></avatar>
											<avatar v-else username="Гость" :size="35"></avatar>
										</div>
										<div class="logged-user-info-w">
											<div v-if="isAuthenticated" class="logged-user-name">
												{{ userName }}
											</div>
											<div v-else class="logged-user-name">
												Гость
											</div>
											<div v-if="isAuthenticated && passportSU" class="logged-user-role">
												Администратор
											</div>
											<div v-else class="logged-user-role">
												Пользователь
											</div>
										</div>
									</div>
									<div class="bg-icon">
										<i class="os-icon os-icon-user"></i>
									</div>
									<ul>
										<li hidden>
											<a href="#"><i class="os-icon os-icon-others-43"></i><span>Уведомления</span></a>
										</li>
										<li>
											<a v-if="isAuthenticated" @click="logout"><i
													class="os-icon os-icon-log-out"></i><span>Выйти</span></a>
											<a v-else @click="login"><i class="os-icon os-icon-log-in"></i><span>Войти</span></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

				<div class="content-i">
					<div class="content-box">
						<router-view/>
					</div>
				</div>

			</div>
		</div>

		<div class="display-type"></div>
	</div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {navbarLinks} from '@/config/navbar-links';
import Avatar from 'vue-avatar';
import {notifyOptions} from '@/store/notifications/constants';
import store from '@/store';

export default {
	name: 'NavBar',
	components: {
		Avatar
	},
	data() {
		return {
			e: null,
			breadcrumbItems: [],
			mobileMenu: 0,
			links: []
		};
	},
	watch: {
		'$route'() {
			this.updateBreadcrumbList();
		},
		'isAuthenticated'() {
			this.rebuildNavBar();
		},
		'notifications'(newValue) {
			this.showToast();
		}
	},
	computed: {
		...mapGetters([
			'permissionsAvailable', 'permissionAvailable', 'scopeAvailable', 'isAuthenticated', 'userName', 'passportSU',
			'notifications', 'attributeAvailable', 'attribute'
		]),
	},
	methods: {
		...mapActions(['logout', 'CLEAR_NOTIFICATIONS']),
		onMobileBurgerMenuClick() {
			if (this.mobileMenu === 'block') {
				this.mobileMenu = 'none';
			} else {
				this.mobileMenu = 'block';
			}
		},
		onMenuMouseEnter(event) {
			event.target.classList.add('active');
		},
		onMenuMouseLeave(event) {
			this.e = setTimeout((function () {
				event.target.classList.remove('active');
			}
			), 30);
		},
		updateBreadcrumbList() {
			this.breadcrumbItems = this.$route.meta.breadcrumb;
		},
		login() {
			this.$router.push('/login');
		},
		showToast() {
			let i = this.notifications.length - 1;
			let options = {};
			switch (this.notifications[i].type) {
			case 'error':
				options = notifyOptions.error;
				break;
			case 'success':
				options = notifyOptions.success;
				break;
			case 'warning':
				options = notifyOptions.warning;
				break;
			case 'info':
				options = notifyOptions.info;
				break;
			default:
				options = notifyOptions.default;
				break;
			}
			options.title = this.notifications[i].title;
			this.$bvToast.toast(this.notifications[i].message, options);
		},
		rebuildNavBar() {
			if (this.isAuthenticated) {
				for (let i in navbarLinks) {

					if (navbarLinks[i].permissionsList) {
						for (let perm of navbarLinks[i].permissionsList) {
							if (this.permissionAvailable(perm)) {
								navbarLinks[i].active = false;
								this.links.push(navbarLinks[i]);
								break;
							}
						}
					}

					if (navbarLinks[i].attributeList) {
						for (let attr of navbarLinks[i].attributeList) {
							if (this.attributeAvailable(attr.name)) {
								if (attr.values.includes(this.attribute(attr.name))) {
									navbarLinks[i].active = false;
									this.links.push(navbarLinks[i]);
									break;
								}
							}
						}
					}

				}

				for (let j in this.links) {
					this.links[j].links = this.links[j].links.filter(item => this.filterLink(item));
				}
			}
		},
		filterLink(item) {
			if (item.displayPermissions) {
				for (const perm of item.displayPermissions) {
					if (this.permissionAvailable(perm)) {
						return true;
					}
				}
				return false;
			}
			if (item.displayPermission) {
				return this.permissionAvailable(item.displayPermission) || this.permissionAvailable(item.suPermission);
			}
			if (item.displayAttribute) {
				const access = [];
				for (let attr of item.displayAttribute) {
					if (this.attributeAvailable(attr.name)) {
						if (attr.values.includes(this.attribute(attr.name))) {
							access.push(true);
							continue;
						}
					}
					access.push(false);
				}

				return !access.includes(false);
			}

			return this.permissionAvailable(item.displayPermission) || this.permissionAvailable(item.suPermission);
		}
	},
	mounted() {
		this.updateBreadcrumbList();
		this.rebuildNavBar();
	},
};
</script>

<style>
@media only screen and (min-width: 1251px) {
	.with-side-panel .layout-w {
		min-height: 100%;
	}

	body {
		height: 100%;
	}

	.all-wrapper {
		min-height: 100%;
	}
}

</style>
