import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL, VUE_APP_TGBOTS_URL} from '@/config';

export const actions = {
	async HELPDESK_DELETE_VPN_USER(s, {user}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/vpn', {
			data: {
				user: user
			}
		});
	},

	async HELPDESK_UPDATE_VPN_USER(s, {user, pass}) {
		return axios.patch(VUE_APP_HELPDESK_API_URL + '/api/v1/vpn',
			{
				user: user,
				pass: pass
			}).then((resp) => {
			return resp.data;
		});
	},

	// eslint-disable-next-line no-unused-vars
	async HELPDESK_CREATE_VPN_USER({commit}, {user, pass}) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/vpn', {
			user: user,
			pass: pass
		}).then((resp) => {
			return resp.data;
		});
	},

	// eslint-disable-next-line no-unused-vars
	async TG_TERMINATE({commit}, {userId}) {
		return axios.post(VUE_APP_TGBOTS_URL + '/terminator/kick?user_id=' + userId);
	},

	// eslint-disable-next-line no-unused-vars
	async LEO_BOT_TERMINATE({commit}, {userId, userLogin}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/leobot?id=' + userId + '&login=' + encodeURIComponent(userLogin));
	},
};
