import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_FILES(deal_id, file_id) {
	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/files/' + file_id.toString()
	).then((resp) => {
		return {file: resp.data.file};
	}).catch((err) => {
		return {file: null, error: err};
	});
}
