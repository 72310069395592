import axios from 'axios';
import {VUE_APP_OKK_API_URL} from '@/config';
import * as events from '@/store/okk/users/event-types';

export const actions = {

	async OKK_GET_USERS_FROM_API({commit}, {office_ids}) {
		let filter = {
			'office_ids[]': office_ids,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_OKK_API_URL + '/api/v1/users/offices', {
			params: filter
		}).then((resp) => {
			commit(events.OKK_SET_USERS, resp.data);
			return resp.data;
		});
	},

};