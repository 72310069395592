import * as events from '@/store/notifications/event-types';

export const mutations = {
	[events.ADD_NOTIFICATION_TO_QUERY](state, {type, title, message}) {
		state.notifications.push({type: type, title: title, message: message});
	},
	[events.CLEAR_NOTIFICATION](state) {
		state.notifications = [];
	},
};
