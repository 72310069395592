<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Создание проекта
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Название: {{ priority.project }}<br>
					Приоритет: {{ priority.priority }}<br>
					Критичность: {{ priority.criticality }}<br>
					Проработка БА~%: {{ priority.ba_elaboration }}<br>
					Проработка СА~%: {{ priority.ca_elaboration }}
				</div>
			</b-modal>

			<b-form-group id="project-input-group" label="Название проекта" label-for="priority-name-input">
				<b-form-input
						id="priority-name-input"
						name="priority-name-input"
						v-model="$v.priority.project.$model"
						:state="validateState('project')"
						aria-describedby="input-priority-project-live-feedback"
						placeholder="Введите название проекта"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-priority-project-live-feedback"
				>Название должно состоять минимум из двух символов.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="priority-input-group" label="Приоритет" label-for="priority-priority-input">
				<b-form-input
						id="priority-priority-input"
						name="priority-priority-input"
						v-model="$v.priority.priority.$model"
						:state="validateState('priority')"
						aria-describedby="input-priority-live-feedback"
						placeholder="Введите приоритет от 0 до 10"
						type="number"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-priority-live-feedback"
				>Смещение должно состоять из цифры от 0 до 10.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="criticality-input-group" label="Критичность" label-for="priority-criticality-input">
				<b-form-input
						id="priority-criticality-input"
						name="priority-criticality-input"
						v-model="$v.priority.criticality.$model"
						:state="validateState('criticality')"
						aria-describedby="input-priority-criticality-live-feedback"
						placeholder="Введите критичность"
						type="number"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-priority-criticality-live-feedback"
				>Смещение должно состоять из цифры от -1 до 10.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="ba-elaboration-input-group" label="Проработка бизнес анализом"
										label-for="priority-ba-elaboration-input">
				<b-form-input
						id="priority-ba-elaboration-input"
						name="priority-ba-elaboration-input"
						v-model="$v.priority.ba_elaboration.$model"
						:state="validateState('ba_elaboration')"
						aria-describedby="input-priority-ba-elaboration-live-feedback"
						placeholder="Введите процент проработки"
						type="number"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-priority-ba-elaboration-live-feedback"
				>Смещение должно состоять из цифры от 0 до 100.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="ca-elaboration-input-group" label="Проработка CA"
										label-for="priority-ca-elaboration-input">
				<b-form-input
						id="priority-ca-elaboration-input"
						name="priority-ca-elaboration-input"
						v-model="$v.priority.ca_elaboration.$model"
						:state="validateState('ca_elaboration')"
						aria-describedby="input-priority-ca-elaboration-live-feedback"
						placeholder="Введите процент проработки"
						type="number"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-priority-ca-elaboration-live-feedback"
				>Смещение должно состоять из цифры от 0 до 100.
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToPrioritiesTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, minLength, minValue, maxValue} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskPriorityCreateForm',
	mixins: [validationMixin],
	validations: {
		priority: {
			project: {
				required,
				minLength: minLength(2),
			},
			priority: {
				required,
				minValue: minValue(0),
				maxValue: maxValue(10),
			},
			criticality: {
				required,
				minValue: minValue(-1),
				maxValue: maxValue(10),
			},
			ba_elaboration: {
				required,
				minValue: minValue(0),
				maxValue: maxValue(100),
			},
			ca_elaboration: {
				required,
				minValue: minValue(0),
				maxValue: maxValue(100),
			},
		}
	},
	data() {
		return {
			priority: {
				project: '',
				priority: 0,
				criticality: 0,
				ba_elaboration: 0,
				ca_elaboration: 0,
			}
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_CREATE_PRIORITY', 'ADD_NOTIFICATION_TO_QUERY'
		]),
		validateState(name) {
			const {$dirty, $error} = this.$v.priority[name];
			return $dirty ? !$error : null;
		},

		backToPrioritiesTable() {
			this.$router.push({name: routeNames.helpdesk.priorities.read});
		},

		async submit() {
			this.$v.priority.$touch();
			if (this.$v.priority.$anyError) {
				return;
			}
			await this.HELPDESK_CREATE_PRIORITY(this.priority).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Проект успешно создан'});
				this.backToPrioritiesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании Проекта'
				});
			});
		}
	},

	async mounted() {
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
