<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Изменение данных кластера
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-modal
				id="confirmation-modal"
				title="Подтвердите изменения"
				ok-title="Сохранить"
				@ok="submit"
				cancel-title="Отмена"
			>
				<div>
					Название: {{ cluster.name }}<br>
					Описание: {{ cluster.description }}<br>
					Тип: {{ clusterType }}<br>
				</div>
			</b-modal>

			<b-form-group id="name-input-group" label="Название" label-for="name-input">
				<b-form-input
					id="name-input"
					name="name-input"
					v-model="$v.cluster.name.$model"
					:state="validateState('name')"
					aria-describedby="input-name-live-feedback"
					placeholder="Введите название"
				></b-form-input>
				<b-form-invalid-feedback id="input-name-live-feedback">
					Название не может быть пустым
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="description-input-group" label="Описание" label-for="description-input">
				<b-form-textarea
					id="description-input"
					name="description-input"
					v-model="cluster.description"
					rows="2"
					placeholder="Введите описание"
				></b-form-textarea>
			</b-form-group>

			<b-form-group id="type-input-group" label="Тип" label-for="type-input">
				<b-form-select
					id="type-input"
					name="type-input"
					v-model="$v.cluster.type.$model"
					:options="typesEnum"
					:state="validateState('type')"
					aria-describedby="input-type-live-feedback"
				></b-form-select>
				<b-form-invalid-feedback id="input-type-live-feedback">
					Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button
					class="btn btn-primary"
					type="button"
					variant="primary"
					name="button"
					v-b-modal="'confirmation-modal'"
				>
					Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToClustersTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, minLength} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskClusterUpdateForm',
	mixins: [validationMixin],
	validations: {
		cluster: {
			name: {
				required,
				minLength: minLength(2),
			},
			type: {
				required,
			},
		}
	},
	data() {
		return {
			cluster: {
				name: null,
				description: null,
				type: null,
			},
			typesEnum: [
				{ value: null, text: 'Не выбрано' },
				{ value: 'rop', text: 'РОП' },
			],
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_UPDATE_CLUSTER', 'HELPDESK_GET_CLUSTER_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateState(name) {
			const {$dirty, $error} = this.$v.cluster[name];
			return $dirty ? !$error : null;
		},

		backToClustersTable() {
			this.$router.push({name: routeNames.helpdesk.clusters.read});
		},

		async submit() {
			this.$v.cluster.$touch();
			if (this.$v.cluster.$anyError) {
				return;
			}
			await this.HELPDESK_UPDATE_CLUSTER({id: this.$route.params.cluster_id, cluster: this.cluster}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Кластер успешно обновлён'});
				this.backToClustersTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении кластера'
				});
			});
		}
	},

	computed: {
		...mapGetters([
			'helpdeskCluster'
		]),
		clusterType() {
			for (let i = 0; i < this.typesEnum.length; i += 1) {
				if (this.typesEnum[i].value === this.cluster.type) {
					return this.typesEnum[i].text;
				}
			}
			
			return '';
		},
	},

	async mounted() {
		if (this.$route.params.cluster_id !== '0') {
			await this.HELPDESK_GET_CLUSTER_FROM_API(this.$route.params.cluster_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении кластера'
				});
			});
			this.cluster = this.helpdeskCluster;
		}
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
