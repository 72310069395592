<template>
  <div class="element-box">
    <h5 class="form-header">
      Файлы записи рабочих смен
    </h5>
    <div class="table-responsive">
      <div class="controls-above-table">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label for="offices-select" class="form-label">Офис</label>
              <b-form-select
                  v-model="filters.office_ids"
                  :options="branchOptions"
                  id="offices-select"
                  value-field="id"
                  text-field="name"
                  class="form-control bright mb-3"
                  @change="filterOffice"
              >
              </b-form-select>
              <label for="offices-select" class="form-label">Сотрудник</label>
              <b-form-select
                  v-model="filters.manager_ids"
                  :options="userOptions"
                  id="managers-select"
                  value-field="id"
                  text-field="name"
                  class="form-control bright"
                  @change="applyFilters"
                  :disabled="filters.office_ids === null || loading"
              >
              </b-form-select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="date-from-filter">Дата от</label>
                  <b-form-datepicker
                      id="date-from-filter"
                      placeholder="Выберите дату от.."
                      v-model="filters.date_from"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      @input="applyFilters"
                      locale="ru"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="date-to-filter">Дата до</label>
                  <b-form-datepicker
                      id="date-to-filter"
                      placeholder="Выберите дату до.."
                      v-model="filters.date_to"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      reset-button
                      @input="applyFilters"
                      locale="ru"
                  ></b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <form class="form-inline justify-content-sm-end">
              <b-form-select
                  id="okk-files-get-limit"
                  name="okk-files-get-limit"
                  v-model="filters.limit"
                  :options="limits"
                  class="form-control form-control-sm rounded bright"
              ></b-form-select>
            </form>
          </div>
        </div>
      </div>
      <table v-if="totalFiles >= 0 && !loading" class="table table-lightborder">
        <thead>
          <tr>
            <th>
              Дата
            </th>
            <th>
              Офис
            </th>
            <th>
              Сотрудник
            </th>
            <th>
              Время старта
            </th>
            <th>
              Ссылка на запись
            </th>
            <th>
              Статус
            </th>
            <th>
              Просмотр
            </th>
            <th>
              Запись битая?
            </th>
          </tr>
        </thead>
        <tbody v-for="file in okkFiles" :key="file.id">
          <tr>
            <td>
              {{ file.date }}
            </td>
            <td>
              {{ findNameByOfficeId(file.office_id) }}
            </td>
            <td>
              {{ findNameByUserId(file.manager_id) }}
            </td>
            <td>
              {{ file.time }}
            </td>
            <td>
              {{ file.link }}
              <b-button
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  @click="copyLink(file.link)"
                  v-if="file.link"
              >
                <b-icon icon="files"></b-icon>
              </b-button>
            </td>
            <td>
              {{ file.status === 'is_recorded' ? 'готово' : (file.status === 'not_recorded' ? 'в процессе' : '') }}
            </td>
            <td>
              <b-link
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  target="_blank"
                  :href="file.link"
                  v-if="file.link"
              >
                <b-icon icon="upload"></b-icon>
              </b-link>
              <b-button
                  size="sm"
                  variant="default"
                  class="mb-2 icon-button"
                  v-if="file.link"
                  @click="showVideo(file.id)"
              >
                <b-icon icon="play-fill"></b-icon>
              </b-button>

              <b-modal
                  :id="'video-file-'+file.id"
                  centered
                  title="Видео"
                  size="lg"
                  hide-footer
              >
                <video-player class="video-player-box"
                  :playsinline="true"
                  :options="{
                    autoplay: false,
                    muted: false,
                    controls: true,
                    language: 'en',
                    height: '490',
                    playbackRates: [0.5, 0.7, 1.0, 1.25, 1.5],
                    sources: [{
                      type: 'video/mp4',
                      src: file.link
                    }]
                  }"
                />
              </b-modal>
            </td>
            <td>
              <b-form-checkbox
                  :id="`checkbox-${file.id}`"
                  :name="`checkbox-${file.id}`"
                  v-model="file.corrupted"
                  value="true"
                  unchecked-value="false"
                  @change="handleCheckboxChange(file.id, file.status, file.corrupted)"
              >
              </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="loading" class="loader-container">
        <span class="loader"></span>
      </div>
    </div>
    <div class="controls-below-table">
      <div class="table-records-info">

      </div>
      <div class="table-records-pages">
        <ul>
          <li>
            <a href="#" @click="previous" v-if="canGetPrev">Назад</a>
          </li>
          <li>
            <a href="#" @click="next" v-if="canGetNext && okkFiles.length === filters.limit">Далее</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'FileTable',
	computed: {
		...mapGetters([
			'okkFiles', 'okkUsers', 'helpdeskBranches'
		]),
	},
	data() {
		return {
			filters: {
				date_from: null,
				date_to: null,
				office_ids: null,
				manager_ids: null,
				limit: 50,
				page: 1,
			},
			limits: [
				{ value: 50, text: 'Записей на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			branchOptions: [
				{
					id: null,
					name: 'Выберите офис..'
				}
			],
			userOptions: [
				{
					id: null,
					name: 'Выберите сотрудника..'
				}
			],
			userOfficeId: null,
			canGetNext: true,
			canGetPrev: false,
      loading: false,
      totalFiles: -1
		};
	},
	methods: {
		...mapActions([
			'OKK_GET_FILES_FROM_API', 'OKK_UPDATE_FILES_FROM_API', 'HELPDESK_GET_BRANCHES_FROM_API', 'OKK_GET_USERS_FROM_API'
		]),

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getFiles();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getFiles();
			if(this.okkFiles.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		copyLink(link) {
			navigator.clipboard.writeText(link);
		},

		showVideo(fileId) {
			this.$bvModal.show('video-file-' + fileId);
		},

		async handleCheckboxChange(id, status, corrupted) {
			await this.OKK_UPDATE_FILES_FROM_API({id, status, corrupted}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении файла'
				});
			});
		},

		findNameByOfficeId(officeId) {
			const office = this.helpdeskBranches.find(item => item.id === officeId);
			return office ? office.name : '';
		},

		findNameByUserId(userId) {
			const user = this.okkUsers.find(item => item.id === userId);
			return user ? user.name : '';
		},

		async getFiles() {
			await this.OKK_GET_FILES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка файлов' });
			});
      this.totalFiles = this.okkFiles.length;
		},
    
		async getBranches() {
			await this.HELPDESK_GET_BRANCHES_FROM_API({}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов'
				});
			});

			for (let i = 0; i < this.helpdeskBranches.length; i++) {
				this.branchOptions.push({
					id: this.helpdeskBranches[i].id,
					name: this.helpdeskBranches[i].name
				});
			}
      this.branchOptions.sort((a, b) => a.name.localeCompare(b.name));
		},

		async getUsers(office_ids = null) {
			await this.OKK_GET_USERS_FROM_API({office_ids}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении ОКК пользователей'
				});
			});
		},

		async filterOffice() {
      this.loading = true;
      this.userOptions = [{
				id: null,
				name: 'Выберите сотрудника..'
			}];
      this.okkUsers = [];
      this.filters.manager_ids = null;
			await this.getUsers(this.filters.office_ids);
      for (let i = 0; i < this.okkUsers.length; i++) {
        this.userOptions.push({
          id: this.okkUsers[i].id,
          name: this.okkUsers[i].name
        });
      }
			await this.applyFilters();
		},

		async applyFilters() {
      this.loading = true;
			this.filters.page = 1;
			await this.getFiles();
      this.loading = false;
		}
	},
	async mounted() {
		await this.getBranches();	
  }
};
</script>
<style scoped>
.icon-button {
  margin-bottom: 5px !important;
  background-color: #fff;
  color: #000;
}
.loader-container {
  display: flex;
  justify-content: center;
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #0a7cf8;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #98c9fd;
}
@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
</style>