import axios from 'axios';
import {VUE_APP_OKK_API_URL} from '@/config';
import * as events from '@/store/okk/files/event-types';

export const actions = {

	async OKK_GET_FILES_FROM_API({commit}, {date_from, date_to, office_ids, manager_ids, limit, page}) {
		let filter = {
			date_from: date_from,
			date_to: date_to,
			'office_ids[]': office_ids,
			'manager_ids[]': manager_ids,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_OKK_API_URL + '/api/v1/files/list', {
			params: filter
		}).then((resp) => {
			commit(events.OKK_SET_FILES, resp.data);
			return resp.data;
		});
	},

	async OKK_UPDATE_FILES_FROM_API({commit}, {id, status, corrupted}){
		let data = {
			id: id,
			status: status,
			corrupted: corrupted
		};
		return axios.patch(VUE_APP_OKK_API_URL + '/api/v1/files/mutation', data).then((resp) => {
			commit(events.OKK_SET_FILE, resp.data);
			return resp.data;
		});
	}
};