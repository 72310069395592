<template>
  <div class="element-box">
    <b-form @submit.stop.prevent="submit">
      <h5 class="form-header">
        Создание неймспейса
      </h5>
      <div class="form-desc">
        Заполните необходимые данные и нажмите сохранить
      </div>

      <b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
      >
        <div>
          Название: {{ namespace.name }}<br>
          Код: {{ namespace.code }}<br>
        </div>
      </b-modal>

      <b-form-group id="login-input-group" label="Название" label-for="user-login-input">
        <b-form-input
            id="user-login-input"
            v-model="namespace.name"
            @keydown="checkFields('name')"
            placeholder="Введите название"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="login-input-group" label="Код" label-for="user-login-input">
        <b-form-input
            id="user-login-input"
            @keydown="checkFields('code')"
            v-model="namespace.code"
            placeholder="Введите код"
        ></b-form-input>
      </b-form-group>

      <div class="alert alert-danger" v-if="isError && error_messages.length">
        <p v-for="(err_msg,key_msg) in error_messages" :key="key_msg">{{ err_msg }}</p>
      </div>

      <div class="form-buttons-w">
        <b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
                  v-b-modal="'confirmation-modal'">Сохранить
        </b-button>
        <b-button class="btn btn-grey" @click="backToNamespaceTable()">Отмена</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'NamespaceCreateForm',
	data() {
		return {
			namespace: {
				code: '',
				name: '',
			},
			valid: false,
			isError: false,
			error_messages: [],
		};
	},
	methods: {
		...mapActions([
			'CREATE_NAMESPACE', 'GET_NAMESPACES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),
		validateForm(name_field = '') {
			this.error_messages = [];
			if (name_field === 'code') {
				if (this.namespace.code.length === 0) {
					this.error_messages.push('Поле кода обязательно для заполнения');
				}
			}
			if (name_field === 'name') {
				if (this.namespace.name.length === 0) {
					this.error_messages.push('Поле названия обязательно для заполнения');
				}
			}
			this.isError = this.error_messages.length > 0;
		},

		backToNamespaceTable() {
			this.$router.push({name: routeNames.passport.namespaces.read});
		},

		checkFields(name_field) {
			this.error_messages = [];
			this.isError = false;
			let code = false;
			let name = false;
			if (this.namespace.name && this.namespace.name.length > 0) {
				name = true;
			}
			if (this.namespace.code && this.namespace.code.length > 0) {
				code = true;
			}
			if (code && name) {
				this.valid = true;
			} else {
				this.valid = false;
				this.validateForm(name_field);
			}
		},

		async submit() {
			await this.CREATE_NAMESPACE(this.namespace).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Неймспейс успешно создан' });
				this.backToNamespaceTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании неймспейса' });
			});
		},
	},
	computed: {
		...mapGetters([
			'passportNamespaces'
		]),
	},
	async mounted() {
	},
};
</script>

<style>

</style>
