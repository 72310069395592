<template>
  <div class="element-box">
    <h5 class="form-header">
      Таблица городов
    </h5>
    <div class="form-desc" v-if="canUseHelpdeskCitiesApi">
      Выберите нужный город и нажмите на кнопку действия<br>
      <router-link :to="{ name: routeNames.helpdesk.cities.create }">Создать новый город</router-link>
    </div>

    <div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация городов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="cities-show-on-sites-group" label="Отображение на сайтах" label-for="cities-show-on-sites-filter">
									<b-form-select
											id="cities-show-on-sites"
											v-model="filters.show_on_sites"
											:options="booleanEnum"
									></b-form-select>
								</b-form-group>
								<b-form-group id="cities-status-coven-group" label="Использование в coven" label-for="cities-status-coven-filter">
									<b-form-select
											id="cities-status-coven"
											v-model="filters.status_coven"
											:options="booleanEnum"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPDESK_GET_CITIES_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

      <table class="table table-lightborder">
        <thead>
        <tr>
          <th>
            Название
						<i class="os-icon os-icon-arrow-up2"
							v-if="filters.sort_name === null || filters.sort_name !== 'desc'"
							@click.exact="filters.sort_name='desc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							v-if="filters.sort_name === null || filters.sort_name !== 'asc'"
							@click.exact="filters.sort_name='asc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
          </th>
          <th>
            Регион
          </th>
          <th>
            Смещение времени UTC
          </th>
          <th v-if="canUseHelpdeskCitiesApi">
            Действия
          </th>
        </tr>
        </thead>
        <tbody v-for="city in helpdeskCities" :key="city.id">
        <tr>
          <td>
            {{ city.name }}
          </td>
          <td>
            {{ city.region_name }}
          </td>
          <td class="text-left">
            {{ city.time_offset }}
          </td>
          <td class="row-actions text-left" v-if="canUseHelpdeskCitiesApi">
            <router-link v-b-tooltip.html.top title="Редактировать"
                         :to="{ name: routeNames.helpdesk.cities.update, params: { city_id: city.id }} "><i
                class="os-icon os-icon-ui-49"></i></router-link>
            <a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(city)" class="danger"><i
                class="os-icon os-icon-ui-15"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskCities.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskCityTable',
	computed: {
		...mapGetters([
			'helpdeskCities', 'canUseHelpdeskCitiesApi'
		]),
	},
	data() {
		return {
			filters: {
				sort_name: null,
				show_on_sites: null,
				status_coven: null,
				limit: 50,
				page: 1
			},
			limits: [
				{ value: 50, text: 'Городов на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			canGetNext: true,
			canGetPrev: false,
			booleanEnum: [
				{ value: null, text: 'Не выбрано' },
				{ value: true, text: 'Активно' },
				{ value: false, text: 'Не активно' }
			],
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_CITIES_FROM_API', 'HELPDESK_DELETE_CITY', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit=50;
			this.filters.page=1;
			this.canGetNext=true;
			this.canGetPrev=false;
			await this.getCities();
		},

		showDeleteQuestion(city) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить город '+city.name+'?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if(value === true) {
					this.HELPDESK_DELETE_CITY({ id: city.id, filters: this.filters }).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Город успешно удалён' });
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении города' });
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
			});
		},

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getCities();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getCities();
			if(this.helpdeskCities.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getCities() {
			await this.HELPDESK_GET_CITIES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка городов' });
			});
		},
	},

	async mounted() {
		await this.getCities();
	},
};
</script>

<style>

</style>
