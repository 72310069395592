<template>
  <div class="element-box">
    <h5 class="form-header">
      Таблица выходных
    </h5>
    <div class="form-desc" v-if="canUseHelpdeskWeekendsApi">
      Выберите нужный выходной и нажмите на кнопку действия<br>
      <router-link :to="{ name: routeNames.helpdesk.weekends.create }">Создать новый выходной</router-link>
    </div>

    <div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация телефонов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="weekends-branch-id-filter-group" label="Привязанный филиал" label-for="weekends-branch-id-filter">
									<b-form-select
											id="weekends-branch-id-filter"
											v-model="filters.branch_id"
											:options="branchesOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPDESK_GET_WEEKENDS_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

      <table class="table table-lightborder">
        <thead>
        <tr>
          <th>
            Филиал
          </th>
          <th>
            Начало
          </th>
          <th>
            Окончание
          </th>
          <th>
            Комментарий
          </th>
          <th v-if="canUseHelpdeskWeekendsApi">
            Действия
          </th>
        </tr>
        </thead>
        <tbody v-for="weekend in helpdeskWeekends" :key="weekend.id">
        <tr>
          <td v-if="weekend.branch_id !== null && weekend.branch_id !== undefined">
			{{ findWeekendBranch(weekend.branch_id) }}
          </td>
          <td v-else>
            {{ findWeekendBranch() }}
          </td>
          <td class="text-left">
            {{ formatDate(weekend.start_date_local) }}
          </td>
          <td class="text-left">
            {{ formatDate(weekend.end_date_local) }}
          </td>
          <td class="text-left">
            {{ weekend.comment }}
          </td>
          <td class="row-actions text-left" v-if="canUseHelpdeskWeekendsApi">
            <a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(weekend)" class="danger"><i
                class="os-icon os-icon-ui-15"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskWeekends.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'WeekendTable',
	computed: {
		...mapGetters([
			'helpdeskBranches', 'helpdeskWeekends', 'canUseHelpdeskWeekendsApi'
		]),
	},
	data() {
		return {
			filters: {
				branch_id: null,
				domain: null,
				limit: 50,
				page: 1
			},
			limits: [
				{ value: 50, text: 'Выходных на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			branchesOptions: [
				{ value: null, text: 'Филиал, выходные которого вы хотите получить' },
			],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_BRANCHES_FROM_API', 'HELPDESK_GET_WEEKENDS_FROM_API', 'HELPDESK_DELETE_WEEKEND', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit=50;
			this.filters.page=1;
			this.canGetNext=true;
			this.canGetPrev=false;
			await this.getWeekends();
		},

		showDeleteQuestion(weekend) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить выходной?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if(value === true) {
					this.HELPDESK_DELETE_WEEKEND({ id: weekend.id, filters: this.filters }).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Выходной успешно удалён' });
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении выхходного' });
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
			});
		},

		findWeekendBranch(branch_id) {
			if(branch_id !== null && branch_id !== undefined) {
				for(let branch of this.branchesOptions) {
					if(branch.value === branch_id) {
						return branch.text;
					}
				}
			}
			return 'Не назначен';
		},

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getWeekends();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getWeekends();
			if(this.helpdeskBranches.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getWeekends() {
			await this.HELPDESK_GET_WEEKENDS_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка выходных' });
			});
		},

		formatDate(s) {
			s = s.replace('T', ' ');
			s = s.replace('Z', '');

			return s;
		},
	},

	async mounted() {
		await this.HELPDESK_GET_BRANCHES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка филиалов' });
		});
		for(let branch of this.helpdeskBranches) {
			this.branchesOptions.push({value: branch.id, text: branch.name});
		}
		await this.getWeekends();
	},
};
</script>

<style>

</style>
