<template>
  <div class="big-error-w">
    <h1>
      404
    </h1>
    <h5>
      Page not Found
    </h5>
    <h4>
      Oops, Something went missing...
    </h4>
  </div>
</template>

<script>
export default {
	name: 'PageNotFound'
};
</script>

<style>

</style>
