import axios from 'axios';
import {GET_PASSPORT_URL} from '@/config';
import * as events from '@/store/passport/groups/event-types';

export const actions = {
	async GET_GROUPS_FROM_API({commit}, {id, namespace_id, scopes, limit, offset}) {
		let filter = {
			id: id,
			namespace_id: namespace_id,
			scopes: scopes,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/group', {
			params: filter
		}).then((resp) => {
			if (resp.data.groups) {
				commit(events.PASSPORT_SET_GROUPS, resp.data.groups);
				return resp.data.groups;
			} else {
				commit(events.PASSPORT_SET_GROUPS, resp.data.group);
				return resp.data.group;
			}
		});
	},

	async DELETE_GROUP({dispatch}, {id, filters}) {
		return axios.delete(GET_PASSPORT_URL() + '/api/v1/group?id=' + id).then(() => {
			dispatch('GET_GROUPS_FROM_API', filters);
		});
	},

	async UPDATE_GROUP({commit}, {group, add, remove}) {
		let rBody = {};
		rBody.group = {
			id: group.id,
			name: group.name,
			code: group.code,
			description: group.description,
		};
		if (Object.keys(add).length !== 0) {
			rBody.add = add;
			for (let i in rBody.add.permissions) {
				rBody.add.permissions[i] = rBody.add.permissions[i].permission;
			}
		}
		if (Object.keys(remove).length !== 0) {
			rBody.remove = remove;
			for (let i in rBody.remove.permissions) {
				rBody.remove.permissions[i] = rBody.remove.permissions[i].id;
			}
		}

		return axios.put(GET_PASSPORT_URL() + '/api/v1/group', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_GROUPS, resp.data);
			return resp.data;
		});
	},

	async CREATE_GROUP({commit}, group) {
		let rBody = {};
		rBody.group = group;
		for (let i in rBody.group.permissions) {
			rBody.group.permissions[i] = rBody.group.permissions[i].permission;
		}
		return axios.post(GET_PASSPORT_URL() + '/api/v1/group', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_GROUPS, resp.data);
			return resp.data;
		});
	},
};
