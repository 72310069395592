import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/sites/event-types';

export const actions = {
	async HELPDESK_GET_SITES_FROM_API({commit}) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/sites').then((resp) => {
			commit(events.HELPDESK_SET_SITES, resp.data);
			return resp.data;
		});
	}
};