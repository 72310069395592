<template>
  <div class="element-box">
    <b-form>
      <h5 class="form-header">
        Изменение данных скидки
      </h5>
      <div class="form-desc">
        Измените необходимые данные и нажмите сохранить
      </div>

      <b-form-group id="name-input-group" label="Название" label-for="tariff-name-input">
        <b-form-input
            id="tariff-name-input"
            name="tariff-name-input"
            v-model="tariff.name"
            placeholder="Введите название"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="description-input-group" label="Описание" label-for="description-name-input">
        <b-form-textarea
            id="tariff-description-input"
            name="tariff-description-input"
            v-model="tariff.description"
            placeholder="Введите описание"
        ></b-form-textarea>
      </b-form-group>

      <div class="form-buttons-w">
        <b-button class="btn btn-primary" type="button" variant="primary" name="button" @click="submit">Сохранить</b-button>
        <b-button class="btn btn-grey" @click="backToTariffsTable">Отмена</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {routeNames} from "@/router/constants";

export default {
  name: 'TariffUpdateForm',
  data() {
    return {
      tariff: {
        name: null,
        description: null,
      },
    };
  },
  methods: {
    ...mapActions([
      'HELPDESK_UPDATE_TARIFF', 'HELPDESK_GET_TARIFF_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
    ]),

    backToTariffsTable() {
      this.$router.push({name: routeNames.helpdesk.tariffs.read});
    },

    async submit() {
      await this.HELPDESK_UPDATE_TARIFF({id: this.$route.params.tariff_id, tariff: this.tariff}).then(() => {
        this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Тариф успешно обновлен'});
        this.backToTariffsTable();
      }).catch(() => {
        this.ADD_NOTIFICATION_TO_QUERY({
          type: 'error',
          title: 'Ошибка',
          message: 'Произошла ошибка при обновлении тарифа'
        });
      });
    }
  },
  computed: {
    ...mapGetters([
      'helpdeskTariff',
    ]),
  },
  async mounted() {
    if (this.$route.params.discount_id !== '0') {
      await this.HELPDESK_GET_TARIFF_FROM_API(this.$route.params.tariff_id).catch(() => {
        this.ADD_NOTIFICATION_TO_QUERY({
          type: 'error',
          title: 'Ошибка',
          message: 'Произошла ошибка при получении тарифа'
        });
      });
      this.tariff = this.helpdeskTariff;
    }
  },
}
</script>