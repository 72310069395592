<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				HunterForm
			</h5>

			<b-form-group id="name-input-group" label="Имя" label-for="form-name-input">
				<b-form-input
						id="form-name-input"
						v-model="$v.form.name.$model"
						:state="validateState('name')"
						placeholder="Имя*"
				></b-form-input>
			</b-form-group>

			<b-form-group id="phone-input-group" label="Телефон" label-for="form-phone-input">
				<b-form-input
						id="form-phone-input"
						v-model="$v.form.phone.$model"
						:state="validateState('phone')"
						placeholder="Телефон*"
						aria-describedby="phone-input-feedback"
				></b-form-input>
				<b-form-invalid-feedback
						id="phone-input-feedback"
				>Телефон должен состоять из 11ти цифр
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="city-input-group" label="Город" label-for="form-city-input">
				<b-form-select
						id="form-city-input"
						v-model="$v.form.city.$model"
						:state="validateState('city')"
						:options="cityOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="hunter-input-group" label="Хантер" label-for="form-hunter-input">
				<b-form-select
						id="form-hunter-input"
						v-model="$v.form.hunter.$model"
						:state="validateState('hunter')"
						:options="hunterOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="source-input-group" label="Источник" label-for="form-source-input">
				<b-form-select
						id="form-source-input"
						v-model="form.source"
						:options="sourceOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group v-show="formLinkEnabled" id="link-input-group" :label="'Ссылка ' + form.source"
										label-for="form-link-input">
				<b-form-input
						v-show="formLinkEnabled"
						id="form-link-input"
						v-model="form.link"
						placeholder="Ссылка*"
				></b-form-input>
			</b-form-group>

			<b-form-group v-show="formSourceIDEnabled" id="client-id-input-group" label="Client ID"
										label-for="form-client-id-input">
				<b-form-input
						v-show="formSourceIDEnabled"
						id="form-client-id-input"
						v-model="form.client_id"
						placeholder="Client ID"
				></b-form-input>
			</b-form-group>

			<b-form-group id="notes-input-group" label="Примечание"
										label-for="form-notes-input">
				<b-form-input
						id="form-notes-input"
						v-model="form.notes"
						placeholder="Примечание"
				></b-form-input>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" @click="submit">Отправить</b-button>
				<b-button class="btn btn-grey" @click="clean">Очистить</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {integer, required, maxLength} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

const phoneValid = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /\d{11}/.test(value);
};

export default {
	name: 'HelpDeskHunterForm',
	mixins: [validationMixin],
	validations: {
		form: {
			name: {
				required,
			},
			phone: {
				required,
				phoneValid,
				maxLength: maxLength(11),
			},
			city: {
				required,
			},
			hunter: {
				required,
			},
		},
	},
	data() {
		return {
			form: {
				name: null,
				phone: null,
				city: null,
				hunter: null,
				source: 'JivoSite',
				link: null,
				client_id: null,
				notes: null
			},
			formLinkEnabled: false,
			formSourceIDEnabled: true,
			cityOptions: [
				{value: null, text: 'Город*'},
			],
			hunterOptions: [
				{value: null, text: 'Хантер*'},
			],
			sourceOptions: [
				{value: 'JivoSite', text: 'JivoSite'},
				{value: 'WhatsApp', text: 'WhatsApp'},
				{value: 'Instagram', text: 'Instagram'},
				{value: 'Одноклассники', text: 'Одноклассники'},
				{value: 'VK', text: 'VK'}
			],
		};
	},
	watch: {
		'form.source'(newValue) {
			switch (newValue) {
			case 'JivoSite':
				this.formLinkEnabled = false;
				this.formSourceIDEnabled = true;
				break;
			case 'WhatsApp':
				this.formLinkEnabled = false;
				this.formSourceIDEnabled = false;
				break;
			case 'Instagram':
			case 'Одноклассники':
			case 'VK':
				this.formLinkEnabled = true;
				this.formSourceIDEnabled = false;
				break;
			}
		}
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_HF_CITIES', 'HELPDESK_GET_HUNTERS_FROM_API', 'HELPDESK_SEND_HUNTER_FORM', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateState(name) {
			const {$dirty, $error} = this.$v.form[name];
			return $dirty ? !$error : null;
		},

		clean() {
			this.form = {
				name: null,
				phone: null,
				city: null,
				source: 'JivoSite',
				link: null,
				client_id: null,
				hunter: null,
				notes: null
			};

			this.$nextTick(() => {
				this.$v.$reset();
			});
		},

		async submit() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}

			await this.HELPDESK_SEND_HUNTER_FORM(this.form).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Форма отправлена'});
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при отправке формы'
				});
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskHFCities', 'helpdeskHunters'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_HF_CITIES().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка городов'
			});
		});

		let cities = [];
		for (let city of this.helpdeskHFCities) {
			cities.push({value: city, text: city.name});
		}
		cities.sort((a, b) => a.text.localeCompare(b.text));
		for (let city of cities) {
			this.cityOptions.push(city);
		}

		await this.HELPDESK_GET_HUNTERS_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка хантеров'
			});
		});

		let hunters = [];
		for (let hunter of this.helpdeskHunters) {
			hunters.push({value: hunter, text: hunter.name});
		}
		hunters.sort((a, b) => a.text.localeCompare(b.text));
		for (let hunter of hunters) {
			this.hunterOptions.push(hunter);
		}

		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
