import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/sms/event-types';

export const actions = {
	async HELPDESK_GET_SMS_TEMPLATES_FROM_API({commit}, { limit, page }) {
		let filter = {
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/sms/templates', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_SMS_TEMPLATES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_TEMPLATE_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/sms/template/'+id).then((resp) => {
			commit(events.HELPDESK_SET_SMS_TEMPLATE, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_TEMPLATE({dispatch}, { id, filters }) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/sms/template/'+id).then(() => {
			dispatch('HELPDESK_GET_SMS_TEMPLATES_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_TEMPLATE({commit}, { id: id, template: template }) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/sms/template/'+id, template).then((resp) => {
			commit(events.HELPDESK_SET_SMS_TEMPLATE, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_TEMPLATE({commit}, template) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/sms/template', template).then((resp) => {
			commit(events.HELPDESK_SET_SMS_TEMPLATE, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_SEND_CUSTOM_SMS({commit}, sms) {
		sms.phones = [sms.phone];
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/sms/send', sms);
	}
};