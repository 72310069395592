import * as events from '@/store/helpdesk/branches/event-types';

export const mutations = {
	[events.HELPDESK_SET_BRANCHES](state, branch) {
		state.helpdesk_branches = branch;
	},
	[events.HELPDESK_SET_BRANCH](state, branch) {
		state.helpdesk_branch = branch;
	},
	[events.HELPDESK_SET_BFL_RESP](state, resp) {
		state.helpdesk_bfl_users = resp;
	}
};
