import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/cities/event-types';
import {HELPDESK_NEW_SET_CITIES} from '@/store/helpdesk/cities/event-types';

function cityToFormData(city) {
	let formData = new FormData();
	if (city.amo_id !== '' && city.amo_id != null) {
		formData.append('amo_id', city.amo_id);
	}
	if (city.bfl_amo_id !== '' && city.bfl_amo_id != null) {
		formData.append('bfl_amo_id', city.bfl_amo_id);
	}
	if (city.amo_group_id !== '' && city.amo_group_id != null) {
		formData.append('amo_group_id', city.amo_group_id);
	}
	if (city.amo_group_name !== '' && city.amo_group_name != null) {
		formData.append('amo_group_name', city.amo_group_name);
	}
	formData.append('name', city.name);
	formData.append('main_name', city.main_name);
	formData.append('region_name', city.region_name);
	formData.append('time_offset', city.time_offset);
	formData.append('abbr', city.abbr);
	formData.append('show_on_sites', city.show_on_sites);
	formData.append('status_coven', city.status_coven);
	if (city.cluster_id !== '' && city.cluster_id != null) {
		formData.append('cluster_id', city.cluster_id);
	}
	return formData;
}

export const actions = {
	async HELPDESK_GET_CITIES_FROM_API({commit}, {sort_name, show_on_sites, status_coven, limit, page, cluster_id}) {
		let filter = {
			sort_name: sort_name,
			show_on_sites: show_on_sites,
			status_coven: status_coven,
			limit: limit,
			page: page,
			cluster_id: cluster_id,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/cities', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_CITIES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_NEW_CITIES_FROM_API({commit}, {sort_name, limit, page}) {
		let filter = {
			sort_name: sort_name,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v2/cities', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_NEW_SET_CITIES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_CITY_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/city/' + id).then((resp) => {
			commit(events.HELPDESK_SET_CITY, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_CITY({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/city/' + id).then(() => {
			dispatch('HELPDESK_GET_CITIES_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_CITY({commit}, {id: id, city: city}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/city/' + id, cityToFormData(city), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_CITY, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_CITY({commit}, city) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/city', cityToFormData(city), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_CITY, resp.data);
			return resp.data;
		});
	},
};
