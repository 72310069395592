import axios from 'axios';
import {GET_PASSPORT_URL} from '@/config';
import * as events from '@/store/passport/namespaces/event-types';

export const actions = {
	async GET_NAMESPACES_FROM_API({commit}, {id, limit, offset}) {
		let filter = {
			id: id,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/namespace', {
			params: filter
		}).then((resp) => {
			if (resp.data.namespaces) {
				commit(events.PASSPORT_SET_NAMESPACES, resp.data.namespaces);
				return resp.data.namespaces;
			} else {
				commit(events.PASSPORT_SET_NAMESPACES, resp.data.namespace);
				return resp.data.namespace;
			}
		});
	},

	async DELETE_NAMESPACE({dispatch}, {id, filters}) {
		return axios.delete(GET_PASSPORT_URL() + '/api/v1/namespace?id=' + id).then(() => {
			dispatch('GET_NAMESPACES_FROM_API', filters);
		});
	},

	async UPDATE_NAMESPACE({commit}, {namespace}) {
		let rBody = {};
		rBody.namespace = {
			id: namespace.id,
			code: namespace.code,
			name: namespace.name,
		};
		return axios.put(GET_PASSPORT_URL() + '/api/v1/namespace', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_NAMESPACES, resp.data);
			return resp.data;
		});
	},

	async CREATE_NAMESPACE({commit}, namespace) {
		let rBody = {};
		rBody.namespace = namespace;
		return axios.post(GET_PASSPORT_URL() + '/api/v1/namespace', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_NAMESPACES, resp.data);
			return resp.data;
		});
	},
};