import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_PUBLIC(deal_id) {
	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/public'
	).then((resp) => {
		return {public: resp.data.public};
	}).catch((err) => {
		return {notes: [], error: err};
	});
}
