import * as events from '@/store/notifications/event-types';

export const actions = {
	ADD_NOTIFICATION_TO_QUERY({commit}, {type, title, message}) {
		commit(events.ADD_NOTIFICATION_TO_QUERY, {type, title, message});
	},
	CLEAR_NOTIFICATIONS({commit}) {
		commit(events.CLEAR_NOTIFICATION);
	}
};
