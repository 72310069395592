import * as events from '@/store/passport/users/event-types';

export const mutations = {
	[events.PASSPORT_SET_USERS] (state, resp) {
		if (typeof resp.id !== 'undefined') {
			state.passport_users = [resp];
			return;
		}

		state.passport_users = resp;
	},
	[events.PASSPORT_DELETE_USER] (state, id) {
		state.passport_users = resp;
	},
};