<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица филиалов
		</h5>
		<div class="form-desc" v-if="canCreate">
			Выберите нужный филиал и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpdesk.branches.create }">Создать новый филиал</router-link>
		</div>

		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация филиалов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="branches-status-filter-group" label="Статус филиалов"
															label-for="branches-status-filter">
									<b-form-select
											id="branches-status-filter"
											v-model="filters.status"
											:options="statusEnum"
									></b-form-select>
								</b-form-group>
								<b-form-group id="branches-project-filter-group" label="Проект филиалов"
															label-for="branches-project-filter">
									<b-form-select
											id="branches-project-filter"
											v-model="filters.project"
											:options="projectOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group label="Старый город" label-for="branches-city_id-filter">
									<b-form-select
											id="branches-city_id-filter"
											v-model="filters.city_id"
											:options="oldCityOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group label="Новый город" label-for="branches-new_city_id-filter">
									<b-form-select
											id="branches-city_id-filter"
											v-model="filters.new_city_id"
											:options="newCityOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="applyFilters"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Название
						<i class="os-icon os-icon-arrow-up2"
							 v-if="filters.sort_name === null || filters.sort_name !== 'desc'"
							 @click.exact="filters.sort_name='desc'; applyFilters()"
							 @click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							 v-if="filters.sort_name === null || filters.sort_name !== 'asc'"
							 @click.exact="filters.sort_name='asc'; applyFilters()"
							 @click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
					</th>
					<th>
						Адрес
					</th>
					<th>
						Часы работы
					</th>
					<th>
						Телефоны
					</th>
					<th>
						Статус
					</th>
					<th v-if="canUpdate || canDelete">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="branch in helpdeskBranches" :key="branch.id">
				<tr>
					<td>
						{{ branch.name }}
					</td>
					<td v-if="branch.address !== null">
						{{ branch.address }}
					</td>
					<td v-else>
						Не определён
					</td>
					<td v-if="branch.work_hours !== null" class="text-left">
						{{ branch.work_hours }}
					</td>
					<td v-else class="text-left">
						Не определены
					</td>
					<td v-if="branch.phones !== null">
						<div v-for="phone in branch.phones">
							{{ phone.phone }}
						</div>
					</td>
					<td v-else>
						Не определены
					</td>
					<td class="text-center">
						<div v-if="branch.status === 'isAvailable'">
							<div class="status-pill green" v-b-tooltip.html.top title="Открыт"></div>
						</div>
						<div v-else-if="branch.status === 'isRemote'">
							<div class="status-pill yellow" v-b-tooltip.html.top title="Дистанция"></div>
						</div>
						<div v-else-if="branch.status === 'isUnavailable'">
							<div class="status-pill red" v-b-tooltip.html.top title="Не обслуживается"></div>
						</div>
					</td>
					<td class="row-actions text-left">
						<router-link v-b-tooltip.html.top title="Редактировать" v-if="canUpdate"
												 :to="{ name: routeNames.helpdesk.branches.update, params: { branch_id: branch.id }, query: filters} "><i
								class="os-icon os-icon-ui-49"></i></router-link>
						<a href="#" v-b-tooltip.html.top title="Удалить" v-if="canDelete" @click="showDeleteQuestion(branch)" class="danger"><i
								class="os-icon os-icon-ui-15"></i></a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskBranches.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import {helpdeskBranchStatusEnum} from '@/store/helpdesk/branches/constants';
import {permissionNames} from "@/store/passport/auth/constants";
import {
	VUE_APP_PASSPORT_BRANCHES_ATTR
} from '@/config';

export default {
	name: 'HelpDeskBranchTable',
	computed: {
		...mapGetters([
			'helpdeskBranches', 'permissionAvailable', 'helpdeskCities', 'helpdeskNewCities', 'attribute',
		]),
		canUpdate() {
			const avPerms = [
				permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
				permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL,
			];
			for (const perm of avPerms) {
				if (this.permissionAvailable(perm)) {
					return true;
				}
			}
			return false;
		},
		canDelete() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCHES_API);
		},
		canCreate() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCHES_API);
		},
		newCityOptions() {
			const a = [];
			for (let city of this.helpdeskNewCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
		oldCityOptions() {
			const a = [];
			for (let city of this.helpdeskCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
	},
	data() {
		return {
			filters: {
				ids: null,
				city_id: null,
				new_city_id: null,
				sort_name: null,
				status: null,
				project: 'all',
				limit: 50,
				page: 1
			},
			limits: [
				{value: 50, text: 'Филиалов на странице'},
				{value: 10, text: '10'},
				{value: 25, text: '25'},
				{value: 50, text: '50'},
				{value: 100, text: '100'},
				{value: 250, text: '250'}
			],
			projectOptions: [
				{value: 'all', text: 'Все'},
				{value: 'mpk', text: 'Призыванет'},
				{value: 'bfl', text: 'Кредитанет'},
			],
			statusEnum: [],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_BRANCHES_FROM_API', 'HELPDESK_DELETE_BRANCH', 'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_CITIES_FROM_API', 'HELPDESK_GET_NEW_CITIES_FROM_API'
		]),

		applyFilters() {
			//this.filters.limit=50;
			this.filters.page = 1;
			this.canGetNext = true;
			this.canGetPrev = false;
			this.getBranches();
		},

		showDeleteQuestion(branch) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить филиал ' + branch.name + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.HELPDESK_DELETE_BRANCH({id: branch.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Филиал успешно удалён'});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении филиала'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		previous() {
			if (this.filters.page > 0) {
				this.filters.page--;
				this.getBranches();
				this.canGetNext = true;
				if (this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		next() {
			this.canGetPrev = true;
			this.filters.page++;
			this.getBranches();
			if (this.helpdeskBranches.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		getBranches() {
			let attributeBranches = this.attribute('user_working_in_offices');
			if (attributeBranches && attributeBranches.length > 0) {
				this.filters.ids = attributeBranches.replace(' ', '').split(',').map(Number);
			};

			return this.HELPDESK_GET_BRANCHES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка филиалов'
				});
			});
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}
		if (this.filters.offset > 0) {
			this.canGetPrev = true;
		}
		await Promise.all([await this.HELPDESK_GET_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка старых городов'
			});
		}), this.HELPDESK_GET_NEW_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка новых городов'
			});
		}), this.getBranches()]);
		this.statusEnum.push({value: null, text: 'Выберите статус филиала'});
		for (let e in helpdeskBranchStatusEnum) {
			this.statusEnum.push(helpdeskBranchStatusEnum[e]);
		}
	},
};
</script>

<style>

</style>
