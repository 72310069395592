import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_NOTES(deal_id) {
	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/notes'
	).then((resp) => {
		return {notes: resp.data.notes};
	}).catch((err) => {
		return {notes: [], error: err};
	});
}

export function SEND_NOTE({deal_id, text}) {
	return axios.post(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/notes', {text}
	).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}

export function SEND_SUPPORT({deal_id, field, text}) {
	return axios.post(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/support', {field, text}
	).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}
