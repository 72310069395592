<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Создание sms шаблона
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-form-group id="name-input-group" label="Название шаблона" label-for="template-name-input">
				<b-form-input
						id="template-name-input"
						name="template-name-input"
						v-model="$v.template.name.$model"
						:state="validateState('name')"
						aria-describedby="name-input-group-feedback"
						placeholder="Введите название шаблона"
				></b-form-input>
				<b-form-invalid-feedback
						id="name-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="text-input-group" label="Шаблон сообщения" label-for="template-text-input">
				<b-form-input
						id="template-text-input"
						name="template-text-input"
						v-model="$v.template.text.$model"
						:state="validateState('text')"
						aria-describedby="text-input-group-feedback"
						placeholder="Введите текст шаблона сообщения"
				></b-form-input>
				<b-form-invalid-feedback
						id="text-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="scope-input-group" label="Область видимости шаблона" label-for="template-scope-input">
				<b-form-input
						id="template-scope-input"
						name="template-scope-input"
						v-model="$v.template.scope.$model"
						:state="validateState('scope')"
						aria-describedby="scope-input-group-feedback"
						placeholder="Введите область видимости шаблона"
				></b-form-input>
				<b-form-invalid-feedback
						id="scope-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="service-input-group" label="Сервис шаблона" label-for="template-service-input">
				<b-form-input
						id="template-service-input"
						name="template-service-input"
						v-model="$v.template.service.$model"
						:state="validateState('service')"
						aria-describedby="service-input-group-feedback"
						placeholder="Введите название сервиса, к которому принадлежит шаблон"
				></b-form-input>
				<b-form-invalid-feedback
						id="service-input-group-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									@click="submit">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToTemplatesTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskTemplateCreateForm',
	mixins: [validationMixin],
	validations: {
		template: {
			name: {
				required
			},
			text: {
				required
			},
			scope: {
				required
			},
			service: {
				required
			}
		}
	},
	data() {
		return {
			template: {
				name: null,
				text: null,
				scope: null,
				service: null,
			}
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_CREATE_TEMPLATE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		backToTemplatesTable() {
			this.$router.push({name: routeNames.helpdesk.sms.read});
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.template[name];
			return $dirty ? !$error : null;
		},

		async submit() {
			this.$v.template.$touch();
			if (this.$v.template.$anyError) {
				return;
			}
			await this.HELPDESK_CREATE_TEMPLATE(this.template).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Шаблон успешно создан'});
				this.backToTemplatesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании шаблона'
				});
			});
		}
	},
	async mounted() {
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
