<template>
	<div class="element-box">
		<h5 class="form-header">
			Кластеры сотрудников 
			<template v-if="filterCluster">
				/ {{ filterCluster.text }}
			</template>
		</h5>
		<div class="table-responsive">

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация пользователей"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="users-id-filter-group" label="ID" label-for="users-id-filter">
									<b-form-input
											id="users-id-filter"
											v-model="filters.id"
											placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-login-filter-group" label="Логин"
															label-for="users-login-filter">
									<b-form-input
											id="users-login-filter"
											v-model="filters.login"
											placeholder="Введите искомый login"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-name-filter-group" label="ФИО"
															label-for="users-name-filter">
									<b-form-input
											id="users-name-filter"
											v-model="filters.name"
											placeholder="Введите ФИО"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Вывод пользователей"
															label-for="users-disabled-filter">
									<b-form-select
											v-model="filters.disabled"
											:options="disabledSelectOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group label="Кластер" label-for="clusters-cluster_id-filter">
									<b-form-select
											id="clusters-cluster_id-filter"
											v-model="filters.clusterId"
											:options="clustersOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
								id="passport-users-get-limit"
								name="passport-users-get-limit"
								v-model="filters.limit"
								:options="limits"
								class="form-control form-control-sm rounded bright"
								@change="getUsers()"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
					<tr>
						<th>
							<div>
								Имя
							</div>
						</th>
						<th>
							<div>
								Кластер
							</div>
						</th>
					</tr>
				</thead>
				<tbody v-for="user in passportUsers" :key="user.id">
					<tr>
						<td>
							<div>
								<router-link
									title="Редактировать"
									:to="{ name: routeNames.passport.clusters.update, params: { user_id: user.id }, query: filters}"
								>
									{{ user.name }}
								</router-link>
							</div>
						</td>
						<td>
							<div>
								{{ getUserCluster(user) }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && passportUsers.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ClustersTable',
	data() {
		return {
			filters: {
				id: null,
				ids: null,
				login: null,
				name: null,
				disabled: 0,
				clusterId: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Пользователей на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],

			disabledSelectOptions: [
				{value: undefined, text: 'Все пользователи'},
				{value: 1, text: 'Только отключённые'},
				{value: 0, text: 'Только активные'},
			],

			canGetNext: true,
			canGetPrev: false,
			routeNames,
		};
	},
	computed: {
		...mapGetters([
			'passportUsers',
			'helpdeskClusters',
			'staffClusters',
		]),
		filterCluster() {
			if (this.filters.clusterId) {
				return this.clustersOptions.find(o => o.value === this.filters.clusterId) || null;
			}

			return null;
		},
		clustersOptions() {
			const a = [];
			for (let cluster of this.helpdeskClusters) {
				a.push({value: cluster.id, text: cluster.name});
			}
			a.unshift({value: null, text: 'Выберите кластер...'});
			return a;
		},
	},
	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_CLUSTERS_FROM_API',
			'HELPDESK_GET_STAFF_CLUSTERS_FROM_API',
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;

			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getStaffClusters(ids);
			}
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getUsers();

				const ids = [];

				for (let i = 0; i < this.passportUsers.length; i += 1) {
					ids.push(this.passportUsers[i].id);
				}

				if (ids.length > 0) {
					await this.getStaffClusters(ids);
				}

				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getStaffClusters(ids);
			}

			if (this.passportUsers.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getUsers() {
			const filters = JSON.parse(JSON.stringify(this.filters));

			if (this.filters.clusterId) {
				const clusters = await this.getUsersByClusterId(this.filters.clusterId);

				if (clusters && clusters.length) {
					const userIds = [];

					for (let i = 0; i < clusters.length; i += 1) {
						userIds.push(clusters[i].userId);
					}

					filters.ids = userIds.join(',');
				}

				filters.clusterId = null;
			}

			await this.GET_USERS_FROM_API(filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пользователей'
				});
			});
			if (this.filters.offset > 0) {
				this.canGetPrev = true;
			}
		},

		async getStaffClusters(users) {
			await this.HELPDESK_GET_STAFF_CLUSTERS_FROM_API({userId: users.join(',')}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров сотрудников'
				});
			});
		},

		async getUsersByClusterId(clusterId) {
			const res = await this.HELPDESK_GET_STAFF_CLUSTERS_FROM_API({clusterId: clusterId}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров сотрудников'
				});
			});

			return res;
		},

		getUserCluster(user) {
			const clusters = [];
			const result = this.staffClusters.find(o => o.userId === user.id) || null;

			if (result) {
				if (result.clusters.length > 0) {
					for (let i = 0; i < result.clusters.length; i += 1) {
						for (let j = 0; j < this.helpdeskClusters.length; j += 1) {
							if (this.helpdeskClusters[j].id === result.clusters[i]) {
								clusters.push(this.helpdeskClusters[j].name);
							}
						}
					}
				}
			}

			return clusters.join(', ');
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}

		await this.getUsers();

		await this.HELPDESK_GET_CLUSTERS_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});

		const ids = [];

		for (let i = 0; i < this.passportUsers.length; i += 1) {
			ids.push(this.passportUsers[i].id);
		}

		if (ids.length > 0) {
			await this.getStaffClusters(ids);
		}
	},
};
</script>

<style>
</style>
