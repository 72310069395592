<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица ответственных
		</h5>
		<div class="form-desc">
			Выберите нужного ответственного и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpback.responsibles.create }">Создать нового ответственного</router-link>
		</div>
		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация ответственных"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="responsibles-id-filter-group" label="ID" label-for="responsibles-id-filter">
									<b-form-input
											id="responsibles-id-filter"
											v-model="filters.id"
											placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>

								<b-form-group id="responsibles-pipeline-filter-group" label="Воронка продаж"
															label-for="responsibles-pipeline-filter">
									<b-form-select
											id="responsibles-pipeline-filter"
											v-model="filters.pipeline"
											:options="pipelineOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group id="responsibles-amoid-filter-group" label="ID пользователя AMO"
															label-for="responsibles-amoid-filter">
									<!--								<b-form-input-->
									<!--										id="responsibles-amoid-filter"-->
									<!--										v-model="filters.amo_user_id"-->
									<!--										placeholder="Введите id пользователя в амо"-->
									<!--								></b-form-input>-->
									<b-form-select
											id="responsibles-amoid-filter"
											v-model="filters.amo_user_id"
											:options="userOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group id="responsibles-cityid-filter-group" label="ID города AMO"
															label-for="responsibles-cityid-filter">
									<!--									<b-form-input-->
									<!--											id="responsibles-cityid-filter"-->
									<!--											v-model="filters.amo_city_id"-->
									<!--											placeholder="Введите id города в амо"-->
									<!--									></b-form-input>-->
									<b-form-select
											id="responsibles-cityid-filter"
											v-model="filters.amo_city_id"
											:options="cityOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group id="responsibles-bxid-filter-group" label="ID пользователя Bitrix"
															label-for="responsibles-bxid-filter">
									<b-form-input
											id="responsibles-bxid-filter"
											v-model="filters.bitrix_user_id"
											placeholder="Введите id пользователя в Bitrix"
									></b-form-input>
								</b-form-group>

								<b-form-group id="responsibles-name-filter-group" label="ФИО ответственного"
															label-for="responsibles-name-filter">
									<b-form-input
											id="responsibles-name-filter"
											v-model="filters.name"
											placeholder="Введите ФИО ответственного"
									></b-form-input>
								</b-form-group>

								<b-form-group id="responsibles-disabled-filter-group" label="Активен"
															label-for="responsibles-disabled-filter">
									<b-form-checkbox
											id="responsibles-disabled-filter"
											v-model="filters.disabled"
											value="0"
											unchecked-value="1"
									></b-form-checkbox>
								</b-form-group>

								<b-form-group id="responsibles-international-filter-group" label="Снятие с учета - Международный"
															label-for="responsibles-international-filter">
									<b-form-checkbox
											id="responsibles-international-filter"
											v-model="filters.international"
											value="1"
											unchecked-value="0"
									></b-form-checkbox>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="responsibles-limit"
									name="responsibles-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPBACK_GET_RESPONSIBLES_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						ID
						<i class="os-icon os-icon-arrow-up2"
							 v-if="filters.order_column === null || (filters.order_direction !== 'desc' && filters.order_column === 'id')"
							 @click.exact="filters.order_direction='desc'; filters.order_column = 'id'; applyFilters()"
							 @click.ctrl="filters.order_column=null; filters.order_direction = null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							 v-if="filters.order_column === null || (filters.order_direction !== 'asc' && filters.order_column === 'id')"
							 @click.exact="filters.order_direction='asc'; filters.order_column = 'id'; applyFilters()"
							 @click.ctrl="filters.order_column=null; filters.order_direction = null; applyFilters()"
						></i>
					</th>
					<th>
						Имя
					</th>
					<th>
						Воронка
					</th>
					<th>
						Коэффициент распределения
						<i class="os-icon os-icon-arrow-up2"
							 v-if="filters.order_column === null || (filters.order_direction !== 'desc' && filters.order_column === 'coeff')"
							 @click.exact="filters.order_direction='desc'; filters.order_column = 'coeff'; applyFilters()"
							 @click.ctrl="filters.order_column=null; filters.order_direction = null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							 v-if="filters.order_column === null || (filters.order_direction !== 'asc' && filters.order_column === 'coeff')"
							 @click.exact="filters.order_direction='asc'; filters.order_column = 'coeff'; applyFilters()"
							 @click.ctrl="filters.order_column=null; filters.order_direction = null; applyFilters()"
						></i>
					</th>
					<th v-if="canUpdateHelpbackResponsibles || canDeleteHelpbackResponsibles" class="text-right">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="responsible in helpbackResponsibles" :key="responsible.id">
				<tr>
					<td>
						{{ responsible.id }}
					</td>
					<td>
						{{ responsible.name }}
					</td>
					<td class="text-left">
						{{ responsible.pipeline }}
					</td>
					<td>
						{{ responsible.coeff / 2 }}
					</td>
					<td v-if="canUpdateHelpbackResponsibles || canDeleteHelpbackResponsibles" class="row-actions text-right">
						<router-link v-if="canUpdateHelpbackResponsibles" v-b-tooltip.html.top title="Редактировать"
												 :to="{ name: routeNames.helpback.responsibles.update, params: { responsible_id: responsible.id }, query: filters} ">
							<i class="os-icon os-icon-ui-49"></i>
						</router-link>

						<a v-if="canDeleteHelpbackResponsibles" v-b-tooltip.html.top title="Удалить"
							 @click="showDeleteQuestion(responsible)" class="danger">
							<i class="os-icon os-icon-ui-15"></i>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpbackResponsibles.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pipelineEnum} from '@/store/helpback/responsible/constants';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpBackResponsibleTable',
	data() {
		return {
			filters: {
				id: null,
				pipeline: null,
				amo_user_id: null,
				bitrix_user_id: null,
				amo_city_id: null,
				name: null,
				disabled: -1,
				international: -1,
				order_column: null,
				order_direction: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Ответственных на странице'},
				{value: 10, text: '10'},
				{value: 50, text: '50'},
				{value: 250, text: '250'},
				{value: 500, text: '500'},
			],
			deleteCheck: false,
			canGetNext: true,
			canGetPrev: false,
			pipelineOptions: [
				{value: null, text: 'Выберите воронку продаж'}
			],
			userOptions: [
				{value: null, text: 'Выберите пользователя AMO'}
			],
			cityOptions: [
				{value: null, text: 'Выберите город AMO'}
			],
			routeNames
		};
	},

	computed: {
		...mapGetters([
			'helpbackResponsibles', 'canUpdateHelpbackResponsibles', 'canDeleteHelpbackResponsibles',
			'helpbackUsersDict', 'helpbackCitiesDict', 'helpbackLastRespCity', 'helpbackLastRespGroup'
		]),
	},

	methods: {
		...mapActions([
			'HELPBACK_GET_RESPONSIBLES_FROM_API', 'HELPBACK_GET_ALL_RESP_FROM_API', 'HELPBACK_DELETE_RESPONSIBLE',
			'HELPBACK_GET_USERS_DICT_FROM_API', 'HELPBACK_GET_CITIES_DICT_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY', 'HELPBACK_GET_ALL_USERS_FROM_API', 'HELPBACK_TG_NOTIFY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getResponsibles();
		},

		showDeleteQuestion(responsible) {
			this.deleteCheck = false;
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить ответственного ' + responsible.name + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(async value => {
				if (value === true) {
					if (responsible.pipeline !== pipelineEnum.dist.value && responsible.pipeline !== pipelineEnum.mlm.value &&
							(this.helpbackLastRespCity(responsible) || this.helpbackLastRespGroup(responsible))) {
						this.$bvModal.msgBoxConfirm('Ответственный ' + responsible.name + ' - последний в группе. Нельзя оставлять группу без ответственного', {
							title: 'Подтвердите действие',
							size: 'sm',
							buttonSize: 'sm',
							okVariant: 'danger',
							okTitle: 'Удалить',
							cancelTitle: 'Отменить',
							footerClass: 'p-2',
							hideHeaderClose: false,
							centered: true
						}).then(async value => {
							if (value === true) {
								await this.delete(responsible);
								const city = this.helpbackCitiesDict.find(v => v.id === responsible.amo_city_id);
								const user = this.helpbackUsersDict.find(v => v.id === responsible.amo_user_id);
								await this.HELPBACK_TG_NOTIFY({
									city: city ? city.name : '',
									group: user ? user.group_name : ''
								}).catch(() => {
									this.ADD_NOTIFICATION_TO_QUERY({
										type: 'error',
										title: 'Ошибка',
										message: 'Произошла ошибка при отправке сообщения в telegram'
									});
								});
							}
						}).catch((err) => {
							console.log(err);
							this.ADD_NOTIFICATION_TO_QUERY({
								type: 'error',
								title: 'Ошибка',
								message: 'Произошла ошибка при создании диалогового окна'
							});
						});
					} else {
						await this.delete(responsible);
					}
				}
			});
		},

		async delete(responsible) {
			await this.HELPBACK_DELETE_RESPONSIBLE({id: responsible.id, filters: this.filters}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Ответственный успешно удалён'
				});
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при удалении ответственного'
				});
			});
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getResponsibles();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},
		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getResponsibles();
			if (this.helpbackResponsibles.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getResponsibles() {
			await this.HELPBACK_GET_RESPONSIBLES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка ответственных'
				});
			});
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}
		if (this.filters.offset > 0) {
			this.canGetPrev = true;
		}
		await this.HELPBACK_GET_USERS_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка пользователей'
			});
		});
		this.helpbackUsersDict.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		});
		for (let user of this.helpbackUsersDict) {
			this.userOptions.push({value: user.id, text: user.name + ' - ' + user.login});
		}

		await this.HELPBACK_GET_CITIES_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка городов'
			});
		});
		this.helpbackCitiesDict.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		});
		for (let city of this.helpbackCitiesDict) {
			this.cityOptions.push({value: city.id, text: city.name});
		}

		await this.getResponsibles();
		for (let key in pipelineEnum) {
			this.pipelineOptions.push(pipelineEnum[key]);
		}
		await this.HELPBACK_GET_ALL_RESP_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка ответственных'
			});
		});
		await this.HELPBACK_GET_ALL_USERS_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка пользователей AMO'
			});
		});
	}
};
</script>

<style>

</style>
