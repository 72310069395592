<template>
	<div class="element-box">
		<h5 class="form-header">
			Кластеры работы сотрудника {{ user.name }}
		</h5>
		<div class="form-desc">
			Измените необходимые данные и нажмите сохранить
		</div>

		<div>
			<b-form-group id="clusters-input-group" label="Кластеры" label-for="user-clusters-input">
				<b-form-tags id="user-clusters-input" v-model="userClusters" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onClusterDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									{{ JSON.parse(tag).name }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление кластеров
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск офисов"
										label-for="cluster-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										description=""
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchClusters"
											id="cluster-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название клсстера"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="cluster in clustersAvailableOptions"
									:key="cluster.id"
									@click="onClusterAddClick({ option: cluster, addTag: addTag, removeTag: removeTag })"
							>{{ cluster.name }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="clustersAvailableOptions.length === 0">
								По вашему запросу ничего не найдено
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>
		</div>
		<div class="form-buttons-w">
			<b-button
				class="btn btn-primary"
				type="button"
				variant="primary"
				name="button"
				@click="saveClusters()"
			>
				Сохранить
			</b-button>
			<b-button class="btn btn-grey" @click="backToClustersTable">Назад</b-button>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ClustersUpdateForm',
	data() {
		return {
			filters: {},
			user: {
				id: '',
				name: '',
			},
			userClusters: [],
			clusterOptions: [],
			searchClusters: '',
		};
	},

	computed: {
		...mapGetters([
			'passportUsers',
			'staffClusters',
			'helpdeskClusters', 
		]),
		clustersCriteria() {
			return this.searchClusters.trim().toLowerCase();
		},
		clustersAvailableOptions() {
			const criteria = this.clustersCriteria;
			if (criteria) {
				return this.clusterOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.clusterOptions;
		},
	},

	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'HELPDESK_GET_CLUSTERS_FROM_API',
			'HELPDESK_GET_STAFF_CLUSTERS_FROM_API',
			'HELPDESK_SAVE_STAFF_CLUSTERS',
			'ADD_NOTIFICATION_TO_QUERY',
		]),

		backToClustersTable() {
			this.$router.push({name: routeNames.passport.clusters.read, query: this.filters});
		},

		async getClusters() {
			await this.HELPDESK_GET_STAFF_CLUSTERS_FROM_API({userId: this.user.id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров сотрудника'
				});
			});
		},

		async saveClusters() {
			const clustersData = {
				userId: this.user.id,
				clusters: [],
			};

			if (this.userClusters.length) {
				this.userClusters.forEach(function callback(userCluster) {
					let cluster = userCluster;
					if (typeof cluster != 'object') {
						cluster = JSON.parse(cluster);
					}
					clustersData.clusters.push(cluster.id);
				});
			}

			await this.HELPDESK_SAVE_STAFF_CLUSTERS(clustersData).then(async () => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Успешно'});
				this.backToClustersTable();
			}).catch((e) => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка'
				});
			});
		},

		onClusterAddClick({option, addTag, removeTag}) {
			if (this.userClusters.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Кластер уже добавлен'
				});
				return;
			}

			for (let i = 0; i < this.userClusters.length; i += 1) {
				let cluster = this.userClusters[i];
				if (typeof cluster != 'object') {
					cluster = JSON.parse(cluster);
				}
				if (cluster.type === option.type) {
					this.onClusterDeleteClick({ option: this.userClusters[i], removeTag: removeTag });
				}
			}

			this.userClusters.push({id: option.id, name: option.name, type: option.type});

			let id = this.clusterOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.clusterOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchClusters = '';
		},

		onClusterDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let clusterToRemove = JSON.parse(option);

			for (let i = 0; i < this.userClusters.length; i += 1) {
				let cluster = this.userClusters[i];
				if (typeof cluster != 'object') {
					cluster = JSON.parse(cluster);
				}
				if (cluster.type === clusterToRemove.type) {
					this.userClusters.splice(i, 1);
				}
			}

			this.clusterOptions.push(clusterToRemove);
			this.clusterOptions.sort((a, b) => a.id < b.id);

			removeTag(option);
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}

		if (this.$route.params.user_id !== '0') {
			await this.GET_USERS_FROM_API({id: this.$route.params.user_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении пользователя'
				});
			});
			this.user = Object.assign({}, this.passportUsers[0]);

			await this.HELPDESK_GET_CLUSTERS_FROM_API({limit: 1000}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров'
				});
			});

			await this.getClusters();
			
			let userClusters = [];

			if (this.staffClusters && this.staffClusters.length) {
				userClusters = this.staffClusters[0].clusters;
			}

			for (let i = 0; i < this.helpdeskClusters.length; i++) {
				if (userClusters.includes(this.helpdeskClusters[i].id)) {
					this.userClusters.push({
						id: this.helpdeskClusters[i].id,
						name: this.helpdeskClusters[i].name,
						type: this.helpdeskClusters[i].type,
					});
				} else {
					this.clusterOptions.push({
						id: this.helpdeskClusters[i].id,
						name: this.helpdeskClusters[i].name,
						type: this.helpdeskClusters[i].type,
					});
				}
			}

			this.loaded = true;
		}
	},
};
</script>

<style>
</style>
