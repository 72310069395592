<template>
	<div class="element-box">
		<b-form @submit.stop.prevent="submit">
			<h5 class="form-header">
				Изменение данных ответственного
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-form-group id="name-input-group" label="Имя ответственного" label-for="responsible-name-input">
				<b-form-input
						id="responsible-name-input"
						v-model="responsible.name"
						:state="nameState"
						aria-describedby="name-input-responsible-feedback"
						placeholder="Введите имя ответственного"
				></b-form-input>
				<b-form-invalid-feedback
						id="name-input-responsible-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-user-id-input-group" label="Пользователь AMO" label-for="responsible-amo-user-id-input">
				<b-form-select
						id="responsible-amo-user-id-input"
						v-model="responsible.amo_user_id"
						:options="userOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="disabled-input-group" label="Активен" label-for="responsible-disabled-input">
				<b-form-checkbox
						id="responsible-disabled-input"
						v-model="responsible.disabled"
						value="0"
						unchecked-value="1"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group id="coeff-input-group" label="Коэффициент распределения" label-for="responsible-coeff-input">
				<b-input-group>
					<b-input-group-prepend>
						<b-btn
								variant="outline-info"
								@click="decrement"
						>-
						</b-btn>
					</b-input-group-prepend>

					<b-form-input
							id="responsible-coeff-input"
							v-model="responsible.coeff"
							type="number"
							:state="coeffState"
					></b-form-input>

					<b-input-group-append>
						<b-btn
								variant="outline-secondary"
								@click="increment"
						>+
						</b-btn>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<b-form-group id="pipeline-input-group" label="Выбор воронки продаж" label-for="responsible-pipeline-input">
				<b-form-select
						id="responsible-pipeline-input"
						v-model="responsible.pipeline"
						:state="pipelineState"
						:options="pipelineOptions"
						@change="clearResponsibleVars"
						aria-describedby="pipeline-input-responsible-feedback"
				></b-form-select>
				<b-form-invalid-feedback
						id="pipeline-input-responsible-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.office.value" id="amo-city-id-input-group"
										label="ID города AMO" label-for="responsible-amo-city-id-input">
				<b-form-select
						id="responsible-amo-city-id-input"
						v-model="responsible.amo_city_id"
						:state="amoCityIdState"
						aria-describedby="amo-city-id-input-responsible-feedback"
						:options="cityOptions"
				></b-form-select>
				<b-form-invalid-feedback
						id="amo-city-id-input-responsible-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.mlm.value" id="bitrix-user-id-input-group"
										label="ID пользователя Bitrix" label-for="responsible-bitrix-user-id-input">
				<b-form-input
						id="responsible-bitrix-user-id-input"
						v-model="responsible.bitrix_user_id"
						:state="bitrixUserIdState"
						aria-describedby="bitrix-user-id-input-responsible-feedback"
						placeholder="Введите id пользователя Bitrix"
				></b-form-input>
				<b-form-invalid-feedback
						id="bitrix-user-id-input-responsible-feedback"
				>Обязательное поле. Должно состоять только из цифр больше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.dist.value" id="disabled-input-group" 
										label="Снятие с учета - Международный" label-for="responsible-international-input">
				<b-form-checkbox
						id="responsible-international-input"
						v-model="responsible.international"
						value="1"
						unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.dist.value" id="distribute-cc-input-group"
										label="Флаг распределения сделок из Колл-Центра" label-for="responsible-distribute-cc-input">
				<b-form-checkbox
						id="responsible-distribute-cc-input"
						v-model="responsible.distribute_cc"
						value="1"
						unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									@click="showSaveQuestion()">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToResponsiblesTable()">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pipelineEnum} from '@/store/helpback/responsible/constants';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpBackResponsibleForm',
	data() {
		return {
			responsible: {
				id: 0,
				name: null,
				amo_user_id: 0,
				disabled: 0,
				international: 0,
				coeff: 1,
				pipeline: null,
				amo_city_id: 0,
				bitrix_user_id: 0,
				distribute_cc: 0
			},
			responsible_original: {
				id: 0,
				name: null,
				amo_user_id: 0,
				disabled: 0,
				international: 0,
				coeff: 0,
				pipeline: null,
				amo_city_id: 0,
				bitrix_user_id: 0,
				distribute_cc: 0
			},
			pipelineOptions: [
				{value: null, text: 'Выберите воронку продаж'}
			],
			userOptions: [
				{value: null, text: 'Выберите пользователя AMO'}
			],
			cityOptions: [
				{value: null, text: 'Выберите город AMO'}
			],
			valid: false,
			pipelineEnum
		};
	},
	watch: {
		'responsible': {
			deep: true,
			handler() {
				this.checkFields();
			}
		},
	},

	computed: {
		...mapGetters([
			'helpbackResponsibles', 'helpbackUsersDict', 'helpbackCitiesDict', 'helpbackLastRespCity', 'helpbackLastRespGroup'
		]),

		nameState() {
			return this.responsible.name !== null && this.responsible.name !== '';
		},
		coeffState() {
			return (this.isInt(this.responsible.coeff) || this.isFloat(this.responsible.coeff)) && this.responsible.coeff >= 0.5;
		},
		pipelineState() {
			return this.responsible.pipeline !== null;
		},
		amoCityIdState() {
			return !(this.responsible.pipeline === 'office' && this.responsible.amo_city_id <= 0);
		},
		bitrixUserIdState() {
			return !(this.responsible.pipeline === 'mlm' && (this.responsible.bitrix_user_id === null || this.responsible.bitrix_user_id === ''));
		},

		nameChanged() {
			return this.responsible.name !== this.responsible_original.name;
		},
		amoUserIdChanged() {
			return Number(this.responsible.amo_user_id) !== this.responsible_original.amo_user_id;
		},
		disabledChanged() {
			return Number(this.responsible.disabled) !== Number(this.responsible_original.disabled);
		},
		internationalChanged() {
			return Number(this.responsible.international) !== Number(this.responsible_original.international);
		},
		coeffChanged() {
			return Number(this.responsible.coeff) !== Number(this.responsible_original.coeff);
		},
		pipelineChanged() {
			return this.responsible.pipeline !== this.responsible_original.pipeline;
		},
		amoCityIdChanged() {
			if (this.responsible.pipeline === 'office') {
				if (this.responsible.amo_city_id !== null && Number(this.responsible.amo_city_id) !== this.responsible_original.amo_city_id) {
					return true;
				}
			}
			return false;
		},
		bitrixUserIdChanged() {
			if (this.responsible.pipeline === 'mlm') {
				if (this.responsible.bitrix_user_id !== null && Number(this.responsible.bitrix_user_id) !== this.responsible_original.bitrix_user_id) {
					return true;
				}
			}
			return false;
		},
		distributeCCChanged() {
			return Number(this.responsible.distribute_cc) !== Number(this.responsible_original.distribute_cc);
		}
	},

	methods: {
		...mapActions([
			'HELPBACK_UPDATE_RESPONSIBLE', 'HELPBACK_GET_RESPONSIBLES_FROM_API', 'HELPBACK_GET_ALL_RESP_FROM_API',
			'HELPBACK_GET_USERS_DICT_FROM_API', 'HELPBACK_GET_CITIES_DICT_FROM_API', 'ADD_NOTIFICATION_TO_QUERY',
			'HELPBACK_GET_ALL_USERS_FROM_API', 'HELPBACK_TG_NOTIFY'
		]),
		checkFields() {
			this.valid = this.nameState && this.coeffState && this.pipelineState && this.amoCityIdState
					&& this.bitrixUserIdState &&
					(this.nameChanged || this.amoUserIdChanged || this.coeffChanged || this.pipelineChanged || this.amoCityIdChanged ||
							this.bitrixUserIdChanged ||
							this.disabledChanged || this.internationalChanged || this.distributeCCChanged);
		},

		isInt(n) {
			return Number(n) === n && n % 1 === 0;
		},

		isFloat(n) {
			return Number(n) === n && n % 1 !== 0;
		},

		increment() {
			if (this.responsible.coeff <= 0.5) {
				this.responsible.coeff = 0.5;
			}
			this.responsible.coeff += 0.5;
		},
		decrement() {
			if (this.responsible.coeff <= 0.5) {
				this.responsible.coeff = 0.5;
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Коэффициент распределения должен быть больше 0.5'
				});
			} else {
				this.responsible.coeff -= 0.5;
			}
		},

		backToResponsiblesTable() {
			this.$router.push({name: routeNames.helpback.responsibles.read, query: this.$route.query});
		},

		clearResponsibleVars() {
			this.responsible.amo_city_id = null;
			this.responsible.bitrix_user_id = null;
		},

		async showSaveQuestion() {
			this.responsible.disabled = Number(this.responsible.disabled);
			this.responsible.international = Number(this.responsible.international);
			this.responsible.distribute_cc = Number(this.responsible.distribute_cc);
			// при отключении активности или флага распределения кц или изменении воронки или города
			if ((this.responsible_original.disabled === 0 && this.responsible.disabled === 1) ||
					(this.responsible_original.distribute_cc === 0 && this.responsible.distribute_cc === 1) ||
					(this.responsible_original.pipeline !== this.responsible.pipeline) ||
					(this.responsible_original.amo_city_id !== this.responsible.amo_city_id)) {
				// идёт проверка - последний ли ответственный в группе/городе
				if (this.responsible.pipeline !== pipelineEnum.dist.value && this.responsible.pipeline !== pipelineEnum.mlm.value &&
						(this.helpbackLastRespCity(this.responsible_original) || this.helpbackLastRespGroup(this.responsible_original))) {
					this.$bvModal.msgBoxConfirm('Ответственный ' + this.responsible.name + ' - последний в группе. Нельзя оставлять группу без ответственного', {
						title: 'Подтвердите действие',
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: 'Деактивировать',
						cancelTitle: 'Отменить',
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: true
					}).then(async value => {
						if (value === true) {
							await this.update();
							const city = this.helpbackCitiesDict.find(v => v.id === this.responsible_original.amo_city_id);
							const user = this.helpbackUsersDict.find(v => v.id === this.responsible_original.amo_user_id);
							await this.HELPBACK_TG_NOTIFY({
								city: city ? city.name : '',
								group: user ? user.group_name : ''
							}).catch(() => {
								this.ADD_NOTIFICATION_TO_QUERY({
									type: 'error',
									title: 'Ошибка',
									message: 'Произошла ошибка при отправке сообщения в telegram'
								});
							});
						}
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при создании диалогового окна'
						});
					});
				} else {
					await this.update();
				}
			} else {
				await this.update();
			}
		},

		async update() {
			this.responsible.coeff = this.responsible.coeff * 2;
			await this.HELPBACK_UPDATE_RESPONSIBLE({responsible: this.responsible}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Ответственный успешно обновлён'
				});
				this.backToResponsiblesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении ответственного'
				});
			});
		},

		async submit() {
			this.responsible.coeff = this.responsible.coeff * 2;
			await this.HELPBACK_UPDATE_RESPONSIBLE({responsible: this.responsible}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Ответственный успешно обновлён'});
				this.backToResponsiblesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении ответственного'
				});
			});
		}
	},

	async mounted() {
		await this.HELPBACK_GET_USERS_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка пользователей'
			});
		});
		for (let user of this.helpbackUsersDict) {
			this.userOptions.push({value: user.id, text: user.name + ' - ' + user.login});
		}

		await this.HELPBACK_GET_CITIES_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка городов'
			});
		});
		for (let city of this.helpbackCitiesDict) {
			this.cityOptions.push({value: city.id, text: city.name});
		}

		if (this.$route.params.responsible_id !== '0') {
			await this.HELPBACK_GET_RESPONSIBLES_FROM_API({id: this.$route.params.responsible_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении ответственного'
				});
			});
			this.helpbackResponsibles[0].coeff = this.helpbackResponsibles[0].coeff / 2;
			for (let key in this.helpbackResponsibles[0]) {
				this.responsible[key] = this.helpbackResponsibles[0][key];
			}
			if (this.responsible.amo_user_id === 0) {
				this.responsible.amo_user_id = null;
			}
		}
		for (let key in pipelineEnum) {
			this.pipelineOptions.push(pipelineEnum[key]);
		}
		for (let key in this.responsible) {
			this.responsible_original[key] = this.responsible[key];
		}
		await this.HELPBACK_GET_ALL_RESP_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка ответственных'
			});
		});
		console.log('Последний ответственный в городе? ' + this.helpbackLastRespCity(this.responsible_original));
		console.log('Последний ответственный в группе? ' + this.helpbackLastRespGroup(this.responsible_original));
	},
};
</script>

<style>

</style>
