<template>
	<div class="element-box">
		<h5 class="form-header">
			Список сервисов компании
		</h5>

		<div v-for="group in this.helpdeskSites" :key="group.id">
			<h6>
				{{ group.name }}
			</h6>
			<ul v-for="site in group.sites" :key="site.id" class="list-group">
				<a :href="site.url" class="list-group-item list-group-item-action">{{ site.name }}</a>
			</ul>
			<br>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'SitesTable',
	methods: {
		...mapActions([
			'HELPDESK_GET_SITES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),
	},
	computed: {
		...mapGetters([
			'helpdeskSites'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_SITES_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка сервисов'
			});
		});
	},
};
</script>

<style>

</style>
