import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/tariffs/event-types';

function tariffToFormData(
    {
        name,
        description
    }
) {
    let formData = new FormData();
    if (name !== '' && name != null) {
        formData.append('name', name);
    }
    if (description !== '' && description != null) {
        formData.append('description', description)
    }
    return formData;
}

export const actions = {

    async HELPDESK_GET_TARIFFS_FROM_API({commit}, {name, limit, page}) {
        let filter = {
            name: name,
            limit: limit,
            page: page
        };
        for (let key in filter) {
            if (filter[key] === '') {
                filter[key] = null;
            }
        }
        return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/tariffs', {
            params: filter
        }).then((resp) => {
            commit(events.HELPDESK_SET_TARIFFS, resp.data);
            return resp.data;
        });
    },

    async HELPDESK_GET_TARIFF_FROM_API({commit}, id) {
        return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/tariff/' + id).then((resp) => {
            commit(events.HELPDESK_SET_TARIFF, resp.data);
            return resp.data;
        });
    },

    async HELPDESK_DELETE_TARIFF({dispatch}, {id, filters}) {
        return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/tariff/' + id).then(() => {
            dispatch('HELPDESK_GET_TARIFFS_FROM_API', filters);
        });
    },

    async HELPDESK_UPDATE_TARIFF({commit}, {id: id, tariff: tariff}) {
        return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/tariff/' + id, tariffToFormData(tariff), {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((resp) => {
            commit(events.HELPDESK_SET_TARIFF, resp.data);
            return resp.data;
        });
    },

    async HELPDESK_CREATE_TARIFF({commit}, tariff) {
        return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/tariff', tariffToFormData(tariff), {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((resp) => {
            commit(events.HELPDESK_SET_TARIFF, resp.data);
            return resp.data;
        });
    },
}