<template>
  <div class="element-box" v-if="is_loaded">
    <b-form @submit.stop.prevent="submit">
      <h5 class="form-header">
        Изменение данных области видимости
      </h5>
      <div class="form-desc">
        Измените необходимые данные и нажмите сохранить
      </div>

      <b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
      >
        <div>
          Код области видимости: {{ scope.code }}<br>
        </div>
      </b-modal>

      <b-form-group id="code-input-group" label="Код области видимости" label-for="scope-code-input">
        <b-form-input
            id="scope-code-input"
            v-model="scope.code"
            placeholder="Введите код области видимости"
        ></b-form-input>
      </b-form-group>

      <div class="alert alert-danger" v-if="isError">
        <p>Поле code обязательное!</p>
      </div>

      <div class="form-buttons-w">
        <b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
        </b-button>
        <b-button class="btn btn-grey" @click="backToScopeTable()">Отмена</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ScopeUpdateForm',
	data() {
		return {
			scope: {
				code: '',
			},
			scope_original: {
				code: '',
			},
			valid: false,
			is_loaded: false,
			isError: false,
		};
	},
	watch: {
		'scope.code': function (val, oldVal) {
			if (this.is_loaded) {
				this.checkFields();
			}
		},
	},
	methods: {
		...mapActions([
			'UPDATE_SCOPE', 'GET_SCOPES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),
		validateCode() {
			this.isError = this.scope.code.length === 0;
		},

		backToScopeTable() {
			this.$router.push({name: routeNames.passport.scopes.read});
		},

		async submit() {
			await this.UPDATE_SCOPE({scope: this.scope}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Область видимости успешно обновлена' });
				this.backToScopeTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при обновлении области видимости' });
			});
		},

		checkFields() {
			this.isError = false;
			let codeValid = false;

			if (this.scope.code && this.scope.code.length > 0) {
				codeValid = true;
			}
			if (codeValid && this.scope.code !== this.scope_original.code) {
				this.valid = true;
			} else {
				this.valid = false;
				this.validateCode();
			}
		},
	},

	computed: {
		...mapGetters([
			'passportScopes'
		]),
	},

	async mounted() {
		await this.GET_SCOPES_FROM_API({id: this.$route.params.scope_id}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении области видимости' });
		});
		if (this.passportScopes[0]) {

			this.scope_original.code = this.passportScopes[0].code;

			this.is_loaded = true;
			this.scope = this.passportScopes[0];
		}
	},
};
</script>

<style>

</style>
