<template>
	<div class="row">
		<div class="col-sm-5">
			<div class="element-wrapper">
				<div class="element-box">
					<h5 class="element-header">
						{{ deal.name }}
					</h5>

					<b-form-group label="Статус" label-for="deal-status-input">
						<b-form-select
								id="deal-status-input"
								v-model="deal.status_id"
								:options="statusOptions"
								:disabled="!statusEditable"
						></b-form-select>
					</b-form-group>

					<b-form-group label="Задачи"
												label-for="deal-tasks">
						<b-input-group>
							<b-form-input
									id="deal-tasks"
									:value="task.text"
									disabled
							></b-form-input>
							<b-input-group-append>
								<!--:class="{'btn-grey': task.hasTask, 'btn-primary': !task.hasTask, 'btn-secondary': false}"-->
								<b-button
										:variant="task.hasTask ? 'primary' : 'grey'"
										@click="finishTask"
										:disabled="!task.hasTask"
								>Завершить
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>

					<b-tabs content-class="mt-3" style="max-height: 66vh">
						<b-tab title="Добавление рекламы" active>
							<b-form-group label="Отв-ый" label-for="deal-responsible-input">
								<b-form-select
										id="deal-responsible-input"
										v-model="deal.responsible_id"
										:options="responsibleOptions"
										:disabled="!editable.responsible_id"
								></b-form-select>
							</b-form-group>

							<b-form-group label="Платформа" label-for="deal-platform-input">
								<b-form-select
										id="deal-platform-input"
										v-model="deal.platform"
										:options="platformOptions"
										:disabled="!editable.platform"
								></b-form-select>
							</b-form-group>

							<b-form-group
									label="Паблики"
									label-for="deal-public-input-0"
							>
								<b-container
										v-for="(v, i) in $v.publics.$each.$iter"
										:id="'deal-public-input-'+i.toString()"
										:key="i"
										class="public-border">
									<div v-if="publics[i].id">
										{{ `Паблик #${publics[i].id}` }}
									</div>
									<b-row>
										<b-col>
											<b-form-group label="Название паблика" :label-for="`deal-public-${i.toString()}-name-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-name-input`"
														v-model.trim="v.name.$model"
														placeholder="Введите название паблика"
														:disabled="!editablePublic[i].name"
														:state="validatePublicState(v.name)"
														:aria-describedby="`deal-public-${i.toString()}-name-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-name-input-feedback`"
												>Введите название паблика
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Ссылка на паблик" :label-for="`deal-public-${i.toString()}-link-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-link-input`"
														v-model.trim="v.link.$model"
														placeholder="https://"
														:disabled="!editablePublic[i].link"
														:state="validatePublicState(v.link)"
														:aria-describedby="`deal-public-${i.toString()}-link-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-link-input-feedback`"
												>Введите ссылку на паблик
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Ссылка на ВОТ" :label-for="`deal-public-${i.toString()}-link_bot-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-link_bot-input`"
														v-model.trim="v.link_bot.$model"
														:disabled="!editablePublic[i].link_bot"
														placeholder="https://"
														:state="validatePublicState(v.link_bot)"
														:aria-describedby="`deal-public-${i.toString()}-link_bot-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-link_bot-input-feedback`"
												>Поле должно быть ссылкой
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Результат" :label-for="`deal-public-${i.toString()}-result-input`">
												<b-form-select
														:id="`deal-public-${i.toString()}-result-input`"
														v-model="publics[i].result"
														:options="publicResultOptions"
														:disabled="!editablePublic[i].result"
												></b-form-select>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group label="Дата публикации"
																		:label-for="`deal-public-${i.toString()}-publish_date-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-publish_date-input`"
														v-model.trim="v.publish_date.$model"
														:disabled="!editablePublic[i].publish_date"
														placeholder="31.12.2023"
														:state="validatePublicState(v.publish_date)"
														:aria-describedby="`deal-public-${i.toString()}-publish_date-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-publish_date-input-feedback`"
												>Поле должно быть в формате день.месяц.год
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Время жизни поста"
																		:label-for="`deal-public-${i.toString()}-time_alive-input`">
												<b-form-select
														:id="`deal-public-${i.toString()}-time_alive-input`"
														v-model.trim="v.time_alive.$model"
														:options="timeAliveOptions"
														:disabled="!editablePublic[i].time_alive"
														:state="validatePublicState(v.time_alive)"
														:aria-describedby="`deal-public-${i.toString()}-time_alive-input-feedback`"
												></b-form-select>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-time_alive-input-feedback`"
												>Поле должно быть в формате часы:минуты
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Стоимость поста" :label-for="`deal-public-${i.toString()}-post_price-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-post_price-input`"
														v-model.trim="v.post_price.$model"
														:disabled="!editablePublic[i].post_price"
														placeholder="1.99"
														:state="validatePublicState(v.post_price)"
														:aria-describedby="`deal-public-${i.toString()}-post_price-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-post_price-input-feedback`"
												>Поле должно быть в формате рубли.копейки
												</b-form-invalid-feedback>
											</b-form-group>

										</b-col>
										<b-col>
											<b-form-group label="Время публикации"
																		:label-for="`deal-public-${i.toString()}-publish_time-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-publish_time-input`"
														v-model.trim="v.publish_time.$model"
														:disabled="!editablePublic[i].publish_time"
														placeholder="23:59"
														:state="validatePublicState(v.publish_time)"
														:aria-describedby="`deal-public-${i.toString()}-publish_time-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-publish_time-input-feedback`"
												>Поле должно быть в формате часы:минуты
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="ТЗ План"
																		:label-for="`deal-public-${i.toString()}-task_plan-input`">
												<b-form-input
														:id="`deal-public-${i.toString()}-task_plan-input`"
														v-model.trim="v.task_plan.$model"
														:disabled="!editablePublic[i].task_plan"
														placeholder="https://"
														:state="validatePublicState(v.task_plan)"
														:aria-describedby="`deal-public-${i.toString()}-task_plan-input-feedback`"
												></b-form-input>
												<b-form-invalid-feedback
														:id="`deal-public-${i.toString()}-task_plan-input-feedback`"
												>Поле должно быть ссылкой
												</b-form-invalid-feedback>
											</b-form-group>
											<b-form-group label="Удалить паблик" label-for="deal-payment_invoice-input"
																		v-if="i > 0">
												<b-button
														:style="{'margin-top': publics.length > 0 ? '6px' : ''}"
														@click="rmPublic(i)"
														:disabled="!editable.public_rm"
												>-
												</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</b-container>
								<b-button
										:style="{'margin-top': publics.length > 0 ? '6px' : ''}"
										@click="addPublic"
										:disabled="!editable.public_add || publics.length > 49"
								>+
								</b-button>
							</b-form-group>

							<b-form-group label="Тип поста" label-for="deal-post_type-input">
								<b-form-select
										id="deal-post_type-input"
										v-model="deal.post_type"
										:options="postTypeOptions"
										:disabled="!editable.post_type"
								></b-form-select>
							</b-form-group>

							<b-form-group label="Счёт на оплату (инвойс)" label-for="deal-payment_invoice-input">
								<b-form-file
										v-if="editable.payment_invoice"
										id="deal-payment_invoice-input"
										v-model="deal.payment_invoice"
										:disabled="!editable.payment_invoice"
										accept=".jpg, .png, .jpeg"
										plain
								></b-form-file>
								<b-button
										v-if="deal_old.payment_invoice !== null"
										id="deal-payment_invoice-input"
										@click="getFile(deal_old.payment_invoice)"
								>Скачать
								</b-button>
							</b-form-group>

							<b-form-group label="Тип оплаты" label-for="deal-payment_type-input">
								<b-form-select
										id="deal-payment_type-input"
										v-model="deal.payment_type"
										:options="paymentTypeOptions"
										:disabled="!editable.payment_type"
								></b-form-select>
							</b-form-group>

							<b-form-group label="Валюта" label-for="deal-currency-input">
								<b-form-select
										id="deal-currency-input"
										v-model="deal.currency"
										:options="currencyOptions"
										:disabled="!editable.currency"
								></b-form-select>
							</b-form-group>

							<b-form-group label="Сумма инвойса" label-for="deal-invoice_sum-input">
								<b-form-input
										id="deal-invoice_sum-input"
										v-model="$v.deal.invoice_sum.$model"
										:disabled="!editable.invoice_sum"
										placeholder="1.99"
										:state="validateState('invoice_sum')"
										aria-describedby="deal-invoice_sum-input-feedback"
								></b-form-input>
								<b-form-invalid-feedback
										id="deal-invoice_sum-input-feedback"
								>Поле должно быть в формате рубли.копейки
								</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Чек оплаты" label-for="deal-payment_receipt-download-input">
								<b-button
										v-if="deal_old.payment_receipt !== null"
										id="deal-payment_receipt-download-input"
										@click="getFile(deal_old.payment_receipt)"
								>Скачать
								</b-button>
								<div v-else>
									Нет чека
								</div>
							</b-form-group>

							<b-form-group label="Реквизиты" label-for="deal-requisites-input">
								<b-form-textarea
										id="deal-requisites-input"
										v-model="deal.requisites"
										:disabled="!editable.requisites"
										placeholder="Введите реквизиты"
										rows="3"
								></b-form-textarea>
							</b-form-group>
						</b-tab>

						<b-tab title="Оплата рекламы" v-if="secondTabAllowed">
							<b-form-group label="Реквизиты" label-for="deal-requisites-text-input">
								<b-form-textarea
										id="deal-requisites-text-input"
										:value="deal.requisites"
										disabled
										rows="3"
								></b-form-textarea>
							</b-form-group>

							<b-form-group label="Курс USDT на момент перевода" label-for="deal-exchange_rate_usdt-input">
								<b-form-input
										id="deal-exchange_rate_usdt-input"
										v-model="$v.deal.exchange_rate_usdt.$model"
										:disabled="!editable.exchange_rate_usdt"
										placeholder="1.99"
										:state="validateState('exchange_rate_usdt')"
										aria-describedby="deal-exchange_rate_usdt-input-feedback"
								></b-form-input>
								<b-form-invalid-feedback
										id="deal-exchange_rate_usdt-input-feedback"
								>Поле должно быть в формате рубли.копейки
								</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Комиссия платежа USDT" label-for="deal-commission_usdt-input">
								<b-form-input
										id="deal-commission_usdt-input"
										v-model="$v.deal.commission_usdt.$model"
										:disabled="!editable.commission_usdt"
										placeholder="1.99"
										:state="validateState('commission_usdt')"
										aria-describedby="deal-commission_usdt-input-feedback"
								></b-form-input>
								<b-form-invalid-feedback
										id="deal-commission_usdt-input-feedback"
								>Поле должно быть в формате рубли.копейки
								</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Сумма перевода в USDT" label-for="deal-transfer_amount_usdt-input">
								<b-form-input
										id="deal-transfer_amount_usdt-input"
										v-model="$v.deal.transfer_amount_usdt.$model"
										:disabled="!editable.transfer_amount_usdt"
										placeholder="1.99"
										:state="validateState('transfer_amount_usdt')"
										aria-describedby="deal-transfer_amount_usdt-input-feedback"
								></b-form-input>
								<b-form-invalid-feedback
										id="deal-transfer_amount_usdt-input-feedback"
								>Поле должно быть в формате рубли.копейки
								</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group label="Оплаченная сумма USDT" label-for="deal-paid_amount_usdt-input">
								<b-form-input
										id="deal-paid_amount_usdt-input"
										:value="Math.round((Number(deal.commission_usdt) + Number(deal.transfer_amount_usdt)) * 100) / 100"
										disabled
								></b-form-input>
							</b-form-group>

							<b-form-group label="Чек оплаты" label-for="deal-payment_receipt-input">
								<b-form-file
										v-if="editable.payment_receipt"
										id="deal-payment_receipt-input"
										v-model="deal.payment_receipt"
										:disabled="!editable.payment_receipt"
										accept=".jpg, .png, .jpeg"
										plain
								></b-form-file>
								<b-button
										v-if="deal_old.payment_receipt !== null"
										id="deal-payment_receipt-input"
										@click="getFile(deal_old.payment_receipt)"
								>Скачать
								</b-button>
							</b-form-group>

							<b-form-group label="Статус" label-for="deal-payment_status-input">
								<b-form-select
										id="deal-payment_status-input"
										v-model="deal.payment_status"
										:options="paymentStatusOptions"
										:disabled="!editable.payment_status"
								></b-form-select>
							</b-form-group>

							<b-form-group label="Возврат" label-for="deal-payment_refund-input">
								<b-form-input
										id="deal-payment_refund-input"
										v-model="$v.deal.payment_refund.$model"
										:disabled="!editable.payment_refund"
										placeholder="1.99"
										:state="validateState('payment_refund')"
										aria-describedby="deal-payment_refund-input-feedback"
								></b-form-input>
								<b-form-invalid-feedback
										id="deal-payment_refund-input-feedback"
								>Поле должно быть в формате рубли.копейки
								</b-form-invalid-feedback>
							</b-form-group>

							<b-form-group v-for="(v, i) in $v.publics.$each.$iter"
														:id="'deal-public-returned-input-'+i.toString()"
														:key="i"
														:label-for="`deal-public-${i.toString()}-returned-input`">
								<b-form-checkbox
										:id="`deal-public-${i.toString()}-returned-input`"
										v-model="publics[i].returned"
										:disabled="!editablePublic[i].returned"
										:value="true"
										:unchecked-value="false"
								>
									{{ `${publics[i].name}, ${publics[i].publish_date}` }}
								</b-form-checkbox>
							</b-form-group>
						</b-tab>
					</b-tabs>
				</div>
			</div>
		</div>
		<div class="col-sm-7">
			<div class="element-wrapper">
				<div class="element-box">
					<h5 class="element-header">
						Примечания
					</h5>

					<div class="hide-scrollbar" style="max-height: 61.3vh; min-height: 61.3vh">
						<div v-for="note of notes" :key="note.id">
							<div>{{ formatNoteOutput(note) }}</div>
						</div>
						<!--						<div class="timed-activities compact">-->
						<!--							<div class="timed-activity">-->
						<!--								<div v-for="note of notes" :key="note.id" :class="note.type === 'create' ? 'ta-date' : 'ta-record-w'">-->
						<!--															<span v-if="note.type === 'create'">{{ getDate(note.created_at) }} {{-->
						<!--																	getTime(note.created_at)-->
						<!--																}} {{ note.created_by_name }} Создал сделку: {{ note.text }}</span>-->
						<!--									<div v-else-if="note.type === 'text'" class="ta-record">-->
						<!--										<div class="ta-timestamp">-->
						<!--											<strong>-->
						<!--												{{ getDate(note.created_at) }}-->
						<!--												{{ getTime(note.created_at) }}-->
						<!--											</strong>-->
						<!--										</div>-->
						<!--										<div class="ta-activity">-->
						<!--											{{ note.created_by_name }}: {{ note.text }}-->
						<!--										</div>-->
						<!--									</div>-->
						<!--								</div>-->
						<!--							</div>-->
						<!--						</div>-->
					</div>

				</div>
				<div class="element-box" style="margin-top: 1rem">
					<b-form-group label="Примечание" label-for="deal-note-input">
						<b-input-group>
							<b-form-textarea
									id="deal-note-input"
									class="hide-scrollbar"
									v-model="note.text"
									placeholder="Введите текст примечания"
									rows="3"
									max-rows="3"
							></b-form-textarea>
							<b-input-group-append>
								<b-button variant="primary" @click="submitNote" :disabled="note.text === null || note.text === ''">
									->
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="">
						<b-button class="btn btn-primary" @click="submit" type="button" variant="primary" name="button">Отправить
						</b-button>
						<b-button class="btn btn-grey" @click="backToDealsTable">Отмена</b-button>
						<b-button class="btn btn-secondary" v-b-modal="'support-task'">Написать в поддержку</b-button>
						<b-modal
								id="support-task"
								title="Написать в поддержку"
								centered
								hide-footer
						>
							<b-form>
								<b-form-group label="Какое поле надо поменять?" label-for="support-field-selector">
									<b-form-select
											id="support-field-selector"
											:options="allFields"
											v-model="support.field"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Текст обращения" label-for="support-message">
									<b-form-textarea
											id="support-message"
											placeholder="Расскажите, что случилось?"
											v-model="support.text"
											rows="4"
									></b-form-textarea>
								</b-form-group>

								<div class="buttons-reverse">
									<button type="button" class="btn btn-primary" @click="submitSupport">Отправить</button>
									<button type="button" class="btn btn-secondary" @click="$bvModal.hide('support-task')">Отмена
									</button>
								</div>
							</b-form>
						</b-modal>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import {GET_DEAL, UPDATE_DEAL, UPDATE_DEAL_FILE} from '@/api/adv-cab/deals';
import {GET_TASK, FINISH_TASK} from '@/api/adv-cab/tasks';
import {GET_NOTES, SEND_NOTE, SEND_SUPPORT} from '@/api/adv-cab/notes';
import {GET_STATUSES, GET_MANAGERS, GET_ALL_STATUSES} from '@/api/adv-cab/common';
import {GET_FILES} from '@/api/adv-cab/files';
import {validationMixin} from 'vuelidate';
import {integer, maxLength, minLength, minValue, required, requiredIf} from 'vuelidate/lib/validators';
import {GET_PUBLIC} from '@/api/adv-cab/public';

const urlRegexp = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value);
};

const urlOrNilRegexp = value => {
	if (typeof value === 'undefined' || value === null || value === '' || value === '-') {
		return true;
	}
	return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value);
};

const decimalRegexp = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	const num = value.toString().split('.');
	if (num.length !== 2) {
		return false;
	}
	if (isNaN(num[0]) || isNaN(num[1])) {
		return false;
	}
	if (num[1].length !== 5) {
		return false;
	}
	if (Number(num[0] < 0) || Number(num[1]) < 0) {
		return false;
	}
	return /[0-9]+.[0-9]{5}/g.test(value);
};

const dateRegexp = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	const date = value.toString().split('.');
	if (date.length !== 3) {
		return false;
	}
	if (isNaN(date[0]) || isNaN(date[1]) || isNaN(date[2])) {
		return false;
	}
	if (Number(date[0]) > 31 || Number(date[0]) <= 0) {
		return false;
	}
	if (Number(date[1]) > 12 || Number(date[1]) <= 0) {
		return false;
	}
	if (Number(date[2]) > 2030 || Number(date[2]) <= 2022) {
		return false;
	}
	return /[0-9]{2}.[0-9]{2}.[0-9]{4}/g.test(value);
};

const timeRegexp = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	const time = value.toString().split(':');
	if (time.length !== 2) {
		return false;
	}
	if (isNaN(time[0]) || isNaN(time[1])) {
		return false;
	}
	if (Number(time[0]) > 23 || Number(time[0]) < 0) {
		return false;
	}
	if (Number(time[1]) > 59 || Number(time[1]) < 0) {
		return false;
	}
	return /[0-9]{2}:[0-9]{2}/g.test(value);
};

export default {
	name: 'AdvDealsForm',
	mixins: [validationMixin],
	validations: {
		deal: {
			invoice_sum: {
				decimalRegexp
			},
			exchange_rate_usdt: {
				decimalRegexp
			},
			commission_usdt: {
				decimalRegexp
			},
			transfer_amount_usdt: {
				decimalRegexp
			},
			paid_amount_usdt: {
				decimalRegexp
			},
			payment_refund: {
				decimalRegexp
			},
		},
		publics: {
			$each: {
				publish_date: {
					required,
					dateRegexp,
				},
				publish_time: {
					required,
					timeRegexp,
				},
				name: {
					required,
				},
				link: {
					required,
					urlRegexp,
				},
				link_bot: {
					required,
					urlOrNilRegexp,
				},
				task_plan: {
					required,
					urlRegexp,
				},
				time_alive: {
					required,
				},
				post_price: {
					required,
					decimalRegexp,
				},
			}
		},
	},
	data() {
		return {
			deal: {
				status_id: null,
				name: null,
				responsible_id: null,
				platform: null,
				post_type: null,
				publish_date: '',
				publish_time: null,
				time_alive: null,
				payment_invoice: null,
				payment_type: null,
				currency: null,
				invoice_sum: null,
				exchange_rate_usdt: null,
				commission_usdt: null,
				transfer_amount_usdt: null,
				paid_amount_usdt: null,
				payment_receipt: null,
				payment_status: null,
				payment_refund: null,
			},
			deal_old: {},
			editable: {
				status_id: false,
				responsible_id: false,
				platform: false,
				post_type: false,
				payment_invoice: false,
				payment_type: false,
				currency: false,
				invoice_sum: false,
				requisites: false,
				exchange_rate_usdt: false,
				commission_usdt: false,
				transfer_amount_usdt: false,
				paid_amount_usdt: false,
				payment_receipt: false,
				payment_status: false,
				payment_refund: false,
				public_add: false,
				public_rm: false,
			},
			task: {
				id: 0,
				text: 'Нет задач',
				hasTask: false,
			},
			support: {
				deal_id: this.$route.params.deal_id,
				field: null,
				text: '',
			},
			notes: [],
			note: {
				deal_id: this.$route.params.deal_id,
				text: null,
			},
			publics: [],
			publics_old: [],

			allAvailableStatuses: [],
			allStatuses: [],
			allManagers: [],
			allFields: [
				{value: null, text: 'Выберите поле'},
				{value: 'status_id', text: 'Статус сделки'},
				{value: 'name', text: 'Название'},
				{value: 'responsible_id', text: 'Отв-ый'},
				{value: 'platform', text: 'Платформа'},
				{value: 'public', text: 'Паблик'},
				{value: 'link_bot', text: 'Ссылка на ВОТ'},
				{value: 'post_type', text: 'Тип поста'},
				{value: 'task_plan', text: 'ТЗ план'},
				{value: 'payment_invoice', text: 'Счёт на оплату (инвойс)'},
				{value: 'payment_type', text: 'Тип оплаты'},
				{value: 'currency', text: 'Валюта'},
				{value: 'invoice_sum', text: 'Сумма инвойса'},
				{value: 'exchange_rate_usdt', text: 'Курс USDT на момент перевода'},
				{value: 'commission_usdt', text: 'Комиссия платежа USDT'},
				{value: 'transfer_amount_usdt', text: 'Сумма перевода в USDT'},
				{value: 'paid_amount_usdt', text: 'Оплаченная сумма USDT'},
				{value: 'payment_receipt', text: 'Чек оплаты'},
				{value: 'payment_status', text: 'Статус'},
				{value: 'payment_refund', text: 'Возврат'},
				{value: 'requisites', text: 'Реквизиты'},
			],
			platformOptions: [
				{value: null, text: 'Выберите платформу', disabled: true},
				{value: 'tg', text: 'Telegram'},
				{value: 'vk', text: 'VK'},
				{value: 'yt', text: 'YouTube'},
				{value: 'twitch', text: 'Twitch'}
			],
			postTypeOptions: [
				{value: null, text: 'Выберите тип поста', disabled: true},
				{value: 'postponed', text: 'Отложенный'},
				{value: 'urgent', text: 'Срочный'},
			],
			timeAliveOptions: [
				{value: null, text: 'Выберите время жизни', disabled: true},
				{value: 24, text: '24 часа'},
				{value: 48, text: '48 часов'},
				{value: 72, text: '72 часа'},
				{value: 720, text: '1 месяц'},
				{value: 1440, text: '2 месяц'}
			],
			paymentTypeOptions: [
				{value: null, text: 'Выберите тип оплаты', disabled: true},
				{value: 'card_crypto', text: 'Карта (крипта)'},
				{value: 'crypto', text: 'Крипта'},
			],
			currencyOptions: [
				{value: null, text: 'Выберите валюту', disabled: true},
				{value: 'rub_usdt', text: 'Рубль (USDT)'},
			],
			paymentStatusOptions: [
				{value: null, text: 'Выберите статус', disabled: true},
				{value: 'paid', text: 'Оплачено'},
				{value: 'not_paid', text: 'Не оплачено'},
			],
			publicResultOptions: [
				{value: null, text: 'Выберите результат', disabled: true},
				{value: 'paid', text: 'Оплачен'},
				{value: 'clarifying', text: 'На уточнении'},
				{value: 'sold', text: 'Реализовано'},
				{value: 'thrown', text: 'Утиль'}
			],
		};
	},
	computed: {
		...mapGetters([
			'advCabinetRole',
		]),
		secondTabAllowed() {
			return ['admin', 'observer', 'accountant'].includes(this.advCabinetRole);
		},
		statusEditable() {
			const farmerAutoSwitch = ['farmer'].includes(this.advCabinetRole) && this.deal_old.status_id === 1;
			return !farmerAutoSwitch && this.editable.status_id;
		},
		statusOptions() {
			const statuses = [];
			if (this.deal.status_id === null) {
				return [{value: null, text: 'Ошибка'}];
			}
			const currentStatus = this.allAvailableStatuses.find((s) => s.id === this.deal.status_id);
			if (currentStatus === undefined) {
				return [{value: null, text: 'Ошибка'}];
			}
			statuses.push({
				value: currentStatus.id,
				text: currentStatus.name,
				next_available: currentStatus.next_available,
				disabled: true
			});
			for (const i of currentStatus.next_available) {
				const status = this.allAvailableStatuses.find((s) => s.id === i);
				if (status) {
					statuses.push({
						value: status.id,
						text: status.name,
						next_available: status.next_available,
						disabled: false
					});
				}
			}

			return statuses;
		},
		responsibleOptions() {
			const resps = [];

			for (const resp of this.allManagers) {
				resps.push({
					value: resp.id,
					text: `${resp.name} / ${this.formatRoleOutput(resp.role)}`
				});
			}

			return resps.sort((a, b) => a.text.localeCompare(b.text));
		},
		editablePublic() {
			const editable = [];
			for (const p of this.publics) {
				let v = {
					name: true,
					link: true,
					publish_date: true,
					publish_time: true,
					link_bot: true,
					task_plan: true,
					time_alive: true,
					post_price: true,
					result: ['admin'].includes(this.advCabinetRole),
					returned: true,
				};
				if (!p.id) {
					editable.push(v);
					continue;
				}

				const old_p = this.publics_old.find((v) => v.id === p.id);
				if (!old_p) {
					editable.push(v);
					continue;
				}

				v.name = (
					(['farmer'].includes(this.advCabinetRole) && old_p.name === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.link = (
					(['farmer'].includes(this.advCabinetRole) && old_p.link === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.publish_date = (
					(['farmer'].includes(this.advCabinetRole) && old_p.publish_date === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.publish_time = (
					(['farmer'].includes(this.advCabinetRole) && old_p.publish_time === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.link_bot = (
					(['farmer'].includes(this.advCabinetRole) && old_p.link_bot === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.task_plan = (
					(['farmer'].includes(this.advCabinetRole) && old_p.task_plan === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.time_alive = (
					(['farmer'].includes(this.advCabinetRole) && old_p.time_alive === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.post_price = (
					(['farmer'].includes(this.advCabinetRole) && old_p.post_price === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				v.returned = (
					(['farmer'].includes(this.advCabinetRole) && old_p.returned === null) ||
						['admin'].includes(this.advCabinetRole)
				);
				editable.push(v);
			}
			return editable;
		},
	},
	watch: {
		'deal.status_id'(newValue) {
			console.log([this.deal_old.status_id, newValue]);
			if (this.deal_old.status_id !== newValue) {
				this.editable.status_id = false;
				if (this.deal_old.status_id === 4 && newValue === 3) {
					if (this.advCabinetRole === 'observer') {
						this.editable.publish_date = true;
						this.editable.publish_time = true;
					}
				}
			}
		}
	},
	methods: {
		...mapActions([
			'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateState(name) {
			const {$dirty, $error} = this.$v.deal[name];
			return $dirty ? !$error : null;
		},

		validatePublicState(v) {
			const {$dirty, $error} = v;
			return $dirty ? !$error : null;
		},

		formatTime(time) {
			const dateTime = time.split('T');
			const d = dateTime[0].split('-');
			const t = dateTime[1].split('Z');
			return `${d[2]}.${d[1]}.${d[0]} ${t[0]}`;
		},

		getDate(time) {
			const dateTime = time.split('T');
			const d = dateTime[0].split('-');
			return `${d[2]}.${d[1]}.${d[0]}`;
		},

		getTime(time) {
			return time.split('T')[1].split('Z')[0];
		},

		dateFormatter(value) {

		},

		formatNoteFieldOutput(field) {
			if (!field.field) {
				return {
					value_before: 'Ошибка форматирования',
					value_after: 'Ошибка форматирования',
				};
			}
			if (['invoice_sum', 'exchange_rate_usdt', 'commission_usdt', 'transfer_amount_usdt', 'paid_amount_usdt', 'payment_refund'].includes(field.field)) {
				return {
					value_before: this.parseNumber(field.value_before),
					value_after: this.parseNumber(field.value_after),
				};
			}
			if (['platform', 'post_type', 'time_alive', 'payment_type', 'currency', 'payment_status'].includes(field.field)) {
				let value_before = null;
				let value_after = null;
				switch (field.field) {
				case 'platform':
					if (field.value_before) {
						value_before = this.platformOptions.find((s) => s.value === field.value_before).text;
					}
					if (field.value_after) {
						value_after = this.platformOptions.find((s) => s.value === field.value_after).text;
					}
					break;
				case 'post_type':
					if (field.value_before) {
						value_before = this.postTypeOptions.find((s) => s.value === field.value_before).text;
					}
					if (field.value_after) {
						value_after = this.postTypeOptions.find((s) => s.value === field.value_after).text;
					}
					break;
				case 'time_alive':
					if (field.value_before) {
						value_before = this.timeAliveOptions.find((s) => s.value === Number(field.value_before)).text;
					}
					if (field.value_after) {
						value_after = this.timeAliveOptions.find((s) => s.value === Number(field.value_after)).text;
					}
					break;
				case 'payment_type':
					if (field.value_before) {
						value_before = this.paymentTypeOptions.find((s) => s.value === field.value_before).text;
					}
					if (field.value_after) {
						value_after = this.paymentTypeOptions.find((s) => s.value === field.value_after).text;
					}
					break;
				case 'currency':
					if (field.value_before) {
						value_before = this.currencyOptions.find((s) => s.value === field.value_before).text;
					}
					if (field.value_after) {
						value_after = this.currencyOptions.find((s) => s.value === field.value_after).text;
					}
					break;
				case 'payment_status':
					if (field.value_before) {
						value_before = this.paymentStatusOptions.find((s) => s.value === field.value_before).text;
					}
					if (field.value_after) {
						value_after = this.paymentStatusOptions.find((s) => s.value === field.value_after).text;
					}
					break;
				}
				return {
					value_before,
					value_after,
				};
			}
			return {
				value_before: field.value_before,
				value_after: field.value_after
			};
		},

		formatNoteStatusOutput(status) {
			if (status === null || status === 0 || status === undefined) {
				return null;
			}
			return {
				status_before: this.allStatuses.find((s) => s.id === Number(status.value_before)).name,
				status_after: this.allStatuses.find((s) => s.id === Number(status.value_after)).name
			};
		},

		formatNoteOutput(note) {
			let s = `${this.getDate(note.created_at)} ${this.getTime(note.created_at)}`;
			const noteCreator = this.allManagers.find((v) => v.id === note.created_by_id);
			if (noteCreator) {
				s = s + ` ${note.created_by_name} / ${this.formatRoleOutput(noteCreator.role)}`;
			} else {
				if (note.created_by_id !== 'robot') {
					s = s + ` ${note.created_by_name} / Не найден`;
				} else {
					s = s + ` ${note.created_by_name}`;
				}
			}
			let field;
			if (note.field) {
				field = this.allFields.find((f) => f.value === note.field);
				if (field === undefined) {
					return 'Ошибка форматирования';
				}
			}

			switch (note.type) {
			case 1:
				return s + ` Создал сделку: ${note.text}`;
			case 2:
				return s + ` Оставил примечание: ${note.text}`;
			case 3:
				if (note.value_before === null) {
					return s + ` Загрузил файл в поле «${field.text}»`;
				}
				return s + ` Обновил файл в поле «${field.text}»`;
			case 4:
				// eslint-disable-next-line no-case-declarations
				const {value_before, value_after} = this.formatNoteFieldOutput(note);
				if (note.value_before === null) {
					return s + ` Для поля «${field.text}» установлено значение «${value_after}»`;
				}
				return s + ` Изменено поле «${field.text}». Предыдущее значение «${value_before}», новое значение «${value_after}»`;
			case 5:
				// eslint-disable-next-line no-case-declarations
				const {status_before, status_after} = this.formatNoteStatusOutput(note);
				return s + ` Новый этап: «${status_after}» из «${status_before}»`;
			case 6:
				return s + ` Изменено название сделки. Предыдущее название «${note.value_before}», новое название «${note.value_after}»`;
			case 7:
				return s + ` Поставил задачу на смену значения поля «${field.text}». Текст задачи: ${note.text}`;
			case 8:
				// eslint-disable-next-line no-case-declarations
				const manager = this.allManagers.find((v) => v.id === note.value_before);
				return s + ` Завёл задачу ${note.text} на ${manager.name} / ${this.formatRoleOutput(manager.role)}`;
			case 9:
				return s + ` Закрыл задачу ${note.text}`;
			case 10:
				// eslint-disable-next-line no-case-declarations
				const manager_before = this.allManagers.find((v) => v.id === note.value_before);
				// eslint-disable-next-line no-case-declarations
				const manager_after = this.allManagers.find((v) => v.id === note.value_after);
				return s + ` Сменил ответственного ${manager_before.name}  / ${this.formatRoleOutput(manager_before.role)} на ${manager_after.name}  / ${this.formatRoleOutput(manager_after.role)}`;
			}
			return 'Ошибка форматирования';
		},

		formatRoleOutput(role) {
			switch (role) {
			case 'farmer':
				return 'Фермер';
			case 'admin':
				return 'Админ';
			case 'observer':
				return 'Наблюдатель';
			case 'accountant':
				return 'Бухгалтер';
			}
		},

		backToDealsTable() {
			this.$router.push({name: routeNames.adv_cab.deals.read, params: {contact_id: this.$route.params.contact_id}});
		},

		addPublic() {
			const emptyPublic = {
				name: null,
				link: null,
				publish_date: null,
				publish_time: null,
				link_bot: null,
				task_plan: null,
				time_alive: null,
				post_price: null,
				result: null,
				returned: false,
			};
			if (this.publics.length === 0) {
				this.publics.push(emptyPublic);
				return;
			}
			let add = true;
			for (const p of this.publics) {
				for (const key in p) {
					if (key === 'result') {
						continue;
					}
					if (p[key] === null || p[key] === '') {
						add = false;
					}
				}
			}
			if (add) {
				this.publics.push(emptyPublic);
			} else {
				this.$v.publics.$touch();
			}
		},

		rmPublic(i) {
			if (!this.publics[i]) {
				return;
			}
			this.publics.splice(i, 1);
		},

		async getFile(file_id) {
			const resp = await GET_FILES(this.$route.params.deal_id, file_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении файла'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении файла'
				});
			}
			if (resp.file !== null) {
				window.open(resp.file);
			}
		},

		async getAvailableStatuses() {
			const resp = await GET_STATUSES().catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка доступных статусов'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка доступных статусов'
				});
				return;
			}
			this.allAvailableStatuses = resp.statuses;
		},

		async getAllStatuses() {
			const resp = await GET_ALL_STATUSES().catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка всех статусов'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка всех статусов'
				});
				return;
			}
			this.allStatuses = resp.statuses;
		},

		async getNotes() {
			const resp = await GET_NOTES(this.$route.params.deal_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка примечаний'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка примечаний'
				});
				return;
			}
			this.notes = resp.notes;
		},

		async getPublic() {
			const resp = await GET_PUBLIC(this.$route.params.deal_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пабликов'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пабликов'
				});
				return;
			}
			for (let i in resp.public) {
				for (let key in resp.public[i]) {
					if (['post_price'].includes(key)) {
						resp.public[i][key] = this.parseNumber(resp.public[i][key]);
					}
				}
			}

			const jP = JSON.stringify(resp.public);
			this.publics = JSON.parse(jP);
			this.publics_old = JSON.parse(jP);
		},

		async getManagers() {
			const resp = await GET_MANAGERS().catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка менеджеров'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка менеджеров'
				});
				return;
			}
			this.allManagers = resp.managers;
		},

		parseNumber(number) {
			if (number === null || number === '' || number === undefined) {
				return null;
			}
			const num = number.toString();
			if (num.length === 5) {
				return `0.${num}`;
			}
			if (num.length === 4) {
				return `0.0${num}`;
			}
			if (num.length === 3) {
				return `0.00${num}`;
			}
			if (num.length === 2) {
				return `0.000${num}`;
			}
			if (num.length === 1) {
				return `0.0000${num}`;
			}
			return `${num.substring(0, num.length - 5)}.${num.substring(num.length - 5, num.length)}`;
		},

		async getDeal() {
			const resp = await GET_DEAL({deal_id: this.$route.params.deal_id}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении сделки'
				});
			});
			if (resp.error) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении сделки'
				});
				return;
			}
			this.deal = resp.deal;

			for (let key in this.deal) {
				if (['invoice_sum', 'exchange_rate_usdt', 'commission_usdt', 'transfer_amount_usdt', 'paid_amount_usdt', 'payment_refund'].includes(key)) {
					this.deal[key] = this.parseNumber(this.deal[key]);
				}
			}

			this.deal_old = structuredClone(resp.deal);
			this.deal.payment_receipt = null;
			this.deal.payment_invoice = null;
			this.refreshEditable();
		},

		refreshEditable() {
			this.editable.status_id = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.status_id !== 1) ||
					['admin', 'observer', 'accountant'].includes(this.advCabinetRole)
			);
			this.editable.responsible_id = ['admin'].includes(this.advCabinetRole);
			this.editable.platform = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.platform === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.post_type = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.post_type === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.payment_invoice = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.payment_invoice === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.payment_type = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.payment_type === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.currency = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.currency === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.invoice_sum = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.invoice_sum === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.requisites = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.deal_old.requisites === null) ||
					'admin' === this.advCabinetRole
			);

			this.editable.exchange_rate_usdt = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.exchange_rate_usdt === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.commission_usdt = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.commission_usdt === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.transfer_amount_usdt = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.transfer_amount_usdt === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.paid_amount_usdt = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.paid_amount_usdt === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.payment_receipt = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.payment_receipt === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.payment_status = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.payment_status === null) ||
					'admin' === this.advCabinetRole
			);
			this.editable.payment_refund = (
				(['accountant', 'observer'].includes(this.advCabinetRole) && this.deal_old.payment_refund === null) ||
					'admin' === this.advCabinetRole
			);

			this.editable.public_add = (
				(['farmer', 'observer'].includes(this.advCabinetRole) && this.publics_old.length === 0) ||
					'admin' === this.advCabinetRole
			);
			this.editable.public_rm = ['farmer', 'observer', 'admin'].includes(this.advCabinetRole);
		},

		async getTask() {
			const resp = await GET_TASK(this.$route.params.deal_id, 1).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении задачи'
				});
			});
			if (resp.error) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении задачи'
				});
				return;
			}
			if (resp.task) {
				this.task = resp.task;
				this.task.hasTask = true;
				return;
			}
			this.task.text = 'Нет задач';
			this.task.hasTask = false;
		},

		finishTask() {
			return FINISH_TASK(this.task.id).then((r) => {
				if (r.status === 200) {
					this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Задача завершена'});
					this.getTask();
					return;
				}
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при закрытии задачи'
				});
			}).catch((err) => {
				console.log(err);
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при закрытии задачи'
				});
			});
		},

		async submitNote() {
			await SEND_NOTE({deal_id: this.note.deal_id, text: this.note.text}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Примечание успешно добавлено'});
				this.note.text = '';
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при добавлении примечания'
				});
			});
		},

		async submitSupport() {
			await SEND_SUPPORT(this.support).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Примечание успешно добавлено'});
				this.note.text = '';
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при добавлении примечания'
				});
			});
			this.$bvModal.hide('support-task');
		},

		getCreatedPublic() {
			const createdPublic = [];
			for (const i in this.publics) {
				if (this.publics[i].id === undefined) {
					createdPublic.push(structuredClone(this.publics[i]));
				}
			}
			return createdPublic;
		},

		getRemovedPublic() {
			const oldIDs = this.publics_old.map((v) => {
				return v.id;
			});
			const newIDs = [];
			for (const p of this.publics) {
				if (!p.id) {
					continue;
				}
				newIDs.push(p.id);
			}
			const rmIDs = [];
			for (const oldID of oldIDs) {
				if (!newIDs.includes(oldID)) {
					rmIDs.push(oldID);
				}
			}
			return rmIDs;
		},

		getUpdatedPublic() {
			const updatedP = [];
			for (const p of this.publics) {
				if (!p.id) {
					continue;
				}
				const oldP = this.publics_old.find((v) => v.id === p.id);
				if (!oldP) {
					continue;
				}
				for (let key in p) {
					if (p[key] !== oldP[key]) {
						updatedP.push(structuredClone(p));
						break;
					}
				}
			}
			return updatedP;
		},

		async submit() {
			this.$v.deal.$touch();
			if (this.$v.deal.$anyError) {
				return;
			}
			if ((this.deal.payment_receipt !== null && this.deal.payment_receipt !== this.deal_old.payment_receipt) ||
					(this.deal.payment_invoice !== null && this.deal.payment_invoice !== this.deal_old.payment_invoice)) {
				await UPDATE_DEAL_FILE(this.$route.params.deal_id, this.deal).then(() => {
					this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Файлы успешно загружены'});
				}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при загрузке файлов'
					});
				});
			}

			const createdP = this.getCreatedPublic();
			const updatedP = this.getUpdatedPublic();
			const deletedP = this.getRemovedPublic();
			console.log(createdP);
			console.log(updatedP);
			console.log(deletedP);
			await UPDATE_DEAL(this.$route.params.deal_id, this.deal, createdP, updatedP, deletedP).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Сделка успешно обновлена'});
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении сделки'
				});
			});
			this.refreshEditable();
		}
	},
	async mounted() {
		await Promise.all([this.getAvailableStatuses(), this.getAllStatuses(), this.getManagers(), this.getDeal(), this.getTask(), this.getNotes(), this.getPublic()]);
		console.log(this.advCabinetRole);
	},
};
</script>

<style scoped>
#deal-tasks {
	background-color: white !important;
}

.buttons-reverse > button {
	float: right;
	margin-left: 10px;
}

.attach-end {
	align-content: flex-end;
	display: grid;
}

.tabs {
	overflow-y: scroll;
}

.tabs::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.public-border {
	border-bottom: 1px solid #047bf8;
	border-left: 1px solid #047bf8;
	border-right: 1px solid #047bf8;
}

body {
	overflow-y: hidden;
}
</style>
