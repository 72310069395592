import axios from 'axios';
import {GET_PASSPORT_URL} from '@/config';
import * as events from '@/store/passport/scopes/event-types';

export const actions = {
	async GET_SCOPES_FROM_API({commit}, {id, limit, offset}) {
		let filter = {
			id: id,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/scope', {
			params: filter
		}).then((resp) => {
			if (resp.data.scopes) {
				commit(events.PASSPORT_SET_SCOPES, resp.data.scopes);
				return resp.data.scopes;
			} else {
				commit(events.PASSPORT_SET_SCOPES, [resp.data.scope]);
				return resp.data.scope;
			}
		});
	},
	async DELETE_SCOPE({dispatch}, {id, filters}) {
		return axios.delete(GET_PASSPORT_URL() + '/api/v1/scope?id=' + id).then(() => {
			dispatch('GET_SCOPES_FROM_API', filters);
		});
	},

	async UPDATE_SCOPE({commit}, {scope}) {
		let rBody = {};
		rBody.scope = {
			id: scope.id,
			code: scope.code,

		};
		return axios.put(GET_PASSPORT_URL() + '/api/v1/scope', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_SCOPES, resp.data);
			return resp.data;
		});
	},

	async CREATE_SCOPE({commit}, scope) {
		let rBody = {};
		rBody.scope = scope;
		return axios.post(GET_PASSPORT_URL() + '/api/v1/scope', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_SCOPES, resp.data);
			return resp.data;
		});
	},
};