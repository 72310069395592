import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_CONTACTS(
	{
		name,
		phone,
		vk,
		tg,
		twitch,
		limit,
		created_at_from,
		created_at_to,
		pay_status,
		platform,
		public_name,
		date_post_at_from,
		date_post_at_to,
		offset,
	}
) {
	let filter = {
		name,
		phone,
		vk,
		tg,
		twitch,
		created_at_from,
		created_at_to,
		pay_status,
		platform,
		public_name,
		date_post_at_from,
		date_post_at_to,
	};
	for (let key in filter) {
		if (filter[key] === undefined || filter[key] === null || filter[key].length === 0) {
			delete filter[key];
		}
	}
	filter.limit = limit;
	filter.offset = offset;

	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/contacts?' + new URLSearchParams(filter).toString()
	).then((resp) => {
		return {counter: resp.data.counter, contacts: resp.data.contacts};
	}).catch((err) => {
		return {counter: 0, contacts: [], error: err};
	});
}

export function CREATE_CONTACT({name, phone, vk, tg, twitch}) {
	let contact = {
		name,
		phone,
		vk,
		tg,
		twitch,
	};
	for (let key in contact) {
		if (contact[key] === undefined || contact[key] === null || contact[key].length === 0) {
			delete contact[key];
		}
	}
	return axios.post(VUE_APP_ADV_CAB_URL + '/api/v1/contacts', contact).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, error: reason.response.data.error};
		}
		return {status: 500, error: null};
	});
}

