<template>
  <div class="element-box">
    <b-form @submit.stop.prevent="submit">
      <h5 class="form-header">
        Создание области видимости
      </h5>
      <div class="form-desc">
        Заполните необходимые данные и нажмите сохранить
      </div>
      <b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
      >
        <div>
          Код области видимости: {{ scope.code }}<br>
        </div>
      </b-modal>

      <b-form-group id="name-input-group" label="Код области видимости" label-for="scope-name-input">
        <b-form-input
            id="scope-name-input"
            v-model="scope.code"
            placeholder="Введите код области видимости"
        ></b-form-input>
      </b-form-group>

      <div class="alert alert-danger" v-if="isError">
        <p>Поле code обязательное!</p>
      </div>

      <div class="form-buttons-w">
        <b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button" @click="validateCode"
									v-b-modal="'confirmation-modal'">Сохранить
        </b-button>
        <b-button class="btn btn-grey" @click="backToScopeTable()">Отмена</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ScopeForm',
	data() {
		return {
			scope: {
				code: '',
			},
			isError: false,
			valid: false,
		};
	},
	watch: {
		'scope.code': function (val, oldVal) {
			this.checkFields();
		},
	},
	methods: {
		...mapActions([
			'CREATE_SCOPE', 'GET_SCOPES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateCode() {
			this.isError = this.scope.code.length === 0;
		},

		backToScopeTable() {
			this.$router.push({name: routeNames.passport.scopes.read});
		},

		checkFields() {
			this.isError = false;
			let codeValid = false;

			if (this.scope.code && this.scope.code.length > 0) {
				codeValid = true;
			}
			if (codeValid) {
				this.valid = true;
			} else {
				this.valid = false;
				this.validateCode();
			}
		},

		async submit() {
			await this.CREATE_SCOPE(this.scope).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Область видимости успешно создана' });
				this.backToScopeTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании области видимости' });
			});
		},
	},

	computed: {
		...mapGetters([
			'passportScopes'
		]),
	},
	async mounted() {
	},
};
</script>

<style>

</style>
