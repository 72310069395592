<template>
  <p>FinesTable</p>
</template>
<script>
export default {
  name: 'FinesTable',
  computed: {

  }
}
</script>