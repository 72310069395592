<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица кластеров
		</h5>
		<div class="form-desc" v-if="canUseHelpdeskClustersApi">
			Выберите нужный кластер и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpdesk.clusters.create }">Создать новый кластер</router-link>
		</div>
		<div class="table-responsive" style>
			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
							id="filters-modal"
							title="Фильтрация кластеров"
							ok-title="Применить"
							cancel-title="Отмена"
							v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="clusters-id-filter-group" label="ID" label-for="clusters-id-filter">
									<b-form-input
										id="clusters-id-filter"
										v-model="filters.id"
										placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>

								<b-form-group id="clusters-type-filter-group" label="Тип" label-for="clusters-type-filter">
									<b-form-select
										id="clusters-type-filter"
										v-model="filters.type"
										:options="typesEnum"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
								id="helpdesk-clusters-get-limit"
								name="helpdesk-clusters-get-limit"
								v-model="filters.limit"
								:options="limits"
								class="form-control form-control-sm rounded bright"
								@change="HELPDESK_GET_CLUSTERS_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>
			<table class="table table-lightborder clusters-table">
				<thead>
				<tr>
					<th>
						Название
						<i class="os-icon os-icon-arrow-up2"
							v-if="filters.sort_name === null || filters.sort_name !== 'desc'"
							@click.exact="filters.sort_name='desc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							v-if="filters.sort_name === null || filters.sort_name !== 'asc'"
							@click.exact="filters.sort_name='asc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
					</th>
					<th>
						Тип
					</th>
					<th>
						Города
					</th>
					<th>
						Пользователи
					</th>
					<th v-if="canUseHelpdeskClustersApi">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="cluster in helpdeskClusters" :key="cluster.id">
					<tr>
						<td>
							{{ cluster.name }}
							<template v-if="cluster.description">
								<br>
								<i>
									{{ cluster.description }}
								</i>
							</template>
						</td>
						<td>
							{{ getClusterType(cluster) }}
						</td>
						<td>
							{{ getClusterCity(cluster) }}
						</td>
						<td>
							{{ getClusterUser(cluster) }}
						</td>
						<td class="row-actions text-left" v-if="canUseHelpdeskClustersApi">
							<router-link
								v-b-tooltip.html.top title="Редактировать"
								:to="{ name: routeNames.helpdesk.clusters.update, params: { cluster_id: cluster.id }}"
							>
								<i class="os-icon os-icon-ui-49"></i>
							</router-link>
							<a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(cluster)" class="danger">
								<i class="os-icon os-icon-ui-15"></i>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskClusters.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskClusterTable',
	computed: {
		...mapGetters([
			'helpdeskCities',
			'helpdeskClusters',
			'passportUsers',
			'staffClusters',
			'canUseHelpdeskClustersApi'
		]),
	},
	data() {
		return {
			filters: {
				id: null,
				type: null,
				limit: 50,
				page: 1,
				sort_name: 'asc'
			},
			limits: [
				{ value: 50, text: 'Кластеров на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			canGetNext: true,
			canGetPrev: false,
			typesEnum: [
				{ value: null, text: 'Не выбрано' },
				{ value: 'rop', text: 'РОП' },
			],
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_CITIES_FROM_API',
			'HELPDESK_GET_CLUSTERS_FROM_API',
			'HELPDESK_GET_STAFF_CLUSTERS_FROM_API',
			'HELPDESK_DELETE_CLUSTER',
			'GET_USERS_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit=50;
			this.filters.page=1;
			this.canGetNext=true;
			this.canGetPrev=false;
			await this.refreshData();
		},

		showDeleteQuestion(cluster) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить кластер '+cluster.name+'?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if(value === true) {
					this.HELPDESK_DELETE_CLUSTER({ id: cluster.id, filters: this.filters }).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Кластер успешно удалён' });
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении кластера' });
					});
					this.refreshData();
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
			});
		},

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.refreshData();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.refreshData();
			if(this.helpdeskClusters.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async refreshData() {
			await this.getClusters();

			const ids = [];

			for (let i = 0; i < this.helpdeskClusters.length; i += 1) {
				ids.push(this.helpdeskClusters[i].id);
			}

			if (ids.length > 0) {
				await this.HELPDESK_GET_CITIES_FROM_API({cluster_id: ids.join(','), limit: 1000}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при получении списка филиалов'
					});
				});

				await this.getUsers(ids);
			}
		},

		async getClusters() {
			await this.HELPDESK_GET_CLUSTERS_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка кластеров' });
			});
		},

		async getStaffClusters(clusters) {
			const staff = await this.HELPDESK_GET_STAFF_CLUSTERS_FROM_API({clusterId: clusters.join(',')}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров сотрудников'
				});
			});

			return staff;
		},

		async getUsers(clusters) {
			const result = await this.getStaffClusters(clusters);

			if (result && result.length) {
				const userIds = [];

				for (let i = 0; i < result.length; i += 1) {
					userIds.push(result[i].userId);
				}

				await this.GET_USERS_FROM_API({ids: userIds.join(',')}).catch(() => {
					this.ADD_NOTIFICATION_TO_QUERY({
						type: 'error',
						title: 'Ошибка',
						message: 'Произошла ошибка при получении списка пользователей'
					});
				});
			}
		},

		getClusterType(cluster) {
			for (let i = 0; i < this.typesEnum.length; i += 1) {
				if (this.typesEnum[i].value === cluster.type) {
					return this.typesEnum[i].text;
				}
			}
			
			return '';
		},

		getClusterCity(cluster) {
			const cities = [];

			if (this.helpdeskCities.length) {
				for (let i = 0; i < this.helpdeskCities.length; i += 1) {
					if (this.helpdeskCities[i].clusters && this.helpdeskCities[i].clusters.length) {
						for (let j = 0; j < this.helpdeskCities[i].clusters.length; j += 1) {
							if (this.helpdeskCities[i].clusters[j].id === cluster.id) {
								cities.push(this.helpdeskCities[i].name);
							}
						}
					}
				}
			}

			return cities.join(', ');
		},

		getClusterUser(cluster) {
			const users = [];
			const ids = [];

			if (this.staffClusters && this.staffClusters.length) {
				for (let i = 0; i < this.staffClusters.length; i += 1) {
					if (this.staffClusters[i].clusters && this.staffClusters[i].clusters.length) {
						for (let j = 0; j < this.staffClusters[i].clusters.length; j += 1) {
							if (this.staffClusters[i].clusters[j] === cluster.id) {
								ids.push(this.staffClusters[i].userId);
								break;
							}
						}
					}
				}
			}

			if (ids.length && this.passportUsers && this.passportUsers.length) {
				for (let i = 0; i < ids.length; i += 1) {
					for (let j = 0; j < this.passportUsers.length; j += 1) {
						if (this.passportUsers[j].id === ids[i]) {
							users.push(this.passportUsers[j].name);
							break;
						}
					}
				}
			}

			return users.join(', ');
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}

		await this.refreshData();
	},
};
</script>

<style>
.clusters-table td {
	vertical-align: top !important;
}
</style>
