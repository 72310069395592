<template>
    <div class="element-box">
        <b-form @submit.stop.prevent="submit">
            <h5 class="form-header">
                Изменение данных права доступа
            </h5>
            <div class="form-desc">
                Измените необходимые данные и нажмите сохранить
            </div>

            <b-modal
                    id="confirmation-modal"
                    title="Подтвердите изменения"
                    ok-title="Сохранить"
                    @ok="submit"
                    cancel-title="Отмена"
            >
                <div>
                    Название права доступа: {{ permission.name }}<br>
                    Код права доступа: {{ permission.code }}<br>
                    <div v-if="namespaceOptions.length > 0">
                        Неймспейс права доступа: {{
												namespaceOptions.find(x => x.value === permission.namespace_id).text
                        }}<br>
                    </div>
                    <div v-if="permission.scopes.length > 0">
                        Области видимости:
                        <div v-for="scope in permission.scopes" :key="scope">{{ scope }}</div>
                    </div>

                    <div v-if="add.scopes.length > 0">
                        Добавленны области видимости:
                        <div v-for="scope in add.scopes" :key="scope">{{ scope }}</div>
                    </div>

                    <div v-if="remove.scopes.length > 0">
                        Удалены области видимости:
                        <div v-for="scope in remove.scopes" :key="scope">{{ scope }}</div>
                    </div>
                </div>
            </b-modal>

            <b-form-group id="name-input-group" label="Название права доступа" label-for="permission-name-input">
                <b-form-input
                        id="permission-name-input"
                        v-model="$v.permission.name.$model"
                        :state="validateState('name')"
                        aria-describedby="name-input-group-feedback"
                        placeholder="Введите название права доступа"
                ></b-form-input>
                <b-form-invalid-feedback
                        id="name-input-group-feedback"
                >Обязательное поле.
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="code-input-group" label="Код права доступа" label-for="permission-code-input">
                <b-form-input
                        id="permission-code-input"
                        v-model="$v.permission.code.$model"
                        :state="validateState('code')"

                        aria-describedby="code-input-group-feedback"
                        placeholder="Введите код права доступа"
                ></b-form-input>
                <b-form-invalid-feedback
                        id="code-input-group-feedback"
                >Обязательное поле.
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="description-input-group" label="Описание права доступа"
                          label-for="description-code-input">
                <b-form-textarea
                        id="permission-description-input"
                        v-model="permission.description"
                        placeholder="Введите описание права доступа"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group id="namespace-select-group" label="Неймспейс права доступа" label-for="namespace-select">
                <b-form-select
                        id="namespace-select"
                        v-model="permission.namespace_id"
                        :options="namespaceOptions"
                        :state="validateState('namespace_id')"
                        aria-describedby="namespace_id-input-group-feedback"
                ></b-form-select>
                <b-form-invalid-feedback
                        id="namespace_id-input-group-feedback"
                >Обязательное поле.
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="scopes-input-group" label="Области видимости права доступа"
                          label-for="permission-scopes-input">
                <b-form-tags id="permission-scopes-input" v-model="permissionScopes" no-outer-focus class="mb-2">
                    <template v-slot="{ tags, disabled, addTag, removeTag }">
                        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                <b-form-tag
                                        @remove="onScopeDeleteClick({ option: tag, removeTag: removeTag })"
                                        :disabled="disabled"
                                        variant="info"
                                >{{ tag }}
                                </b-form-tag>
                            </li>
                        </ul>
                        <b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block
                                    menu-class="w-100">
                            <template #button-content>
                                Добавление областей видимости
                            </template>
                            <b-dropdown-form @submit.stop.prevent="() => {}">
                                <b-form-group
                                        label="Поиск областей видимости"
                                        label-for="scope-search-input"
                                        label-cols-md="auto"
                                        class="mb-0"
                                        label-size="sm"
                                        :description="searchScopesDesc"
                                        :disabled="disabled"
                                >
                                    <b-form-input
                                            v-model="searchScopes"
                                            id="scope-search-input"
                                            type="search"
                                            size="sm"
                                            autocomplete="off"
                                            placeholder="Начните вводить название группы прав"
                                    ></b-form-input>
                                </b-form-group>
                            </b-dropdown-form>
                            <b-dropdown-item-button
                                    v-for="scope in scopeAvailableOptions"
                                    :key="scope"
                                    @click="onScopeAddClick({ option: scope, addTag: addTag })"
                            >{{ scope }}
                            </b-dropdown-item-button>
                            <b-dropdown-text v-if="scopeAvailableOptions.length === 0">
                                Нет областей видимости, соответствующим вашим критериям
                            </b-dropdown-text>
                        </b-dropdown>
                    </template>
                </b-form-tags>
            </b-form-group>

            <div class="form-buttons-w">
                <b-button class="btn btn-primary" :disabled="!valid" variant="primary" name="button"
                          v-b-modal="'confirmation-modal'">
                    Сохранить
                </b-button>
                <b-button class="btn btn-grey" @click="backToPermissionTable()">Отмена</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

export default {
	name: 'PermissionUpdateForm',
	mixins: [validationMixin],
	validations: {
		permission: {
			name: {
				required
			},
			code: {
				required
			},
			namespace_id: {
				required
			}
		}
	},
	data() {
		return {
			permission: {
				name: '',
				code: '',
				description: null,
				namespace_id: '',
				disabled: 0,
				scopes: [],
			},
			original_permission: {
				name: '',
				code: '',
				description: null,
				namespace_id: '',
				disabled: 0,
				scopes: [],
			},
			add: {
				scopes: [],
			},
			remove: {
				scopes: [],
			},
			permissionScopes: [],
			scopeOptions: [],
			searchScopes: '',

			namespaceOptions: [],
			namespace: '',

			loaded: false,
			valid: false,
		};
	},

	watch: {
		'permissionScopes': function (val, oldVal) {
			if (this.loaded) {
				this.checkFields();
			}
		},

		'permission.name': function (val, oldVal) {
			if (this.loaded) {
				this.checkFields();
			}
		},

		'permission.code': function (val, oldVal) {
			if (this.loaded) {
				this.checkFields();
			}
		},

		'permission.description': function (val, oldVal) {
			if (this.loaded) {
				this.checkFields();
			}
		},

		'permission.namespace_id': function (val, oldVal) {
			this.checkFields();
		},
	},
	methods: {
		...mapActions([
			'UPDATE_PERMISSION', 'GET_PERMISSIONS_FROM_API', 'GET_NAMESPACES_FROM_API', 'GET_SCOPES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		checkFields() {
			let valid = {
				code: false,
				name: false,
				description: false,
				namespace: false,
				scope: false
			};
			let scopeChanged = false;

			if (this.permission.name && this.permission.name.length > 0) {
				valid.name = true;
			}

			if (this.permission.code && this.permission.code.length > 0) {
				valid.code = true;
			}

			if (this.permission.description && this.permission.description.length > 0) {
				valid.description = true;
			}

			if (this.permission.namespace_id && this.permission.namespace_id.length > 0) {
				valid.namespace = true;
			}

			if (this.permissionScopes && this.permissionScopes.length > 0) {
				valid.scope = true;
				let app = this;
				let original_from_current = [];
				app.original_permission.scopes.forEach(function callback(originValue, originIndex, originArray) {
					//your iterator
					app.permissionScopes.forEach(function callback(currentValue, index, array) {
						//your iterator
						if (originValue === currentValue) {
							original_from_current.push(currentValue);
						}
					});
				});
				if (app.permissionScopes.length > original_from_current.length || original_from_current.length !== app.original_permission.scopes.length) {
					scopeChanged = true;
				}
			}

			this.valid = valid.code && valid.name && valid.description && valid.namespace && valid.scope && (
				this.original_permission.name !== this.permission.name
				|| this.original_permission.code !== this.permission.code
				|| this.original_permission.namespace_id !== this.permission.namespace_id
				|| this.original_permission.description !== this.permission.description
				|| scopeChanged
			);
		},

		onScopeAddClick({option, addTag}) {
			// если группа выбрано к удалению мы убираем удаление
			let idRemovedScope = this.remove.scopes.findIndex(x => x === option);
			if (idRemovedScope > -1) {
				let id = this.scopeOptions.findIndex(x => x === option);
				if (id > -1) {
					this.scopeOptions.splice(id, 1);
				}

				this.remove.scopes.splice(idRemovedScope, 1);
				addTag(option);
				this.searchScopes = '';
				return;
			}
			// если группа уже добавлена к пользователю - возврат
			if (this.permission.scopes.findIndex(x => x === option) > -1 || this.add.scopes.findIndex(x => x === option) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Область видимости уже добавлена'
				});
				return;
			}

			let id = this.scopeOptions.findIndex(x => x === option);
			if (id > -1) {
				this.scopeOptions.splice(id, 1);
			}

			this.add.scopes.push(option);
			addTag(option);
			this.searchScopes = '';
		},

		onScopeDeleteClick({option, removeTag}) {
			// если группа выбрана к добавлению мы убираем добавление
			let idAddedScope = this.add.scopes.findIndex(x => x === option);
			if (idAddedScope > -1) {
				this.scopeOptions.push(option);
				this.scopeOptions.sort((a, b) => a.normalize().localeCompare(b.normalize()));

				this.add.scopes.splice(idAddedScope, 1);
				removeTag(option);
				return;
			}
			this.scopeOptions.push(option);
			this.scopeOptions.sort((a, b) => a.normalize().localeCompare(b.normalize()));

			this.remove.scopes.push(option);
			removeTag(option);
			this.checkFields();
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.permission[name];
			return $dirty ? !$error : null;
		},

		backToPermissionTable() {
			this.$router.push({name: routeNames.passport.permissions.read, query: this.$route.query});
		},

		async submit() {
			this.$v.permission.$touch();
			if (this.$v.permission.$anyError) {
				return;
			}
			await this.UPDATE_PERMISSION({permission: this.permission, add: this.add, remove: this.remove}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'success',
					title: 'Успех',
					message: 'Право доступа успешно обновлено'
				});
				this.backToPermissionTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении права доступа'
				});
			});
		},
	},

	computed: {
		...mapGetters([
			'passportPermissions', 'passportSU', 'passportNamespaces', 'passportScopes'
		]),
		// Функции для работы поиска
		scopesCriteria() {
			return this.searchScopes.trim().toLowerCase();
		},
		scopeAvailableOptions() {
			const criteria = this.scopesCriteria;
			if (criteria) {
				return this.scopeOptions.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
			}
			return this.scopeOptions;
		},
		searchScopesDesc() {
			if (this.scopesCriteria && this.scopeAvailableOptions.length === 0) {
				return 'Нет областей видимости с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		await this.GET_NAMESPACES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка неймспейсов'
			});
		});
		for (let i = 0; i < this.passportNamespaces.length; i++) {
			this.namespaceOptions.push({
				value: this.passportNamespaces[i].id,
				text: this.passportNamespaces[i].name + ' - ' + this.passportNamespaces[i].code
			});
		}
		this.permission.namespace_id = this.namespaceOptions[0].value;

		await this.GET_PERMISSIONS_FROM_API({id: this.$route.params.permission_id}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении права доступа'
			});
		});
		this.permission = this.passportPermissions;
		this.permissionScopes = this.permission.scopes;

		await this.GET_SCOPES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка областей видимости'
			});
		});
		for (let i = 0; i < this.passportScopes.length; i++) {
			this.scopeOptions.push(this.passportScopes[i].code);
		}

		for (let permScope of this.permission.scopes) {
			let id = this.scopeOptions.findIndex(x => x === permScope);
			if (id > -1) {
				this.scopeOptions.splice(id, 1);
			}
		}

		this.original_permission.code = this.permission.code;
		this.original_permission.name = this.permission.name;
		this.original_permission.description = this.permission.description;
		this.original_permission.namespace_id = this.permission.namespace_id;
		this.original_permission.scopes = this.permission.scopes;
		this.loaded = true;

		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
