<template>
	<div class="element-box">
		<h5 class="form-header">
			Офисы работы сотрудников 
			<template v-if="filterOffice">
				/ {{ filterOffice.text }}
			</template>
		</h5>
		<div class="table-responsive">

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация пользователей"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="users-id-filter-group" label="ID" label-for="users-id-filter">
									<b-form-input
											id="users-id-filter"
											v-model="filters.id"
											placeholder="Введите искомый id"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-login-filter-group" label="Логин"
															label-for="users-login-filter">
									<b-form-input
											id="users-login-filter"
											v-model="filters.login"
											placeholder="Введите искомый login"
									></b-form-input>
								</b-form-group>
								<b-form-group id="users-name-filter-group" label="ФИО"
															label-for="users-name-filter">
									<b-form-input
											id="users-name-filter"
											v-model="filters.name"
											placeholder="Введите ФИО"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Вывод пользователей"
															label-for="users-disabled-filter">
									<b-form-select
											v-model="filters.disabled"
											:options="disabledSelectOptions"
									></b-form-select>
								</b-form-group>
								<b-form-group label="Офис" label-for="branches-office_id-filter">
									<b-form-select
											id="branches-office_id-filter"
											v-model="filters.officeId"
											:options="officesOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
								id="passport-users-get-limit"
								name="passport-users-get-limit"
								v-model="filters.limit"
								:options="limits"
								class="form-control form-control-sm rounded bright"
								@change="getUsers()"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
					<tr>
						<th>
							<div>
								Имя
							</div>
						</th>
						<th>
							<div>
								Офис
							</div>
						</th>
					</tr>
				</thead>
				<tbody v-for="user in passportUsers" :key="user.id">
					<tr>
						<td>
							<div>
								<router-link
									title="Редактировать"
									:to="{ name: routeNames.passport.offices.update, params: { user_id: user.id }, query: filters}"
								>
									{{ user.name }}
								</router-link>
							</div>
						</td>
						<td>
							<div>
								{{ getUserOffice(user) }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && passportUsers.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'OfficesTable',
	data() {
		return {
			filters: {
				id: null,
				ids: null,
				login: null,
				name: null,
				disabled: 0,
				officeId: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Пользователей на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],

			disabledSelectOptions: [
				{value: undefined, text: 'Все пользователи'},
				{value: 1, text: 'Только отключённые'},
				{value: 0, text: 'Только активные'},
			],

			functionsOptions: [
				{value: 'consultation', text: 'Конс.'},
				{value: 'drr', text: 'ДРР'},
			],

			canGetNext: true,
			canGetPrev: false,
			routeNames,
		};
	},
	computed: {
		...mapGetters([
			'passportUsers',
			'helpdeskBranches',
			'helpdeskAvailableBranchesWithCityId',
			'staffOffices',
		]),
		filterOffice() {
			if (this.filters.officeId) {
				return this.officesOptions.find(o => o.value === this.filters.officeId) || null;
			}

			return null;
		},
		officesOptions() {
			const a = [];
			for (let office of this.helpdeskAvailableBranchesWithCityId) {
				a.push({value: office.id, text: office.name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите офис...'});
			return a;
		},
	},
	methods: {
		...mapActions([
			'GET_USERS_FROM_API',
			'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_BRANCHES_FROM_API',
			'HELPDESK_GET_STAFF_OFFICES_FROM_API',
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;

			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getStaffOffices(ids);
			}
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getUsers();

				const ids = [];

				for (let i = 0; i < this.passportUsers.length; i += 1) {
					ids.push(this.passportUsers[i].id);
				}

				if (ids.length > 0) {
					await this.getStaffOffices(ids);
				}

				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getUsers();

			const ids = [];

			for (let i = 0; i < this.passportUsers.length; i += 1) {
				ids.push(this.passportUsers[i].id);
			}

			if (ids.length > 0) {
				await this.getStaffOffices(ids);
			}

			if (this.passportUsers.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getUsers() {
			const filters = JSON.parse(JSON.stringify(this.filters));

			if (this.filters.officeId) {
				const offices = await this.getUsersByOfficeId(this.filters.officeId);

				if (offices && offices.length) {
					const userIds = [];

					for (let i = 0; i < offices.length; i += 1) {
						userIds.push(offices[i].userId);
					}

					filters.ids = userIds.join(',');
				}

				filters.officeId = null;
			}

			await this.GET_USERS_FROM_API(filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка пользователей'
				});
			});
			if (this.filters.offset > 0) {
				this.canGetPrev = true;
			}
		},

		async getStaffOffices(users) {
			await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({userId: users.join(',')}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});
		},

		async getUsersByOfficeId(officeId) {
			const res = await this.HELPDESK_GET_STAFF_OFFICES_FROM_API({officeId: officeId}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка офисов сотрудников'
				});
			});

			return res;
		},

		getUserOffice(user) {
			const offices = [];
			const result = this.staffOffices.find(o => o.userId === user.id) || null;

			if (result) {
				if (result.offices.length > 0) {
					for (let i = 0; i < result.offices.length; i += 1) {
						for (let j = 0; j < this.helpdeskBranches.length; j += 1) {
							if (this.helpdeskBranches[j].id === result.offices[i].id) {
								const f = this.functionsOptions.find(v => v.value === result.offices[i].userFunction);
								if (f) {
									offices.push(`${this.helpdeskBranches[j].name} (${f.text})`);
									continue;
								}

								offices.push(this.helpdeskBranches[j].name);
							}
						}
					}
				}
			}

			return offices.join(', ');
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}

		await this.getUsers();

		await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000, project: 'all'}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});

		const ids = [];

		for (let i = 0; i < this.passportUsers.length; i += 1) {
			ids.push(this.passportUsers[i].id);
		}

		if (ids.length > 0) {
			await this.getStaffOffices(ids);
		}
	},
};
</script>

<style>
</style>
