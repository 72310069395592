<template>
	<div class="all-wrapper menu-side with-pattern">
		<div class="auth-box-w">
			<div class="logo-w">
				<router-link class="logo" :to="{ path: '/' }"><img alt="" src="@/assets/logo-big.png"></router-link>
			</div>
			<h4 class="auth-header">
				Сервис авторизации
			</h4>
			<b-form @submit.stop.prevent="">
				<b-form-radio v-model="passport" value="passport">Паспорт</b-form-radio>
				<b-form-radio v-model="passport" value="passport-external">Паспорт-external</b-form-radio>
				<div class="buttons-w">
					<b-button class="btn btn-primary" variant="primary" @click="authorize">Авторизация</b-button>
				</div>
			</b-form>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import router from '@/router';
import {GET_PASSPORT_URL} from '@/config';

export default {
	name: 'Login',
	props: {
		code: {
			type: String,
			default: ''
		},
		error: {
			type: String,
			default: ''
		},
		error_description: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			passport: ''
		};
	},
	methods: {
		...mapActions(['login', 'introspect']),
		async authorize() {
			localStorage.setItem('passport', this.passport);
			await this.login(this.code);
		},
		errorToast() {
			this.$bvToast.toast('Ошибка авторизации', {
				title: 'Ошибка',
				autoHideDelay: 2200,
				variant: 'error',
				solid: true
			});
		}
	},
	computed: {
		...mapGetters(['authStatus']),
	},
	async mounted() {
		let after_route = {name: 'MainPage'};
		if (this.code !== '') {
			if (GET_PASSPORT_URL() === undefined) {
				this.errorToast();
				return;
			}
			await this.login(this.code).then(async () => {
				await this.introspect().then(async () => {
					let after_path = localStorage.getItem('after_login');
					if (after_path) {
						localStorage.removeItem('after_login');
						after_route = {path: after_path};
					}
					await router.push(after_route);
				}).catch(() => {
					this.errorToast();
				});
			}).catch(() => {
				this.errorToast();
			});
		}
	}
};
</script>
