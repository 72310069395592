import * as events from '@/store/passport/auth/event-types';

export const mutations = {
	[events.AUTH_STARTED] (state) {
		state.status = 'loading';
	},
	[events.AUTH_SUCCESS] (state, auth_resp) {
		state.passport_token_data = auth_resp;
		state.status = 'auth_success';
	},
	[events.LOGOUT] (state) {
		state.passport_user_data = { active: 0, user: { permissions: [] } };
		state.passport_token_data = {};
	},
	[events.INTROSPECT_STARTED] (state) {
		state.status = 'intro_started';
	},
	[events.INTROSPECT_SUCCESS] (state, intro_resp) {
		state.passport_user_data = intro_resp;
		state.status = 'intro_success';
	},
};