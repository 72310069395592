import axios from 'axios';
import {VUE_APP_HELPBACK_URL, VUE_APP_TGBOTS_URL} from '@/config';
import * as events from '@/store/helpback/responsible/event-types';
import {HELPBACK_SET_ALL_USERS} from '@/store/helpback/responsible/event-types';

export const actions = {
	async HELPBACK_GET_RESPONSIBLES_FROM_API({commit}, {
		id,
		pipeline,
		amo_user_id,
		bitrix_user_id,
		amo_city_id,
		name,
		disabled,
		international,
		order_column,
		order_direction,
		limit,
		offset
	}) {
		let filter = {
			id: id,
			pipeline: pipeline,
			amo_user_id: amo_user_id,
			bitrix_user_id: bitrix_user_id,
			amo_city_id: amo_city_id,
			name: name,
			disabled: disabled === -1 ? null : disabled,
			international: international === -1 ? null : international,
			order_column: order_column,
			order_direction: order_direction,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPBACK_URL + '/api/v1/responsible', {
			params: filter
		}).then((resp) => {
			if (resp.data.responsibles) {
				commit(events.HELPBACK_SET_RESPONSIBLE, resp.data.responsibles);
				return resp.data.responsibles;
			} else {
				commit(events.HELPBACK_SET_RESPONSIBLE, [resp.data.responsible]);
				return [resp.data.responsible];
			}
		});
	},

	async HELPBACK_GET_ALL_RESP_FROM_API({commit}) {
		return axios.get(VUE_APP_HELPBACK_URL + '/api/v1/responsible').then((resp) => {
			if (resp.data.responsibles) {
				commit(events.HELPBACK_SET_ALL_RESPONSIBLE, resp.data.responsibles);
				return resp.data.responsibles;
			} else {
				commit(events.HELPBACK_SET_ALL_RESPONSIBLE, [resp.data.responsible]);
				return [resp.data.responsible];
			}
		});
	},

	async HELPBACK_GET_ALL_USERS_FROM_API({commit}) {
		return axios.get(VUE_APP_HELPBACK_URL + '/api/v1/dict/users').then((resp) => {
			if (resp.data) {
				commit(events.HELPBACK_SET_ALL_USERS, resp.data);
				return resp.data;
			} else {
				commit(events.HELPBACK_SET_ALL_USERS, [resp.data]);
				return [resp.data];
			}
		});
	},

	// eslint-disable-next-line no-unused-vars
	async HELPBACK_TG_NOTIFY({commit}, {city, group}) {
		return axios.post(VUE_APP_TGBOTS_URL + '/helpback/tgmsg', {
			city: city,
			group: group
		});
	},

	async HELPBACK_DELETE_RESPONSIBLE({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPBACK_URL + '/api/v1/responsible?id=' + id).then(() => {
			dispatch('HELPBACK_GET_RESPONSIBLES_FROM_API', filters);
		});
	},

	async HELPBACK_UPDATE_RESPONSIBLE({commit}, {responsible}) {
		let rBody = responsible;
		for (let key in rBody) {
			if (rBody[key] === '' || rBody[key] === null) {
				delete rBody[key];
			}
		}
		return axios.put(VUE_APP_HELPBACK_URL + '/api/v1/responsible', rBody
		).then((resp) => {
			commit(events.HELPBACK_SET_RESPONSIBLE, [resp.data.responsible]);
			return [resp.data.responsible];
		});
	},

	async HELPBACK_CREATE_RESPONSIBLE({commit}, responsible) {
		return axios.post(VUE_APP_HELPBACK_URL + '/api/v1/responsible', responsible
		).then((resp) => {
			commit(events.HELPBACK_SET_RESPONSIBLE, [resp.data.responsible]);
			return [resp.data.responsible];
		});
	},
};
