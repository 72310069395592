<template>
	<div class="element-box">
		<h5 class="form-header">
			Проектная приоретизация
		</h5>
		<div class="form-desc" v-if="canUseHelpdeskPrioritiesApi">
			Выберите нужный проект и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpdesk.priorities.create }">Создать новый проект</router-link>
		</div>

		<div class="table-responsive" style>
			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6"></div>

					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-priorities-get-limit"
									name="helpdesk-priorities-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPDESK_GET_PRIORITIES_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Проект
						<i class="os-icon os-icon-arrow-up2"
							 v-if="filters.sort_name === null || filters.sort_name !== 'desc'"
							 @click.exact="filters.sort_name='desc'; applyFilters()"
							 @click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							 v-if="filters.sort_name === null || filters.sort_name !== 'asc'"
							 @click.exact="filters.sort_name='asc'; applyFilters()"
							 @click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
					</th>
					<th>
						Приоритет
					</th>
					<th>
						Критичность
					</th>
					<th>
						Проработка БА~%
					</th>
					<th>
						Проработка СА~%
					</th>
					<th v-if="canUseHelpdeskPrioritiesApi">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="priority in helpdeskPriorities" :key="priority.id">
				<tr>
					<td>
						{{ priority.project }}
					</td>
					<td>
						{{ priority.priority }}
					</td>
					<td class="text-left">
						{{ priority.criticality }}
					</td>
					<td class="text-left">
						{{ priority.ba_elaboration }}
					</td>
					<td class="text-left">
						{{ priority.ca_elaboration }}
					</td>
					<td class="row-actions text-left" v-if="canUseHelpdeskPrioritiesApi">
						<router-link v-b-tooltip.html.top title="Редактировать"
												 :to="{ name: routeNames.helpdesk.priorities.update, params: { priority_id: priority.id }} "><i
								class="os-icon os-icon-ui-49"></i></router-link>
						<a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(priority)" class="danger"><i
								class="os-icon os-icon-ui-15"></i></a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskPriorities.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskPriorityTable',
	computed: {
		...mapGetters([
			'helpdeskPriorities', 'canUseHelpdeskPrioritiesApi'
		]),
	},
	data() {
		return {
			filters: {
				sort_name: null,
				limit: 50,
				page: 1
			},
			limits: [
				{value: 50, text: 'Проектов на странице'},
				{value: 10, text: '10'},
				{value: 25, text: '25'},
				{value: 50, text: '50'},
				{value: 100, text: '100'},
				{value: 250, text: '250'}
			],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_PRIORITIES_FROM_API', 'HELPDESK_DELETE_PRIORITY', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 50;
			this.filters.page = 1;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getPriorities();
		},

		showDeleteQuestion(priority) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить проект ' + priority.project + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.HELPDESK_DELETE_PRIORITY({id: priority.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Проект успешно удалён'});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении проекта'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		async previous() {
			if (this.filters.page > 0) {
				this.filters.page--;
				await this.getPriorities();
				this.canGetNext = true;
				if (this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getPriorities();
			if (this.helpdeskPriorities.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getPriorities() {
			await this.HELPDESK_GET_PRIORITIES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка проектов'
				});
			});
		},
	},

	async mounted() {
		await this.getPriorities();
	},
};
</script>

<style>

</style>
