<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Отправка смс
			</h5>

			<b-container>
				<b-row>
					<b-col>
						<h6>Шаблоны сообщений</h6>
						<div class="message-templates">
							<b-list-group v-for="template in helpdeskSmsTemplates" :key="template.id">
								<b-list-group-item @click="sms.text = template.text">{{ template.text }}</b-list-group-item>
							</b-list-group>
						</div>
					</b-col>
					<b-col cols="10">
						<b-form-group id="phone-input-group" label="Телефон" label-for="sms-phone-input">
							<b-form-input
									id="sms-phone-input"
									name="sms-phone-input"
									v-model="$v.sms.phone.$model"
									:state="validateState('phone')"
									aria-describedby="input-sms-text-live-feedback"
									placeholder="Введите телефон в формате '7XXXXXXXXXX'"
							></b-form-input>
							<b-form-invalid-feedback
									id="input-sms-phone-live-feedback"
							>Телефон должен быть в формате '7XXXXXXXXXX'.
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group id="text-input-group" label="Текст сообщения" label-for="sms-text-input">
							<b-form-textarea
									id="sms-text-input"
									name="sms-text-input"
									rows="6"
									v-model="$v.sms.text.$model"
									:state="validateState('text')"
									aria-describedby="input-sms-text-live-feedback"
									placeholder="Введите текст сообщения"
							></b-form-textarea>
							<b-form-invalid-feedback
									id="input-sms-text-live-feedback"
							>Сообщение не может быть пустым!
							</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button" @click="submit">Отправить</b-button>
				<b-button class="btn btn-grey">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

const phoneRegex = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /7([0-9]{10})/.test(value);
};

export default {
	name: 'HelpDeskSmsSendForm',
	mixins: [validationMixin],
	validations: {
		sms: {
			text: {
				required
			},
			phone: {
				required,
				phoneRegex
			}
		}
	},
	data() {
		return {
			sms: {
				text: null,
				phone: null
			}
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_SEND_CUSTOM_SMS', 'HELPDESK_GET_SMS_TEMPLATES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateState(name) {
			const {$dirty, $error} = this.$v.sms[name];
			return $dirty ? !$error : null;
		},

		async submit() {
			this.$v.sms.$touch();
			if (this.$v.sms.$anyError) {
				return;
			}
			await this.HELPDESK_SEND_CUSTOM_SMS(this.sms).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Сообщение успешно отправлено' });
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при отправке сообщения' });
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskSmsTemplates'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_SMS_TEMPLATES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка шаблонов' });
		});
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>
.message-templates {
	height:400px;
	overflow-y:scroll;
}
</style>
