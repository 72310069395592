<template>
    <div class="element-box">
        <h5 class="form-header">
            Таблица областей видимости
        </h5>
        <div class="form-desc">
            Выберите нужные области видимости и нажмите на кнопку действия<br>
            <router-link :to="{ name: routeNames.passport.scopes.create }">Создать новую область видимости</router-link>
        </div>
        <div class="table-responsive" style>
            <div class="controls-above-table">
                <div class="row">
                    <div class="col-sm-6">
                        <a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
                        <b-modal
                                id="filters-modal"
                                title="Фильтрация областей видимости"
                                ok-title="Применить"
                                cancel-title="Отмена"
                                v-on:ok="applyFilters"
                        >
                            <b-form>
                                <b-form-group id="scopes-id-filter-group" label="ID" label-for="scopes-id-filter">
                                    <b-form-input
                                            id="scopes-id-filter"
                                            v-model="filters.id"
                                            placeholder="Введите искомый id"
                                    ></b-form-input>
                                </b-form-group>
                            </b-form>
                        </b-modal>
                    </div>
                    <div class="col-sm-6">
                        <form class="form-inline justify-content-sm-end">
                            <b-form-select
                                    id="passport-scopes-get-limit"
                                    name="passport-scopes-get-limit"
                                    v-model="filters.limit"
                                    :options="limits"
                                    class="form-control form-control-sm rounded bright"
                                    @change="GET_SCOPES_FROM_API(filters)"
                            ></b-form-select>
                        </form>
                    </div>
                </div>
            </div>

            <table class="table table-lightborder">
                <thead>
                <tr>
                    <th>
                        Область видимости
                    </th>
                    <th class="text-right">
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody v-for="scope in passportScopes" :key="scope.id">
                <tr>
                    <td>
                        {{ scope.code }}
                    </td>

                    <td class="row-actions text-right">
                        <router-link v-if="canUpdatePassportScopes || passportSU" v-b-tooltip.html.top
                                     title="Редактировать"
                                     :to="{ name: routeNames.passport.scopes.update, params: { scope_id: scope.id }} ">
                            <i class="os-icon os-icon-ui-49"></i>
                        </router-link>

                        <a v-if="canDeletePassportScopes || passportSU" v-b-tooltip.html.top title="Удалить"
                           @click="showDeleteQuestion(scope)" class="danger">
                            <i class="os-icon os-icon-ui-15"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="controls-below-table">
            <div class="table-records-info">
            </div>
            <div class="table-records-pages">
                <ul>
                    <li>
                        <a href="#" @click="previous" v-if="canGetPrev">Назад</a>
                    </li>
                    <li>
                        <a href="#" @click="next" v-if="canGetNext && passportScopes.length === filters.limit">Далее</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'ScopeTable',
	data() {
		return {
			filters: {
				id: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Областей видимости на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],
			deleteCheck: false,
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	computed: {
		...mapGetters([
			'passportScopes', 'canUpdatePassportScopes', 'canDeletePassportScopes',
			'passportSU'
		]),
	},
	methods: {
		...mapActions([
			'GET_SCOPES_FROM_API', 'DELETE_SCOPE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getScopes();
		},

		showDeleteQuestion(scope) {
			this.deleteCheck = false;
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить область видимости ' + scope.code + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.DELETE_SCOPE({id: scope.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'success',
							title: 'Успех',
							message: 'Область видимости успешно удалена'
						});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении области видимости'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getScopes();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getScopes();
			if (this.passportScopes.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getScopes() {
			await this.GET_SCOPES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка областей видимости'
				});
			});
		},
	},

	async mounted() {
		await this.getScopes();
	},
};
</script>

<style>

</style>
