export const getters = {
	helpbackResponsibles: state => state.helpback_responsible,
	helpbackLastRespCity: (state) => (resp) => {
		let cnt = 0;
		for (let r of state.helpback_all_resp) {
			if (r.amo_city_id && r.amo_city_id === resp.amo_city_id) {
				cnt += 1;
			}
		}
		return cnt <= 1;
	},
	helpbackLastRespGroup: (state) => (resp) => {
		const user = state.helpback_all_users.find(v => resp.name === v.name);
		if (user === undefined) {
			return false;
		}

		let cnt = 0;
		for (let r of state.helpback_all_users) {
			if (r.group_id && r.group_id === user.group_id) {
				cnt += 1;
			}
		}
		return cnt <= 1;
	},
};
