import * as events from '@/store/helpdesk/clusters/event-types';

export const mutations = {
	[events.HELPDESK_SET_CLUSTERS](state, clusters) {
		state.helpdesk_clusters = clusters;
	},
	[events.HELPDESK_SET_CLUSTER](state, cluster) {
		state.helpdesk_cluster = cluster;
	}
};
