export const permissionNames = {
	CAN_USE_HELPDESK_BRANCHES_API: 'helpdesk_can_use_branches_api',
	CAN_USE_HELPDESK_BRANCH_CONSULTATIONS: 'helpdesk_can_use_branch_consultations',
	CAN_USE_HELPDESK_CITIES_API: 'helpdesk_can_use_cities_api',
	CAN_USE_HELPDESK_PHONES_API: 'helpdesk_can_use_phones_api',
	CAN_USE_HELPDESK_SIGNERS_API: 'helpdesk_can_use_signers_api',
	CAN_USE_HELPDESK_SITE_INFO_API: 'helpdesk_can_use_site_info_api',
	CAN_USE_HELPDESK_SITES_API: 'helpdesk_can_use_sites_api',
	CAN_USE_HELPDESK_CUSTOM_SMS_API: 'helpdesk_can_use_custom_sms',
	CAN_USE_HELPDESK_HUNTERFORM_API: 'helpdesk.hunterform_api',
	CAN_USE_HELPDESK_PRIORITIES_API: 'helpdesk.priorities_api',
	CAN_USE_HELPDESK_VPN_API: 'helpdesk.vpn_api',
	CAN_USE_HELPDESK_LEOBOT_API: 'helpdesk.leobot_api',
	CAN_USE_HELPDESK_BRANCH_PROJECT_BFL: 'helpdesk.branches_project_kreditanet',
	CAN_USE_HELPDESK_BRANCH_CURATOR_BFL: 'helpdesk.branches_curator_bfl',
	CAN_USE_HELPDESK_DISCOUNT: 'helpdesk.discounts_api',
	CAN_USE_HELPDESK_TARIFFS_API: 'helpdesk_can_use_tariffs_api',
	CAN_USE_HELPDESK_WEEKENDS_API: 'helpdesk_can_use_weekends_api',
	CAN_USE_HELPDESK_TERMINAL_DEACTIVATOR: 'helpdesk.terminal_deactivator',
	CAN_USE_HELPDESK_CHANGE_CONSULTATION: 'helpdesk_can_change_consultation',
	CAN_USE_HELPDESK_VIEW_CONSULTATION: 'helpdesk_can_view_consultation',
	CAN_USE_HELPDESK_CLUSTERS_API: 'helpdesk_can_use_clusters_api',

	PASSPORT_SU: 'api_access',

	CAN_READ_PASSPORT_USERS: 'passport.user.read',
	CAN_CREATE_PASSPORT_USERS: 'passport.user.create',
	CAN_DELETE_PASSPORT_USERS: 'passport.user.delete',
	CAN_UPDATE_PASSPORT_USERS: 'passport.user.update',

	CAN_READ_PASSPORT_ATTRIBUTES: 'passport.attribute.read',
	CAN_CREATE_PASSPORT_ATTRIBUTES: 'passport.attribute.create',
	CAN_DELETE_PASSPORT_ATTRIBUTES: 'passport.attribute.delete',
	CAN_UPDATE_PASSPORT_ATTRIBUTES: 'passport.attribute.update',

	CAN_READ_PASSPORT_GROUPS: 'passport.group.read',
	CAN_CREATE_PASSPORT_GROUPS: 'passport.group.create',
	CAN_DELETE_PASSPORT_GROUPS: 'passport.group.delete',
	CAN_UPDATE_PASSPORT_GROUPS: 'passport.group.update',

	CAN_READ_PASSPORT_PERMISSIONS: 'passport.permission.read',
	CAN_CREATE_PASSPORT_PERMISSIONS: 'passport.permission.create',
	CAN_DELETE_PASSPORT_PERMISSIONS: 'passport.permission.delete',
	CAN_UPDATE_PASSPORT_PERMISSIONS: 'passport.permission.update',

	CAN_READ_PASSPORT_SCOPES: 'passport.scope.read',
	CAN_CREATE_PASSPORT_SCOPES: 'passport.scope.create',
	CAN_DELETE_PASSPORT_SCOPES: 'passport.scope.delete',
	CAN_UPDATE_PASSPORT_SCOPES: 'passport.scope.update',

	CAN_READ_PASSPORT_NAMESPACES: 'passport.namespace.read',
	CAN_CREATE_PASSPORT_NAMESPACES: 'passport.namespace.create',
	CAN_DELETE_PASSPORT_NAMESPACES: 'passport.namespace.delete',
	CAN_UPDATE_PASSPORT_NAMESPACES: 'passport.namespace.update',

	CAN_READ_HELPBACK_RESPONSIBLES: 'helpback.responsible.read',
	CAN_CREATE_HELPBACK_RESPONSIBLES: 'helpback.responsible.create',
	CAN_DELETE_HELPBACK_RESPONSIBLES: 'helpback.responsible.delete',
	CAN_UPDATE_HELPBACK_RESPONSIBLES: 'helpback.responsible.update',

	CAN_USE_TERM_DOCGEN: 'term_docgen_access',

	ADV_CAB_ACCESS: 'advcab.access',

	CAN_READ_OKK_FILES: 'okk.files.read',
	CAN_READ_OKK_FINES: 'okk.fines.read',
};

export const scopeNames = {
	HELPDESK: 'helpdesk',
	PASSPORT: 'passport_api',
	TERM_DOCGEN: 'term_docgen',
	ADV_CAB: 'adv_cabinet',
	OKK: 'okk'
};

export const attributeNames = {
	ADV_CAB_ROLE: {name: 'adv_cabinet_role', values: ['farmer', 'admin', 'observer', 'accountant']},
	ADV_CAB_ACCESS: {name: 'adv_cabinet_access', values: ['true']}
};
