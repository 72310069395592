import axios from 'axios';
import {GET_PASSPORT_URL} from '@/config';
import * as events from '@/store/passport/permissions/event-types';

export const actions = {
	async GET_PERMISSIONS_FROM_API({commit}, {id, namespace_id, scopes, limit, offset}) {
		let filter = {
			id: id,
			namespace_id: namespace_id,
			limit: limit,
			offset: offset,
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		if (scopes) {
			if (scopes.length > 0) {
				filter.scopes = '';
				for (let i in scopes) {
					filter.scopes += scopes[i] + ',';
				}
			}
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/permission', {
			params: filter
		}).then((resp) => {
			if (resp.data.permissions) {
				commit(events.PASSPORT_SET_PERMISSIONS, resp.data.permissions);
				return resp.data.permissions;
			} else {
				commit(events.PASSPORT_SET_PERMISSIONS, resp.data.permission);
				return resp.data.permission;
			}
		});
	},

	async DELETE_PERMISSION({dispatch}, {id, filters}) {
		return axios.delete(GET_PASSPORT_URL() + '/api/v1/permission?id=' + id).then(() => {
			dispatch('GET_PERMISSIONS_FROM_API', filters);
		});
	},

	async UPDATE_PERMISSION({commit}, {permission, add, remove}) {
		let rBody = {};
		rBody.permission = {
			id: permission.id,
			name: permission.name,
			code: permission.code,
			description: permission.description,
			namespace_id: permission.namespace_id,
			disabled: permission.disabled
		};
		if (Object.keys(add).length !== 0) {
			rBody.add = add;
		}
		if (Object.keys(remove).length !== 0) {
			rBody.remove = remove;
		}

		return axios.put(GET_PASSPORT_URL() + '/api/v1/permission', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_PERMISSIONS, resp.data);
			return resp.data;
		});
	},

	async CREATE_PERMISSION({commit}, permission) {
		let rBody = {};
		rBody.permission = permission;
		return axios.post(GET_PASSPORT_URL() + '/api/v1/permission', rBody
		).then((resp) => {
			commit(events.PASSPORT_SET_PERMISSIONS, resp.data);
			return resp.data;
		});
	},
};
