import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/discounts/event-types';

export const actions = {
	async HELPDESK_GET_DISCOUNTS_FROM_API({commit}, { name, limit, page }) {
		let filter = {
			name: name,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/discounts', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_DISCOUNTS, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_DISCOUNT_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/discount/'+id).then((resp) => {
			commit(events.HELPDESK_SET_DISCOUNT, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_DISCOUNT({dispatch}, { id, filters }) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/discount/'+id).then(() => {
			dispatch('HELPDESK_GET_DISCOUNTS_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_DISCOUNT({commit}, { id: id, discount: {
		name,
		sum,
		expiration,
		comment,
	}}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/discount/'+id, {
			name: name,
			sum: Number(sum),
			expiration: expiration,
			comment: comment,
		}).then((resp) => {
			commit(events.HELPDESK_SET_DISCOUNT, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_DISCOUNT({commit}, {
		name,
		sum,
		expiration,
		comment,
	}) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/discount', {
			name: name,
			sum: Number(sum),
			expiration: expiration,
			comment: comment,
		}).then((resp) => {
			commit(events.HELPDESK_SET_DISCOUNT, resp.data);
			return resp.data;
		});
	},
};