import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/weekends/event-types';

function weekendData(weekend) {
	return {
		branch_id: weekend.branch_id,
		start_date: weekend.start_date,
		start_date_local: weekend.start_date_local,
		end_date: weekend.end_date,
		end_date_local: weekend.end_date_local,
		comment: weekend.comment || '',
	};
}

export const actions = {
	async HELPDESK_GET_WEEKENDS_FROM_API({commit}, { branch_id, domain, limit, page }) {
		let filter = {
			branch_id: branch_id,
			domain: domain,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/weekends', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_WEEKENDS, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_WEEKEND_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/weekend/'+id).then((resp) => {
			commit(events.HELPDESK_SET_WEEKEND, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_WEEKEND({dispatch}, { id, filters }) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/weekend/'+id).then(() => {
			dispatch('HELPDESK_GET_WEEKENDS_FROM_API', filters);
		});
	},

	async HELPDESK_CREATE_WEEKEND({commit}, weekend) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/weekend', weekendData(weekend), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_WEEKEND, resp.data);
			return resp.data;
		});
	},
};