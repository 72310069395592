import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import {attributeNames, permissionNames, scopeNames} from '@/store/passport/auth/constants';
import {routeNames} from '@/router/constants';

import Login from '@/views/Login';
import MainPage from '@/components/MainPage';
import PageNotFound from '@/components/PageNotFound';

import BranchTable from '@/components/helpdesk/branches/BranchTable';
import BranchConsultationsTable from '@/components/helpdesk/branches/BranchConsultationsTable';
import BranchCreateForm from '@/components/helpdesk/branches/BranchCreateForm';
import BranchUpdateForm from '@/components/helpdesk/branches/BranchUpdateForm';

import CityTable from '@/components/helpdesk/cities/CityTable';
import CityCreateForm from '@/components/helpdesk/cities/CityCreateForm';
import CityUpdateForm from '@/components/helpdesk/cities/CityUpdateForm';

import PhoneTable from '@/components/helpdesk/phones/PhoneTable';
import PhoneCreateForm from '@/components/helpdesk/phones/PhoneCreateForm';
import PhoneUpdateForm from '@/components/helpdesk/phones/PhoneUpdateForm';

import DiscountTable from '@/components/helpdesk/dicounts/DiscountTable';
import DiscountCreateForm from '@/components/helpdesk/dicounts/DiscountCreateForm';
import DiscountUpdateForm from '@/components/helpdesk/dicounts/DiscountUpdateForm';

import SignerTable from '@/components/helpdesk/signers/SignerTable';
import SignerCreateForm from '@/components/helpdesk/signers/SignerCreateForm';
import SignerUpdateForm from '@/components/helpdesk/signers/SignerUpdateForm';

import SiteInfoForm from '@/components/helpdesk/siteinfo/SiteInfoForm';

import SitesTable from '@/components/helpdesk/sites/SitesTable';

import HunterForm from '@/components/helpdesk/hunterform/HunterForm';

import SmsSendForm from '@/components/helpdesk/sms/SmsSendForm';
import TemplateTable from '../components/helpdesk/sms/TemplateTable';
import TemplateUpdateForm from '../components/helpdesk/sms/TemplateUpdateForm';
import TemplateCreateForm from '../components/helpdesk/sms/TemplateCreateForm';

import WeekendTable from '@/components/helpdesk/weekends/WeekendTable';
import WeekendCreateForm from '@/components/helpdesk/weekends/WeekendCreateForm';

import ClusterTable from '@/components/helpdesk/clusters/ClusterTable';
import ClusterCreateForm from '@/components/helpdesk/clusters/ClusterCreateForm';
import ClusterUpdateForm from '@/components/helpdesk/clusters/ClusterUpdateForm';

import UserTable from '@/components/passport/users/UserTable';
import UserCreateForm from '@/components/passport/users/UserCreateForm';
import UserUpdateForm from '@/components/passport/users/UserUpdateForm';

import PermissionTable from '@/components/passport/permissions/PermissionTable';
import PermissionCreateForm from '@/components/passport/permissions/PermissionCreateForm';
import PermissionUpdateForm from '@/components/passport/permissions/PermissionUpdateForm';

import GroupTable from '@/components/passport/groups/GroupTable';
import GroupCreateForm from '@/components/passport/groups/GroupCreateForm';
import GroupUpdateForm from '@/components/passport/groups/GroupUpdateForm';

import ScopeTable from '../components/passport/scopes/ScopeTable';
import ScopeCreateForm from '@/components/passport/scopes/ScopeCreateForm';
import ScopeUpdateForm from '@/components/passport/scopes/ScopeUpdateForm';

import NamespaceTable from '../components/passport/namespaces/NamespaceTable';
import NamespaceCreateForm from '@/components/passport/namespaces/NamespaceCreateForm';
import NamespaceUpdateForm from '@/components/passport/namespaces/NamespaceUpdateForm';

import AttributeTable from '@/components/passport/attributes/AttributeTable';
import AttributeCreateForm from '@/components/passport/attributes/AttributeCreateForm';
import AttributeUpdateForm from '@/components/passport/attributes/AttributeUpdateForm';

import ScheduleTable from '@/components/passport/schedule/ScheduleTable';
import ScheduleUpdateForm from '@/components/passport/schedule/ScheduleUpdateForm';

import OfficesTable from '@/components/passport/offices/OfficesTable';
import OfficesUpdateForm from '@/components/passport/offices/OfficesUpdateForm';

import ClustersTable from '@/components/passport/clusters/ClustersTable';
import ClustersUpdateForm from '@/components/passport/clusters/ClustersUpdateForm';

import ResponsibleTable from '@/components/helpback/responsible/ResponsibleTable';
import ResponsibleCreateForm from '@/components/helpback/responsible/ResponsibleCreateForm';
import ResponsibleUpdateForm from '@/components/helpback/responsible/ResponsibleUpdateForm';

import TerminationDocGenForm from '@/components/term-docgen/generator';
import PriorityTable from '@/components/helpdesk/priorities/PriorityTable';
import PriorityUpdateForm from '@/components/helpdesk/priorities/PriorityUpdateForm';
import PriorityCreateForm from '@/components/helpdesk/priorities/PriorityCreateForm';

import AdvContactsTable from '@/components/adv-cab/contacts/table';
import AdvDealsTable from '@/components/adv-cab/deals/table';
import AdvDealsForm from '@/components/adv-cab/deals/form';
import TariffTable from '@/components/helpdesk/tariffs/TariffTable.vue';
import TariffCreateForm from '@/components/helpdesk/tariffs/TariffCreateForm.vue';
import TariffUpdateForm from '@/components/helpdesk/tariffs/TariffUpdateForm.vue';

import FileTable from '@/components/okk/files/FileTable.vue';
import FinesTable from '@/components/okk/fines/FinesTable.vue';

Vue.use(VueRouter);
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '',
			name: 'MainPage',
			component: MainPage,
			meta: {
				breadcrumb: [{text: 'Домой', href: '/', active: true}],
			},
		},
		{
			path: '*',
			name: '404',
			component: PageNotFound,
			meta: {
				breadcrumb: [{text: 'Домой', href: '/'}],
			},
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			props: (route) => ({
				code: route.query.code,
				error: route.query.error,
				error_description: route.query.error_description,
			}),
			meta: {
				breadcrumb: [{text: 'Домой', href: '/'}],
			},
		},

		{
			path: '/helpdesk/branches',
			name: routeNames.helpdesk.branches.read,
			component: BranchTable,
			meta: {
				requiresAuth: true,
				requiresOneOfPermissions: [
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
					permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
					permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL,
				],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Филиалы', active: true},
				],
			},
		},
		{
			path: '/helpdesk/branches/consultations',
			name: routeNames.helpdesk.branches.consultations,
			component: BranchConsultationsTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_BRANCH_CONSULTATIONS],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Консультации в филиалах', active: true},
				],
			},
		},
		{
			path: '/helpdesk/branch/:branch_id',
			name: routeNames.helpdesk.branches.update,
			component: BranchUpdateForm,
			meta: {
				requiresAuth: true,
				requiresOneOfPermissions: [
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
					permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
					permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL,
					permissionNames.CAN_USE_HELPDESK_BRANCH_CONSULTATIONS
				],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Филиалы', to: {name: routeNames.helpdesk.branches.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/branch',
			name: routeNames.helpdesk.branches.create,
			component: BranchCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_BRANCHES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Филиалы', to: {name: routeNames.helpdesk.branches.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/cities',
			name: routeNames.helpdesk.cities.read,
			component: CityTable,
			meta: {
				requiresAuth: true,
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Города',
						to: {name: routeNames.helpdesk.cities.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/city/:city_id',
			name: routeNames.helpdesk.cities.update,
			component: CityUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CITIES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Города', to: {name: routeNames.helpdesk.cities.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/city',
			name: routeNames.helpdesk.cities.create,
			component: CityCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CITIES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Города', to: {name: routeNames.helpdesk.cities.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/site_info',
			name: routeNames.helpdesk.siteInfo.update,
			component: SiteInfoForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_SITE_INFO_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Информация сайтов', active: true},
					{text: 'Обновление', active: true},
				],
			},
		},

		{
			path: '/helpdesk/sites',
			name: routeNames.helpdesk.sites.read,
			component: SitesTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_SITES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Список сервисов', active: true},
				],
			},
		},

		{
			path: '/helpdesk/sms/send',
			name: routeNames.helpdesk.sms.send,
			component: SmsSendForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Шаблоны', to: {name: routeNames.helpdesk.sms.read}},
					{text: 'Отправка', active: true},
				],
			},
		},
		{
			path: '/helpdesk/sms/templates',
			name: routeNames.helpdesk.sms.read,
			component: TemplateTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Шаблоны',
						to: {name: routeNames.helpdesk.sms.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/sms/template/:template_id',
			name: routeNames.helpdesk.sms.update,
			component: TemplateUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Шаблоны', to: {name: routeNames.helpdesk.sms.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/sms/template',
			name: routeNames.helpdesk.sms.create,
			component: TemplateCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Шаблоны', to: {name: routeNames.helpdesk.sms.read}},
					{text: 'Создание', active: true},
				],
			},
		},
		{
			path: '/helpdesk/phones',
			name: routeNames.helpdesk.phones.read,
			component: PhoneTable,
			meta: {
				requiresAuth: true,
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Телефоны',
						to: {name: routeNames.helpdesk.phones.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/phone/:phone_id',
			name: routeNames.helpdesk.phones.update,
			component: PhoneUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_PHONES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Телефоны', to: {name: routeNames.helpdesk.phones.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/phone',
			name: routeNames.helpdesk.phones.create,
			component: PhoneCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_PHONES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Телефоны', to: {name: routeNames.helpdesk.phones.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/discounts',
			name: routeNames.helpdesk.discounts.read,
			component: DiscountTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_DISCOUNT],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Скидки',
						to: {name: routeNames.helpdesk.discounts.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/discount/:discount_id',
			name: routeNames.helpdesk.discounts.update,
			component: DiscountUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_DISCOUNT],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Скидки', to: {name: routeNames.helpdesk.discounts.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/discount',
			name: routeNames.helpdesk.discounts.create,
			component: DiscountCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_DISCOUNT],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Скидки', to: {name: routeNames.helpdesk.discounts.read}},
					{text: 'Создание', active: true},
				],
			},
		},
		{
			path: '/helpdesk/tariffs',
			name: routeNames.helpdesk.tariffs.read,
			component: TariffTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_TARIFFS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Тарифы',
						to: {name: routeNames.helpdesk.tariffs.read},
						active: true,
					},
				],
			}
		},
		{
			path: '/helpdesk/tariff',
			name: routeNames.helpdesk.tariffs.create,
			component: TariffCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_TARIFFS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Тарифы', to: {name: routeNames.helpdesk.tariffs.read}},
					{text: 'Создание', active: true},
				],
			},
		},
		{
			path: '/helpdesk/tariff/:tariff_id',
			name: routeNames.helpdesk.tariffs.update,
			component: TariffUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_TARIFFS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Тарифы', to: {name: routeNames.helpdesk.tariffs.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/signers',
			name: routeNames.helpdesk.signers.read,
			component: SignerTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_SIGNERS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Подписанты',
						to: {name: routeNames.helpdesk.signers.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/signer/:signer_id',
			name: routeNames.helpdesk.signers.update,
			component: SignerUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_SIGNERS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Подписанты',
						to: {name: routeNames.helpdesk.signers.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/signer',
			name: routeNames.helpdesk.signers.create,
			component: SignerCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_SIGNERS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Подписанты',
						to: {name: routeNames.helpdesk.signers.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/priorities',
			name: routeNames.helpdesk.priorities.read,
			component: PriorityTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_PRIORITIES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Проекты',
						to: {name: routeNames.helpdesk.signers.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/priority/:priority_id',
			name: routeNames.helpdesk.priorities.update,
			component: PriorityUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_PRIORITIES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Проекты',
						to: {name: routeNames.helpdesk.signers.read},
					},
					{
						text: 'Обновление',
						active: true
					},
				],
			},
		},
		{
			path: '/helpdesk/priority',
			name: routeNames.helpdesk.priorities.create,
			component: PriorityCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_PRIORITIES_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Проекты',
						to: {name: routeNames.helpdesk.signers.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/hunterform',
			name: routeNames.helpdesk.hunterForm,
			component: HunterForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_HUNTERFORM_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'HunterForm',
						to: {name: routeNames.helpdesk.hunterForm},
						active: true
					},
				],
			},
		},

		{
			path: '/helpdesk/weekends',
			name: routeNames.helpdesk.weekends.read,
			component: WeekendTable,
			meta: {
				requiresAuth: true,
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Выходные',
						to: {name: routeNames.helpdesk.weekends.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/weekend',
			name: routeNames.helpdesk.weekends.create,
			component: WeekendCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_WEEKENDS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Выходные', to: {name: routeNames.helpdesk.weekends.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpdesk/clusters',
			name: routeNames.helpdesk.clusters.read,
			component: ClusterTable,
			meta: {
				requiresAuth: true,
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{
						text: 'Кластеры сотрудников',
						to: {name: routeNames.helpdesk.clusters.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpdesk/cluster/:cluster_id',
			name: routeNames.helpdesk.clusters.update,
			component: ClusterUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CLUSTERS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Кластеры сотрудников', to: {name: routeNames.helpdesk.clusters.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpdesk/cluster',
			name: routeNames.helpdesk.clusters.create,
			component: ClusterCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_HELPDESK_CLUSTERS_API],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Кластеры', to: {name: routeNames.helpdesk.clusters.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/users',
			name: routeNames.passport.users.read,
			component: UserTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_USERS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Пользователи',
						to: {name: routeNames.passport.users.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/user/:user_id',
			name: routeNames.passport.users.update,
			component: UserUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_USERS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Пользователи',
						to: {name: routeNames.passport.users.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/user',
			name: routeNames.passport.users.create,
			component: UserCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_USERS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Пользователи',
						to: {name: routeNames.passport.users.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/schedule',
			name: routeNames.passport.schedule.read,
			component: ScheduleTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{text: 'Расписание работы', active: true},
				],
			},
		},
		{
			path: '/passport/schedule/:user_id',
			name: routeNames.passport.schedule.update,
			component: ScheduleUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Расписание работы',
						to: {name: routeNames.passport.schedule.read},
					},
					{text: 'Расписание сотрудника', active: true},
				],
			},
		},


		{
			path: '/passport/offices',
			name: routeNames.passport.offices.read,
			component: OfficesTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{text: 'Офисы работы', active: true},
				],
			},
		},
		{
			path: '/passport/offices/:user_id',
			name: routeNames.passport.offices.update,
			component: OfficesUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Офисы работы',
						to: {name: routeNames.passport.offices.read},
					},
					{text: 'Офисы сотрудника', active: true},
				],
			},
		},

		{
			path: '/passport/clusters',
			name: routeNames.passport.clusters.read,
			component: ClustersTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{text: 'Кластеры', active: true},
				],
			},
		},
		{
			path: '/passport/clusters/:user_id',
			name: routeNames.passport.clusters.update,
			component: ClustersUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [
					permissionNames.CAN_READ_PASSPORT_USERS,
					permissionNames.CAN_READ_PASSPORT_ATTRIBUTES,
					permissionNames.CAN_USE_HELPDESK_CLUSTERS_API,
				],
				requiresScopes: [scopeNames.PASSPORT, scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Кластеры',
						to: {name: routeNames.passport.clusters.read},
					},
					{text: 'Кластеры сотрудника', active: true},
				],
			},
		},

		{
			path: '/passport/permissions',
			name: routeNames.passport.permissions.read,
			component: PermissionTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_PERMISSIONS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Права доступа',
						to: {name: routeNames.passport.permissions.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/permission/:permission_id',
			name: routeNames.passport.permissions.update,
			component: PermissionUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_PERMISSIONS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Права доступа',
						to: {name: routeNames.passport.permissions.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/permission',
			name: routeNames.passport.permissions.create,
			component: PermissionCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_PERMISSIONS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Права доступа',
						to: {name: routeNames.passport.permissions.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/groups',
			name: routeNames.passport.groups.read,
			component: GroupTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_GROUPS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Группы',
						to: {name: routeNames.passport.groups.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/group/:group_id',
			name: routeNames.passport.groups.update,
			component: GroupUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_GROUPS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{text: 'Группы', to: {name: routeNames.passport.groups.read}},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/group',
			name: routeNames.passport.groups.create,
			component: GroupCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_GROUPS],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{text: 'Группы', to: {name: routeNames.passport.groups.read}},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/scopes',
			name: routeNames.passport.scopes.read,
			component: ScopeTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_SCOPES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Области видимости',
						to: {name: routeNames.passport.scopes.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/scope/:scope_id',
			name: routeNames.passport.scopes.update,
			component: ScopeUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_SCOPES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Области видимости',
						to: {name: routeNames.passport.scopes.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/scope',
			name: routeNames.passport.scopes.create,
			component: ScopeCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_SCOPES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Области видимости',
						to: {name: routeNames.passport.scopes.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/namespaces',
			name: routeNames.passport.namespaces.read,
			component: NamespaceTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_NAMESPACES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Неймспейсы',
						to: {name: routeNames.passport.namespaces.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/namespace/:namespace_id',
			name: routeNames.passport.namespaces.update,
			component: NamespaceUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_NAMESPACES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Неймспейсы',
						to: {name: routeNames.passport.namespaces.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/namespace',
			name: routeNames.passport.namespaces.create,
			component: NamespaceCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_NAMESPACES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Неймспейсы',
						to: {name: routeNames.passport.namespaces.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/passport/attributes',
			name: routeNames.passport.attributes.read,
			component: AttributeTable,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_READ_PASSPORT_ATTRIBUTES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Атрибуты',
						to: {name: routeNames.passport.attributes.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/passport/attribute/:attribute_id',
			name: routeNames.passport.attributes.update,
			component: AttributeUpdateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_UPDATE_PASSPORT_ATTRIBUTES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Атрибуты',
						to: {name: routeNames.passport.attributes.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/passport/attribute',
			name: routeNames.passport.attributes.create,
			component: AttributeCreateForm,
			meta: {
				requiresAuth: true,
				SUPermission: permissionNames.PASSPORT_SU,
				requiresPermissions: [permissionNames.CAN_CREATE_PASSPORT_ATTRIBUTES],
				requiresScopes: [scopeNames.PASSPORT],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'Passport',
						to: {name: routeNames.passport.main},
						active: true,
					},
					{
						text: 'Атрибуты',
						to: {name: routeNames.passport.attributes.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/helpback/responsibles',
			name: routeNames.helpback.responsibles.read,
			component: ResponsibleTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_READ_HELPBACK_RESPONSIBLES],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpBack',
						to: {name: routeNames.helpback.main},
						active: true,
					},
					{
						text: 'Ответственные',
						to: {name: routeNames.helpback.responsibles.read},
						active: true,
					},
				],
			},
		},
		{
			path: '/helpback/responsible/:responsible_id',
			name: routeNames.helpback.responsibles.update,
			component: ResponsibleUpdateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_UPDATE_HELPBACK_RESPONSIBLES],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpBack',
						to: {name: routeNames.helpback.main},
						active: true,
					},
					{
						text: 'Ответственные',
						to: {name: routeNames.helpback.responsibles.read},
					},
					{text: 'Обновление', active: true},
				],
			},
		},
		{
			path: '/helpback/responsible',
			name: routeNames.helpback.responsibles.create,
			component: ResponsibleCreateForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_CREATE_HELPBACK_RESPONSIBLES],
				requiresScopes: [scopeNames.HELPDESK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpBack',
						to: {name: routeNames.helpback.main},
						active: true,
					},
					{
						text: 'Ответственные',
						to: {name: routeNames.helpback.responsibles.read},
					},
					{text: 'Создание', active: true},
				],
			},
		},

		{
			path: '/docgen',
			name: routeNames.term_docgen.main,
			component: TerminationDocGenForm,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_USE_TERM_DOCGEN],
				requiresScopes: [scopeNames.TERM_DOCGEN],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'HelpDesk',
						to: {name: routeNames.helpdesk.main},
						active: true,
					},
					{text: 'Генерация договора расторжения', active: true},
				],
			},
		},

		{
			path: '/advertising-cabinet/contacts',
			name: routeNames.adv_cab.contacts.read,
			component: AdvContactsTable,
			meta: {
				requiresAuth: true,
				requiresAttribute: [attributeNames.ADV_CAB_ACCESS, attributeNames.ADV_CAB_ROLE],
				requiresScopes: [scopeNames.ADV_CAB],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{text: 'Контакты', active: true},
				],
			},
		},
		{
			path: '/advertising-cabinet/contact/:contact_id/deals',
			name: routeNames.adv_cab.deals.read,
			component: AdvDealsTable,
			meta: {
				requiresAuth: true,
				requiresAttribute: [attributeNames.ADV_CAB_ACCESS, attributeNames.ADV_CAB_ROLE],
				requiresScopes: [scopeNames.ADV_CAB],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{text: 'Контакты', href: '/advertising-cabinet/contacts'},
					{text: 'Сделки', active: true},
				],
			},
		},
		{
			path: '/advertising-cabinet/contact/:contact_id/deal/:deal_id',
			name: routeNames.adv_cab.deals.update,
			component: AdvDealsForm,
			meta: {
				requiresAuth: true,
				requiresAttribute: [attributeNames.ADV_CAB_ACCESS, attributeNames.ADV_CAB_ROLE],
				requiresScopes: [scopeNames.ADV_CAB],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{text: 'Контакты', href: '/advertising-cabinet/contacts'},
					{text: 'Сделка', href: '/advertising-cabinet/contact/:contact_id/deals', active: true}
				],
			},
		},
		{
			path: '/okk/files',
			name: routeNames.okk.files.read,
			component: FileTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_READ_OKK_FILES],
				requiresScopes: [scopeNames.OKK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'OKK',
						to: {name: routeNames.okk.main},
						active: true,
					},
					{text: 'Хранилище', active: true},
				],
			}
		},
		{
			path: '/okk/fines',
			name: routeNames.okk.fines.read,
			component: FinesTable,
			meta: {
				requiresAuth: true,
				requiresPermissions: [permissionNames.CAN_READ_OKK_FINES],
				requiresScopes: [scopeNames.OKK],
				breadcrumb: [
					{text: 'Домой', href: '/'},
					{
						text: 'OKK',
						to: {name: routeNames.okk.main},
						active: true,
					},
					{text: 'Штрафы', active: true},
				],
			}
		}
	],
});

// meta requiresAuth to block non auth users from route
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.getters.isAuthenticated && store.getters.canUseHelpdesk) {
			next();
			return;
		}
		localStorage.setItem('after_login', to.path);
		next('/login');
	} else {
		next();
	}
});

// scope-permission route check
router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth) {
		if (to.meta.requiresScopes) {
			for (let scope of to.meta.requiresScopes) {
				if (!store.getters.scopeAvailable(scope)) {
					next('/404');
					return;
				}
			}
		}
		if (to.meta.SUPermission) {
			if (store.getters.permissionAvailable(to.meta.SUPermission)) {
				next();
				return;
			}
		}
		if (to.meta.requiresOneOfPermissions) {
			for (let permission of to.meta.requiresOneOfPermissions) {
				if (store.getters.permissionAvailable(permission)) {
					next();
					return;
				}
			}
			next('/404');
			return;
		}
		if (to.meta.requiresPermissions) {
			for (let permission of to.meta.requiresPermissions) {
				if (!store.getters.permissionAvailable(permission)) {
					next('/404');
					return;
				}
			}
		}
		if (to.meta.requiresAttribute) {
			for (let attr of to.meta.requiresAttribute) {
				if (!store.getters.attributeAvailable(attr.name)) {
					next('/404');
					return;
				}
				if (!attr.values.includes(store.getters.attribute(attr.name))) {
					next('/404');
					return;
				}
			}
		}
	}
	next();
});

export default router;
