<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица подписантов
		</h5>
		<div class="form-desc" v-if="canUseHelpdeskSignersApi">
			Выберите нужного подписанта и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpdesk.signers.create }">Создать нового подписанта</router-link>
		</div>

		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация подписантов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="signers-city-id-filter-group" label="Закреплённый город"
															label-for="signers-city-id-filter">
									<b-form-select
											id="signers-city-id-filter"
											v-model="filters.city_id"
											:options="citiesOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group id="signers-entity-filter-group" label="Юр лицо" label-for="signers-entity-filter">
									<b-form-checkbox-group
											id="signers-entity-filter"
											v-model="filters.entity"
											:options="entityOptions"
									></b-form-checkbox-group>
								</b-form-group>

								<b-form-group id="signers-active-filter-group" label="Статус подписанта"
															label-for="signers-active-filter">
									<b-form-select
											id="signers-active-filter"
											v-model="filters.active"
											:options="statusOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-signers-get-limit"
									name="helpdesk-signers-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="applyFilters"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						ФИО
					</th>
					<th>
						Статус
					</th>
					<th>
						Город
					</th>
					<th v-if="canUseHelpdeskSignersApi">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="signer in helpdeskSigners" :key="signer.id">
				<tr>
					<td class="text-left">
						{{ signer.fio_nominative }}
					</td>
					<td class="text-left">
						{{ findSignerStatus(signer.active) }}
					</td>
					<td>
						{{ findSignerCity(signer.cities) }}
					</td>
					<td class="row-actions text-left" v-if="canUseHelpdeskSignersApi">
						<router-link v-b-tooltip.html.top title="Редактировать"
												 :to="{ name: routeNames.helpdesk.signers.update, params: { signer_id: signer.id }, query: filters} "><i
								class="os-icon os-icon-ui-49"></i></router-link>
						<a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(signer)" class="danger"><i
								class="os-icon os-icon-ui-15"></i></a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next"
							 v-if="canGetNext && helpdeskSigners !== null && helpdeskSigners.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import {helpdeskSignersStatusEnum} from '@/store/helpdesk/signers/constants';

export default {
	name: 'SignerTable',
	computed: {
		...mapGetters([
			'helpdeskCities', 'helpdeskSigners', 'canUseHelpdeskSignersApi'
		]),
	},
	data() {
		return {
			filters: {
				city_id: null,
				active: null,
				entity: [],
				limit: 1000,
				page: 1
			},
			limits: [
				{value: 1000, text: 'Подписантов на странице'},
				{value: 50, text: '50'},
				{value: 250, text: '250'},
				{value: 500, text: '500'},
				{value: 1000, text: '1000'}
			],
			citiesOptions: [
				{value: null, text: 'Город, к которому привязаны подписанты'},
			],
			statusOptions: [
				{value: null, text: 'Выберите статус подписантов'},
			],
			entityOptions: [
				{value: 'mpk', text: 'МПК'},
				{value: 'lt', text: 'LegalTech'},
			],
			canGetNext: true,
			canGetPrev: false,
			helpdeskSignersStatusEnum,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_CITIES_FROM_API', 'HELPDESK_GET_SIGNERS_FROM_API', 'HELPDESK_DELETE_SIGNER', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			//this.filters.limit=50;
			this.filters.page = 1;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getSigners();
		},

		showDeleteQuestion(signer) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить подписанта ' + signer.fio_nominative + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.HELPDESK_DELETE_SIGNER({id: signer.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Подписант успешно удалён'});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении подписанта'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		findSignerCity(cities) {
			switch (cities.length) {
			case 0:
				return 'Не назначен';
			case 1:
				for (let city of this.citiesOptions) {
					if (city.value === cities[0]) {
						return city.text;
					}
				}
				return 'Не найден';
			default:
				return 'Несколько';
			}
		},

		findSignerStatus(status) {
			let funded = this.statusOptions.find(x => x.value === status);
			if (funded === undefined) {
				return 'Неизвестный статус';
			}
			return funded.text;
		},

		async previous() {
			if (this.filters.page > 0) {
				this.filters.page--;
				await this.getSigners();
				this.canGetNext = true;
				if (this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getSigners();
			if (this.helpdeskSigners.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getSigners() {
			await this.HELPDESK_GET_SIGNERS_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка подписантов'
				});
			});
			if (this.helpdeskSigners === null) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'warning',
					title: 'Подписанты не найдены',
					message: 'Список подписантов, отправленный сервером, пустой'
				});
			}
			if (this.filters.page > 1) {
				this.canGetPrev = true;
			}
		},
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}
		await this.HELPDESK_GET_CITIES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка городов'
			});
		});
		for (let city of this.helpdeskCities) {
			this.citiesOptions.push({value: city.id, text: city.name});
		}
		for (let e in helpdeskSignersStatusEnum) {
			this.statusOptions.push(helpdeskSignersStatusEnum[e]);
		}
		await this.getSigners();
	},
};
</script>

<style>

</style>
