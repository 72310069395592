import axios from 'axios';
import {GET_PASSPORT_URL, IS_EXTERNAL, VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/passport/users/event-types';

export const actions = {
	async GET_USERS_FROM_API({commit}, {id, ids, login, name, disabled, scopes, limit, offset, attributes}) {
		let flt = '';

		let filter = {
			id: id,
			ids: ids,
			login: login,
			name: name,
			disabled: disabled,
			scopes: scopes,
			limit: limit,
			offset: offset,
		};
		if (filter.login !== null && filter.login !== undefined) {
			filter.login = `%${login}%`;
		}
		if (filter.name !== null && filter.name !== undefined) {
			filter.name = `%${name}%`;
		}
		for (let key in filter) {
			if (filter[key] === '' || filter[key] === undefined) {
				filter[key] = null;
			}
			if (filter[key] !== null) {
				flt += key.toString() + '=' + filter[key].toString() + '&';
			}
		}
		if (attributes) {
			attributes.forEach((a) => {
				flt += 'attributes[' + a.attribute.toString() + ']=' + a.value.toString() + '&';
			});
		}
		return axios.get(GET_PASSPORT_URL() + '/api/v1/user?' + encodeURI(flt)).then((resp) => {
			if (resp.data.users) {
				commit(events.PASSPORT_SET_USERS, resp.data.users);
				return resp.data.users;
			} else {
				commit(events.PASSPORT_SET_USERS, resp.data.user);
				return resp.data.user;
			}
		});
	},

	// not tested; not used
	async DELETE_USER({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/user',
			// 	{
			// 	url: GET_PASSPORT_URL() + '/api/v1/user?id=' + id,
			// 	external: IS_EXTERNAL()
			// }
		).then(() => {
			dispatch('GET_USERS_FROM_API', filters);
		});
	},

	async UPDATE_USER({commit}, {user}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/user', {
			url: GET_PASSPORT_URL() + '/api/v1/user',
			message: {user: user},
			external: IS_EXTERNAL()
		}).then((resp) => {
			commit(events.PASSPORT_SET_USERS, resp.data);
			return resp.data;
		});
	},

	async CREATE_USER({commit}, user) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/user', {
			url: GET_PASSPORT_URL() + '/api/v1/user',
			message: {user: user},
			external: IS_EXTERNAL()
		}).then((resp) => {
			commit(events.PASSPORT_SET_USERS, resp.data);
			return resp.data;
		});
	},
};
