import {permissionNames} from '@/store/passport/auth/constants';
import {scopeNames} from '@/store/passport/auth/constants';
import {VUE_APP_ADV_CAB_ROLE} from '@/config';
import { state } from './state';

export const getters = {
	isAuthenticated: state => !!(state.passport_token_data.access_token && state.passport_user_data.active === 1),
	getAccessToken: state => state.passport_token_data.access_token,
	getTokenData: state => state.passport_token_data,
	authStatus: state => state.status,
	userName: state => state.passport_user_data.user.fullName,

	permissionsAvailable: (state) => {
		return state.passport_user_data.user && state.passport_user_data.user.permissions.length > 0;
	},

	permissionAvailable: (state) => (permission) => {
		return state.passport_user_data.user.permissions.includes(permission);
	},

	attributeAvailable: (state) => (attribute) => {
		return attribute in state.passport_user_data.user.attributes;
	},

	attribute: (state) => (attribute) => {
		return state.passport_user_data.user.attributes[attribute];
	},

	scopeAvailable: (state) => (scope) => {
		return state.passport_user_data.scope.includes(scope);
	},

	advCabinetRole: (state) => {
		const role = localStorage.getItem('VUE_APP_ADV_CAB_ROLE');
		if (role) {
			return role;
		}
		if (!state.passport_user_data.user.attributes) {
			return '';
		}
		if (!(VUE_APP_ADV_CAB_ROLE in state.passport_user_data.user.attributes)) {
			return '';
		}
		return state.passport_user_data.user.attributes[VUE_APP_ADV_CAB_ROLE];
	},

	canUseHelpdesk: state => state.passport_user_data.scope.includes(scopeNames.HELPDESK),
	canUseHelpdeskBranchesApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_BRANCHES_API),
	canUseHelpdeskCitiesApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_CITIES_API),
	canUseHelpdeskPhonesApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_PHONES_API),
	canUseHelpdeskSignersApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_SIGNERS_API),
	canUseHelpdeskSiteInfoApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_SITE_INFO_API),
	canUseHelpdeskPrioritiesApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_PRIORITIES_API),
	canUseHelpdeskCustomSmsApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_CUSTOM_SMS_API),
	canUseHelpdeskVpnApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_VPN_API),
	canUseHelpdeskLeobotApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_LEOBOT_API),
	canUseHelpdeskDiscountApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_DISCOUNT),
	canUseHelpdeskTariffApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_TARIFFS_API),
	canUseHelpdeskChangeConsultation: state => state.passport_user_data.user.permission.includes(permissionNames.CAN_USE_HELPDESK_CHANGE_CONSULTATION),

	canUseHelpdeskWeekendsApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_WEEKENDS_API),
	canUseHelpdeskTerminalDeactivatorApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_TERMINAL_DEACTIVATOR),
	canUseHelpdeskClustersApi: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_USE_HELPDESK_CLUSTERS_API),

	canUsePassportApi: state => state.passport_user_data.scope.includes(scopeNames.PASSPORT),
	passportSU: state => state.passport_user_data.user.permissions.includes(permissionNames.PASSPORT_SU),

	canReadPassportScopes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_SCOPES),
	canCreatePassportScopes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_PASSPORT_SCOPES),
	canUpdatePassportScopes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_PASSPORT_SCOPES),
	canDeletePassportScopes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_DELETE_PASSPORT_SCOPES),

	canReadPassportUsers: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_USERS),
	canCreatePassportUsers: state => state.passport_user_data.user.permissions.includes(x => x === permissionNames.CAN_CREATE_PASSPORT_USERS),
	canUpdatePassportUsers: state => state.passport_user_data.user.permissions.includes(x => x === permissionNames.CAN_UPDATE_PASSPORT_USERS),
	canDeletePassportUsers: state => state.passport_user_data.user.permissions.includes(x => x === permissionNames.CAN_DELETE_PASSPORT_USERS),

	canReadPassportNamespaces: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_NAMESPACES),
	canCreatePassportNamespaces: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_PASSPORT_NAMESPACES),
	canUpdatePassportNamespaces: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_PASSPORT_NAMESPACES),
	canDeletePassportNamespaces: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_DELETE_PASSPORT_NAMESPACES),

	canReadPassportPermissions: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_PERMISSIONS),
	canCreatePassportPermissions: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_PASSPORT_PERMISSIONS),
	canUpdatePassportPermissions: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_PASSPORT_PERMISSIONS),
	canDeletePassportPermissions: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_DELETE_PASSPORT_PERMISSIONS),

	canReadPassportGroups: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_GROUPS),
	canCreatePassportGroups: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_PASSPORT_GROUPS),
	canUpdatePassportGroups: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_PASSPORT_GROUPS),
	canDeletePassportGroups: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_DELETE_PASSPORT_GROUPS),

	canReadPassportAttributes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_PASSPORT_ATTRIBUTES),
	canCreatePassportAttributes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_PASSPORT_ATTRIBUTES),
	canUpdatePassportAttributes: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_PASSPORT_ATTRIBUTES),
	canDeletePassportAttributes: state => state.passport_user_data.user.permissions.includes(x => x === permissionNames.CAN_DELETE_PASSPORT_ATTRIBUTES),

	canReadHelpbackResponsibles: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_READ_HELPBACK_RESPONSIBLES),
	canCreateHelpbackResponsibles: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_CREATE_HELPBACK_RESPONSIBLES),
	canUpdateHelpbackResponsibles: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_UPDATE_HELPBACK_RESPONSIBLES),
	canDeleteHelpbackResponsibles: state => state.passport_user_data.user.permissions.includes(permissionNames.CAN_DELETE_HELPBACK_RESPONSIBLES),
};
