import axios from 'axios';
import {VUE_APP_HELPBACK_URL} from '@/config';
import * as events from '@/store/helpback/cities/event-types';

export const actions = {
	async HELPBACK_GET_CITIES_DICT_FROM_API({ commit }) {
		return axios.get(VUE_APP_HELPBACK_URL+'/api/v1/dict/cities').then((resp) => {
			commit(events.HELPBACK_SET_CITIES_DICT, resp.data);
			return resp.data;
		});
	}
};