<template>
	<NavBar/>
</template>

<script>
import NavBar from '@/components/NavBar';
export default {
	name: 'App',
	components: {
		NavBar,
	},
	mounted() {
		const el = document.body;
		el.classList.add('menu-position-side');
		el.classList.add('menu-side-left');
		el.classList.add('full-screen');
		el.classList.add('with-content-panel');
	}
};
</script>

<style>

</style>
