import * as events from '@/store/helpback/responsible/event-types';

export const mutations = {
	[events.HELPBACK_SET_RESPONSIBLE](state, resp) {
		state.helpback_responsible = resp;
	},
	[events.HELPBACK_SET_ALL_RESPONSIBLE](state, resp) {
		state.helpback_all_resp = resp;
	},
	[events.HELPBACK_SET_ALL_USERS](state, resp) {
		state.helpback_all_users = resp;
	},
};