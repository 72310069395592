import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/siteinfo/event-types';

function siteInfoToFormData(siteInfo) {
	let formData = new FormData();
	// Append any plain string values to the request
	formData.append('license', siteInfo.license);
	formData.append('amount_clients', siteInfo.amount_clients);
	formData.append('amount_coworkers', siteInfo.amount_coworkers);
	formData.append('copyright', siteInfo.copyright);
	return formData;
}

export const actions = {
	async HELPDESK_GET_SITE_INFO_FROM_API({commit}) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/info').then((resp) => {
			commit(events.HELPDESK_SET_SITE_INFO, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_UPDATE_SITE_INFO({commit}, site_info) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/info', siteInfoToFormData(site_info), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_SITE_INFO, resp.data);
			return resp.data;
		});
	}
};