import * as events from '@/store/helpdesk/cities/event-types';

export const mutations = {
	[events.HELPDESK_SET_CITIES](state, cities) {
		state.helpdesk_cities = cities;
	},
	[events.HELPDESK_NEW_SET_CITIES](state, cities) {
		state.helpdesk_cities_new = cities;
	},
	[events.HELPDESK_SET_CITY](state, city) {
		state.helpdesk_city = city;
	}
};
