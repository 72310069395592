import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_STATUSES() {
	return axios.get(VUE_APP_ADV_CAB_URL + '/api/v1/statuses').then((resp) => {
		return {statuses: resp.data.statuses, error: null};
	}).catch((err) => {
		return {statuses: [], error: err};
	});
}

export function GET_ALL_STATUSES() {
	return axios.get(VUE_APP_ADV_CAB_URL + '/api/v1/all_statuses').then((resp) => {
		return {statuses: resp.data.statuses, error: null};
	}).catch((err) => {
		return {statuses: [], error: err};
	});
}

export function GET_MANAGERS() {
	return axios.get(VUE_APP_ADV_CAB_URL + '/api/v1/managers').then((resp) => {
		return {managers: resp.data.managers, error: null};
	}).catch((err) => {
		return {managers: [], error: err};
	});
}

