<template>
    <div class="element-box">
        <h5 class="form-header">
            Таблица неймспейсов
        </h5>
        <div class="form-desc">
            Выберите нужные неймспейсы и нажмите на кнопку действия<br>
            <router-link :to="{ name: routeNames.passport.namespaces.create }">Создать новый неймспейс</router-link>
        </div>

        <div class="table-responsive" style>
            <div class="controls-above-table">
                <div class="row">
                    <div class="col-sm-6">
                        <a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
                        <b-modal
                                id="filters-modal"
                                title="Фильтрация неймспейсов"
                                ok-title="Применить"
                                cancel-title="Отмена"
                                v-on:ok="applyFilters"
                        >
                            <b-form>
                                <b-form-group id="namespaces-id-filter-group" label="ID"
                                              label-for="namespaces-id-filter">
                                    <b-form-input
                                            id="namespaces-id-filter"
                                            v-model="filters.id"
                                            placeholder="Введите искомый id"
                                    ></b-form-input>
                                </b-form-group>
                            </b-form>
                        </b-modal>
                    </div>

                    <div class="col-sm-6">
                        <form class="form-inline justify-content-sm-end">
                            <b-form-select
                                    id="passport-namespaces-get-limit"
                                    name="passport-namespaces-get-limit"
                                    v-model="filters.limit"
                                    :options="limits"
                                    class="form-control form-control-sm rounded bright"
                                    @change="GET_NAMESPACES_FROM_API(filters)"
                            ></b-form-select>
                        </form>
                    </div>
                </div>
            </div>

            <table class="table table-lightborder">
                <thead>
                <tr>
                    <th>
                        Имя
                    </th>
                    <th>
                        Код
                    </th>
                    <th class="text-right">
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody v-for="namespace in passportNamespaces" :key="namespace.id">
                <tr>
                    <td>
                        {{ namespace.name }}
                    </td>
                    <td>
                        {{ namespace.code }}
                    </td>

                    <td class="row-actions text-right">
                        <router-link v-if="canUpdatePassportNamespaces || passportSU" v-b-tooltip.html.top
                                     title="Редактировать"
                                     :to="{ name: routeNames.passport.namespaces.update, params: { namespace_id: namespace.id }} ">
                            <i class="os-icon os-icon-ui-49"></i>
                        </router-link>

                        <a v-if="canDeletePassportNamespaces || passportSU" v-b-tooltip.html.top title="Удалить"
                           @click="showDeleteQuestion(namespace)" class="danger">
                            <i class="os-icon os-icon-ui-15"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="controls-below-table">
            <div class="table-records-info">
            </div>
            <div class="table-records-pages">
                <ul>
                    <li>
                        <a href="#" @click="previous" v-if="canGetPrev">Назад</a>
                    </li>
                    <li>
                        <a href="#" @click="next"
                           v-if="canGetNext && passportNamespaces.length === filters.limit">Далее</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'NamespaceTable',
	data() {
		return {
			filters: {
				id: null,
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Неймспейсов на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],
			deleteCheck: false,
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	computed: {
		...mapGetters([
			'passportNamespaces', 'canUpdatePassportNamespaces', 'canDeletePassportNamespaces',
			'passportSU'
		]),
	},
	methods: {
		...mapActions([
			'GET_NAMESPACES_FROM_API', 'DELETE_NAMESPACE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getNamespaces();
		},

		showDeleteQuestion(namespace) {
			this.deleteCheck = false;
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить неймспейс ' + namespace.name + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.DELETE_NAMESPACE({id: namespace.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'success',
							title: 'Успех',
							message: 'Неймспейс успешно удалён'
						});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении неймспейса'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getNamespaces();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getNamespaces();
			if (this.passportNamespaces.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getNamespaces() {
			await this.GET_NAMESPACES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка неймспейсов'
				});
			});
		},
	},

	async mounted() {
		await this.getNamespaces();
	},
};
</script>

<style>

</style>
