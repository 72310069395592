import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import {BootstrapVue, TooltipPlugin, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './styles/main.scss';
import './main.css';
import Vuelidate from 'vuelidate';
import VueVideoPlayer from 'vue-video-player';

import 'video.js/dist/video-js.css';
Vue.use(VueVideoPlayer, /* {
	options: global default options,
	events: global videojs events
} */);

Vue.use(Vuelidate);
// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(TooltipPlugin);
Vue.use(IconsPlugin);

const moment = require('moment');
require('moment/locale/ru');
Vue.use(require('vue-moment'), {
	moment
});

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

Vue.use(VueViewer);

Vue.config.productionTip = false;

let isRefreshing = false;
let failedQueue = [];

const subscribeTokenRefresh = (cb) => {
    failedQueue.push(cb);
}

const onRefreshed = (token) => {
    failedQueue.forEach(cb => cb(token));
    failedQueue = [];
}

axios.interceptors.response.use(response => {
	return response;
}, error => {
	const { config, response } = error;
	const originalRequest = config;
	if (response.status === 401 && originalRequest.url.includes("login/oauth/token")) {
		store.dispatch('logout');
		router.push("/login");
		return Promise.reject(error);
	} else if (response.status === 401 && !originalRequest._retry) {
		if (!isRefreshing) {
			isRefreshing = true;
			return store.dispatch('refresh')
				.then(() => {
					const token = store.getters.getAccessToken;
					originalRequest.headers['Authorization'] = `Bearer ${token}`;
					isRefreshing = false;
					onRefreshed(token);
					return Promise.all(failedQueue.map(cb => cb(token))).then(() => {
						return axios(originalRequest);
					});
				})
				.catch(error => {
					isRefreshing = false;
					return Promise.reject(error);
				})
				.finally(() => failedQueue = []);
		}

		originalRequest._retry = true;
		// await execution of the store async action before
		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(token => {
				originalRequest.headers['Authorization'] = `Bearer ${token}`;
				resolve(axios(originalRequest));
			});
		});
	}
	return Promise.reject(error);
});

// axios.interceptors.response.use((response) => {
// 	return response;
// }, async function (error) {
// 	if (error) {
// 		if (error.response) {
// 			// The request was made and the server responded with a status code
// 			// that falls out of the range of 2xx
// 			const originalRequest = error.config;
// 			if (error.response.status === 401 || error.response.status === 403 && !originalRequest._retry) {
// 				originalRequest._retry = true;
// 				await store.dispatch('refresh').catch(() => {
// 					store.dispatch('logout');
// 				});
// 				await store.dispatch('introspect').catch(() => {
// 					store.dispatch('logout');
// 				});
// 				if (!store.getters.isAuthenticated) {
// 					await store.dispatch('logout');
// 					return;
// 				}
// 				axios.defaults.headers.common['Authorization'] = store.getters.getAccessToken;
// 				return axios(originalRequest);
// 			}
// 		}
// 	}
// 	return Promise.reject(error);
// });

axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		// use getters to retrieve the access token from vuex
		// store
		const token = store.getters.getAccessToken;
		if (token) {
			config.headers = {
				'Authorization': `Bearer ${store.getters.getAccessToken}`,
			}
		}
		return config;
	}, function (error) {
		// Do something with request error
		return Promise.reject(error);
});

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app');
