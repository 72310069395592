import * as events from '@/store/helpdesk/staff/event-types';

export const mutations = {
	[events.HELPDESK_SET_STAFF_SCHEDULE] (state, schedule) {
		state.staff_schedule = schedule;
	},
	[events.HELPDESK_SET_STAFF_OFFICES] (state, offices) {
		state.staff_offices = offices;
	},
	[events.HELPDESK_SET_STAFF_CLUSTERS] (state, clusters) {
		state.staff_clusters = clusters;
	},
};