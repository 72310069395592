<template>
  <div class="element-box">
    <h5 class="form-header">
      Таблица тарифов
    </h5>
    <div class="form-desc" v-if="canUseHelpdeskTariffApi">
      Выберите нужный тариф и нажмите на кнопку действия<br>
      <router-link :to="{ name: routeNames.helpdesk.tariffs.create }">Создать новый тариф</router-link>
    </div>
    <div class="table-responsive" style>
      <div class="controls-above-table">
        <div class="row">
          <div class="col-sm-6">
            <a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
            <b-modal
                id="filters-modal"
                title="Фильтрация тарифов"
                ok-title="Применить"
                cancel-title="Отмена"
                v-on:ok="applyFilters"
            >
              <b-form>
                <b-form-group id="tariff-name-filter-group" label="Название тарифа" label-for="tariff-name-filter">
                  <b-form-input
                      id="tariff-name-filter"
                      v-model="filters.name"
                      placeholder="Введите название"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-modal>
          </div>
          <div class="col-sm-6">
            <form class="form-inline justify-content-sm-end">
              <b-form-select
                  id="helpdesk-branches-get-limit"
                  name="helpdesk-branches-get-limit"
                  v-model="filters.limit"
                  :options="limits"
                  class="form-control form-control-sm rounded bright"
                  @change="HELPDESK_GET_TARIFFS_FROM_API(filters)"
              ></b-form-select>
            </form>
          </div>
        </div>
      </div>

      <table class="table table-lightborder">
        <thead>
        <tr>
          <th>
            Название
          </th>
          <th>
            Описание
          </th>
          <th v-if="canUseHelpdeskTariffApi">
            Действия
          </th>
        </tr>
        </thead>
        <tbody v-for="tariff in helpdeskTariffs" :key="tariff.id">
        <tr>
          <td>
            {{ tariff.name }}
          </td>
          <td>
            {{ tariff.description }}
          </td>
          <td class="row-actions text-left" v-if="canUseHelpdeskTariffApi">
            <router-link v-b-tooltip.html.top title="Редактировать" :to="{ name: routeNames.helpdesk.tariffs.update, params: { tariff_id: tariff.id }} ">
              <i class="os-icon os-icon-ui-49"></i>
            </router-link>
            <a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(tariff)" class="danger">
              <i class="os-icon os-icon-ui-15"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
  name: 'TariffTable',
  computed: {
    ...mapGetters([
      'helpdeskTariffs', 'canUseHelpdeskTariffApi'
    ]),
  },
  data() {
    return {
      filters: {
        name: null,
        limit: 50,
        page: 1
      },
      limits: [
        { value: 50, text: 'Тарифов на странице' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 250, text: '250' }
      ],
      tariffs: [],
      canGetNext: true,
      canGetPrev: false,
      routeNames
    }
  },
  methods: {
    ...mapActions([
      'HELPDESK_GET_TARIFFS_FROM_API', 'HELPDESK_DELETE_TARIFF', 'ADD_NOTIFICATION_TO_QUERY'
    ]),

    async applyFilters() {
      this.filters.limit=50;
      this.filters.page=1;
      this.canGetNext=true;
      this.canGetPrev=false;
      await this.getDiscounts();
    },

    async previous() {
      if(this.filters.page > 0) {
        this.filters.page--;
        await this.getDiscounts();
        this.canGetNext = true;
        if(this.filters.page === 1) {
          this.canGetPrev = false;
        }
      }
    },

    async next() {
      this.canGetPrev = true;
      this.filters.page++;
      await this.getDiscounts();
      if(this.discounts.length < this.filters.limit) {
        this.canGetNext = false;
      }
    },

    showDeleteQuestion(tariff) {
      this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить тариф '+tariff.name+'?', {
        title: 'Подтвердите действие',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if(value === true) {
          this.HELPDESK_DELETE_TARIFF({ id: tariff.id, filters: this.filters }).then(() => {
            this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Тариф успешно удален' });
          }).catch(() => {
            this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении тарифа' });
          });
        }
      }).catch(() => {
        this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
      });
    },

    async getTariffs() {
      await this.HELPDESK_GET_TARIFFS_FROM_API(this.filters).catch(() => {
        this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка тарифов' });
      });
      for (const tariff in this.helpdeskTariffs) {
        this.tariffs.push(tariff);
      }
    },
  },
  async mounted() {
    await this.getTariffs();
  },
}
</script>

<style>

</style>