<template>
  <div class="element-box">
    <h5 class="form-header">
      Таблица телефонов
    </h5>
    <div class="form-desc" v-if="canUseHelpdeskPhonesApi">
      Выберите нужный телефон и нажмите на кнопку действия<br>
      <router-link :to="{ name: routeNames.helpdesk.phones.create }">Создать новый телефон</router-link>
    </div>

    <div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация телефонов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="phones-branch-id-filter-group" label="Привязанный филиал" label-for="phones-branch-id-filter">
									<b-form-select
											id="phones-branch-id-filter"
											v-model="filters.branch_id"
											:options="branchesOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group id="phones-domain-filter-group" label="Домен телефона" label-for="phones-domain-filter">
									<b-form-input
											id="phones-domain-filter"
											v-model="filters.domain"
											placeholder="Введите домен, например priziva.net"
									></b-form-input>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPDESK_GET_PHONES_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

      <table class="table table-lightborder">
        <thead>
        <tr>
          <th>
            Филиал
          </th>
          <th>
            Домен
          </th>
          <th>
            Телефон
          </th>
          <th v-if="canUseHelpdeskPhonesApi">
            Действия
          </th>
        </tr>
        </thead>
        <tbody v-for="phone in helpdeskPhones" :key="phone.id">
        <tr>
          <td v-if="phone.branch_id !== null && phone.branch_id !== undefined">
						<router-link title="Редактировать"
						:to="{ name: routeNames.helpdesk.branches.update, params: { branch_id: phone.branch_id }}"
						>{{ findPhoneBranch(phone.branch_id) }}</router-link>
          </td>
          <td v-else>
            {{ findPhoneBranch() }}
          </td>
          <td v-if="phone.domain !== null && phone.domain !== '' && phone.domain !== 'default'">
						<a @click="openDomain(phone.domain)">{{ phone.domain }}</a>
          </td>
					<td v-else>
						{{ findPhoneBranch() }}
					</td>
          <td class="text-left">
            {{ phone.phone }}
          </td>
          <td class="row-actions text-left" v-if="canUseHelpdeskPhonesApi">
            <router-link v-b-tooltip.html.top title="Редактировать"
                         :to="{ name: routeNames.helpdesk.phones.update, params: { phone_id: phone.id }} "><i
                class="os-icon os-icon-ui-49"></i></router-link>
            <a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(phone)" class="danger"><i
                class="os-icon os-icon-ui-15"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskPhones.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'PhoneTable',
	computed: {
		...mapGetters([
			'helpdeskBranches', 'helpdeskPhones', 'canUseHelpdeskPhonesApi'
		]),
	},
	data() {
		return {
			filters: {
				branch_id: null,
				domain: null,
				limit: 50,
				page: 1
			},
			limits: [
				{ value: 50, text: 'Телефонов на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			branchesOptions: [
				{ value: null, text: 'Филиал, телефоны которого вы хотите получить' },
			],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_BRANCHES_FROM_API', 'HELPDESK_GET_PHONES_FROM_API', 'HELPDESK_DELETE_PHONE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit=50;
			this.filters.page=1;
			this.canGetNext=true;
			this.canGetPrev=false;
			await this.getPhones();
		},

		showDeleteQuestion(phone) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить телефон '+phone.phone+'?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if(value === true) {
					this.HELPDESK_DELETE_PHONE({ id: phone.id, filters: this.filters }).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Телефон успешно удалён' });
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении телефона' });
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
			});
		},

		findPhoneBranch(branch_id) {
			if(branch_id !== null && branch_id !== undefined) {
				for(let branch of this.branchesOptions) {
					if(branch.value === branch_id) {
						return branch.text;
					}
				}
			}
			return 'Не назначен';
		},

		openDomain(domain) {
			window.open('https://' + domain).focus();
		},

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getPhones();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getPhones();
			if(this.helpdeskBranches.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getPhones() {
			await this.HELPDESK_GET_PHONES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка телефонов' });
			});
		},
	},

	async mounted() {
		await this.HELPDESK_GET_BRANCHES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка филиалов' });
		});
		for(let branch of this.helpdeskBranches) {
			this.branchesOptions.push({value: branch.id, text: branch.name});
		}
		await this.getPhones();
	},
};
</script>

<style>

</style>
