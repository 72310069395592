import axios from 'axios';
import {GET_PASSPORT_URL, GET_PASSPORT_SECRET} from '@/config';
import {VUE_APP_HELPDESK_URL} from '@/config';
import * as events from '@/store/passport/auth/event-types';
import generator from 'generate-password';

const getSHA256Hash = async (input) => {
	const textAsBuffer = new TextEncoder().encode(input);
	const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hash = hashArray
		.map((item) => item.toString(16).padStart(2, '0'))
		.join('');
	return hash;
};

export const actions = {
	async login({commit}, code) {
		commit(events.AUTH_STARTED);
		if (code === '') {
			const codeVerifier = generator.generate({
				length: 16,
				numbers: true,
				excludeSimilarCharacters: true
			});
			const codeChallenge = await getSHA256Hash(codeVerifier);
			console.log(codeVerifier);
			console.log(codeChallenge);
			localStorage.setItem('codeVerifier', codeVerifier);
			localStorage.setItem('codeChallenge', codeChallenge);
			window.location.replace(GET_PASSPORT_URL() +
				'/login/oauth/authorize?client_id=helpdesk&response_type=code&redirect_uri=' +
				encodeURIComponent(process.env.VUE_APP_HELPDESK_URL + '/login') +
				'&scope=' + encodeURIComponent(`helpdesk passport_api term_docgen okk${!GET_PASSPORT_URL().includes('external') ? ' adv_cabinet' : ''}`) +
				'&code_challenge=' + encodeURIComponent(codeChallenge) +
				'&code_challenge_method=sha256');
			return;
		}
		// if code presented
		const codeVerifier = localStorage.getItem('codeVerifier');
		localStorage.removeItem('codeVerifier');
		localStorage.removeItem('codeChallenge');
		return axios.post(GET_PASSPORT_URL() + '/login/oauth/token', {
			client_id: 'helpdesk',
			grant_type: 'authorization_code',
			code: code,
			redirect_uri: VUE_APP_HELPDESK_URL + '/login',
			code_verifier: codeVerifier,
			// client_secret: GET_PASSPORT_SECRET(),
		}).then(resp => {
			// got access and refresh tokens
			commit(events.AUTH_SUCCESS, resp.data);
		}).catch(() => {
			commit(events.LOGOUT);
		});
	},

	async introspect({state, commit}) {
		return axios.post(GET_PASSPORT_URL() + '/login/oauth/introspect', {
			token: state.passport_token_data.access_token
		}).then(resp => {
			commit(events.INTROSPECT_SUCCESS, resp.data);
		}).catch(() => {
			commit(events.LOGOUT);
		});
	},

	async logout({commit}) {
		commit(events.LOGOUT);
	},

	async refresh({state, commit, dispatch}) {
		let resp = await axios.create().post(GET_PASSPORT_URL() + '/login/oauth/refresh', {
			client_id: 'helpdesk',
			grant_type: 'refresh_token',
			refresh_token: state.passport_token_data.refresh_token,
			client_secret: GET_PASSPORT_SECRET(),
		}).catch(() => {
			commit(events.LOGOUT);
		});
		if (resp.status !== 200) {
			commit(events.LOGOUT);
			return;
		}
		commit(events.AUTH_SUCCESS, resp.data);
	},
};
