<template>
  <div class="element-box">
    <b-form @submit.stop.prevent="submit">
      <h5 class="form-header">
        Создание ответственного
      </h5>
      <div class="form-desc">
        Заполните необходимые данные и нажмите сохранить
      </div>

      <b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
      >
        <div>
          Имя отвествтенного {{ responsible.name }}<br>
					<div v-if="userOptions.length > 0 && responsible.amo_user_id !== null">
						Пользователь AMO: {{ findUser() }}<br>
					</div>
					Активен: {{ !Boolean(responsible.disabled) }}<br>
					Воронка продаж: {{ responsible.pipeline }}<br>
					<div v-if="responsible.pipeline === pipelineEnum.office.value">
						<div v-if="cityOptions.length > 0 && responsible.amo_city_id !== null">
							Город AMO: {{ findCity() }}<br>
						</div>
					</div>
					<div v-if="responsible.pipeline === pipelineEnum.mlm.value">
						ID пользователя Bitrix: {{ responsible.bitrix_user_id }}<br>
					</div>
					<div v-if="responsible.pipeline === pipelineEnum.dist.value">
						Distribute_cc: {{ responsible.distribute_cc }}<br>
					</div>
        </div>
      </b-modal>

      <b-form-group id="name-input-group" label="Имя ответственного" label-for="responsible-name-input">
        <b-form-input
            id="responsible-name-input"
            v-model="responsible.name"
            :state="nameState"
            aria-describedby="name-input-responsible-feedback"
            placeholder="Введите имя ответственного"
        ></b-form-input>
        <b-form-invalid-feedback
            id="name-input-responsible-feedback"
        >Обязательное поле.
        </b-form-invalid-feedback>
      </b-form-group>

			<b-form-group id="amo-user-id-input-group" label="Пользователь AMO" label-for="responsible-amo-user-id-input">
				<b-form-select
						id="responsible-amo-user-id-input"
						v-model="responsible.amo_user_id"
						:options="userOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="disabled-input-group" label="Активен" label-for="responsible-disabled-input">
				<b-form-checkbox
						id="responsible-disabled-input"
						v-model="responsible.disabled"
						value="0"
						unchecked-value="1"
				></b-form-checkbox>
			</b-form-group>

      <b-form-group id="pipeline-input-group" label="Выбор воронки продаж" label-for="responsible-pipeline-input">
        <b-form-select
            id="responsible-pipeline-input"
            v-model="responsible.pipeline"
						:state="pipelineState"
            :options="pipelineOptions"
						@change="clearResponsibleVars"
						aria-describedby="pipeline-input-responsible-feedback"
        ></b-form-select>
				<b-form-invalid-feedback
						id="pipeline-input-responsible-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
      </b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.office.value" id="amo-city-id-input-group" label="ID города AMO" label-for="responsible-amo-city-id-input">
				<b-form-select
						id="responsible-amo-city-id-input"
						v-model="responsible.amo_city_id"
						:state="amoCityIdState"
						aria-describedby="amo-city-id-input-responsible-feedback"
						:options="cityOptions"
				></b-form-select>
				<b-form-invalid-feedback
						id="amo-city-id-input-responsible-feedback"
				>Обязательное поле.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.mlm.value" id="bitrix-user-id-input-group" label="ID пользователя Bitrix" label-for="responsible-bitrix-user-id-input">
				<b-form-input
						id="responsible-bitrix-user-id-input"
						v-model="responsible.bitrix_user_id"
						:state="bitrixUserIdState"
						aria-describedby="bitrix-user-id-input-responsible-feedback"
						placeholder="Введите id пользователя Bitrix"
				></b-form-input>
				<b-form-invalid-feedback
						id="bitrix-user-id-input-responsible-feedback"
				>Обязательное поле. Должно состоять только из цифр больше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.dist.value" id="international-input-group" label="Снятие с учета - Международный" label-for="responsible-international-input">
				<b-form-checkbox
						id="responsible-international-input"
						v-model="responsible.international"
						value="1"
						unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group v-if="responsible.pipeline === pipelineEnum.dist.value" id="distribute-cc-input-group" label="Флаг распределения сделок из Колл-Центра" label-for="responsible-distribute-cc-input">
				<b-form-checkbox
						id="responsible-distribute-cc-input"
						v-model="responsible.distribute_cc"
						value="1"
						unchecked-value="0"
				></b-form-checkbox>
			</b-form-group>

			<b-form-group id="coeff-input-group" label="Коэффициент распределения" label-for="responsible-coeff-input">
				<b-input-group>
					<b-input-group-prepend>
						<b-btn
								variant="outline-info"
								@click="decrement"
						>-</b-btn>
					</b-input-group-prepend>

					<b-form-input
							id="responsible-coeff-input"
							v-model="responsible.coeff"
							type="number"
							:state="coeffState"
					></b-form-input>

					<b-input-group-append>
						<b-btn
								variant="outline-secondary"
								@click="increment"
						>+</b-btn>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
      <div class="form-buttons-w">
        <b-button :disabled="!valid" class="btn btn-primary" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
        </b-button>
        <b-button class="btn btn-grey" @click="backToResponsiblesTable()">Отмена</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pipelineEnum} from '@/store/helpback/responsible/constants';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpBackResponsibleCreateForm',
	data() {
		return {
			responsible: {
				name: null,
				amo_user_id: 0,
				disabled: 0,
				international: 0,
				coeff: 1,
				pipeline: null,
				amo_city_id: 0,
				bitrix_user_id: 0,
				distribute_cc: 0
			},
			pipelineOptions: [
				{ value: null, text: 'Выберите воронку продаж' }
			],
			userOptions: [
				{ value: null, text: 'Выберите пользователя AMO' }
			],
			cityOptions: [
				{ value: null, text: 'Выберите город AMO' }
			],
			valid: false,
			pipelineEnum
		};
	},
	watch: {
		'responsible': {
			deep: true,
			handler() {
				this.checkFields();
			}
		},
	},

	computed: {
		...mapGetters([
			'helpbackUsersDict', 'helpbackCitiesDict'
		]),

		nameState() {
			return this.responsible.name !== null && this.responsible.name !== '';
		},
		coeffState() {
			return (this.isInt(this.responsible.coeff) || this.isFloat(this.responsible.coeff)) && this.responsible.coeff >= 0.5;
		},
		pipelineState() {
			return this.responsible.pipeline !== null;
		},
		amoCityIdState() {
			return !(this.responsible.pipeline === 'office' && this.responsible.amo_city_id <= 0);
		},
		bitrixUserIdState() {
			return !(this.responsible.pipeline === 'mlm' && (this.responsible.bitrix_user_id === null || this.responsible.bitrix_user_id === ''));
		}
	},

	methods: {
		...mapActions([
			'HELPBACK_CREATE_RESPONSIBLE', 'HELPBACK_GET_USERS_DICT_FROM_API', 'HELPBACK_GET_CITIES_DICT_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		checkFields() {
			this.valid = this.nameState && this.coeffState && this.pipelineState && this.amoCityIdState
					&& this.bitrixUserIdState;
		},

		isInt(n){
			return Number(n) === n && n % 1 === 0;
		},

		isFloat(n){
			return Number(n) === n && n % 1 !== 0;
		},

		increment() {
			if (this.responsible.coeff <= 0.5) {
				this.responsible.coeff = 0.5;
			}
			this.responsible.coeff += 0.5;
		},
		decrement() {
			if (this.responsible.coeff <= 0.5) {
				this.responsible.coeff = 0.5;
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Коэффициент распределения должен быть больше 0.5' });
			} else {
				this.responsible.coeff -= 0.5;
			}
		},

		backToResponsiblesTable() {
			this.$router.push({name: routeNames.helpback.responsibles.read});
		},

		clearResponsibleVars() {
			this.responsible.amo_city_id = null;
			this.responsible.bitrix_user_id = null;
		},

		findUser() {
			let user = this.userOptions.find(x => x.value === this.responsible.amo_user_id);
			if(user !== undefined) {
				return user.text;
			}
			return 'Ошибка';
		},

		findCity() {
			let city = this.cityOptions.find(x => x.value === this.responsible.amo_city_id);
			if(city !== undefined) {
				return city.text;
			}
			return 'Ошибка';
		},

		async submit() {
			this.responsible.coeff = this.responsible.coeff * 2;
			await this.HELPBACK_CREATE_RESPONSIBLE(this.responsible).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Ответственный успешно создан' });
				this.backToResponsiblesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании ответственного' });
				this.responsible.coeff = this.responsible.coeff / 2;
			});
		}
	},

	async mounted() {
		await this.HELPBACK_GET_USERS_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка пользователей' });
		});
		for(let user of this.helpbackUsersDict) {
			this.userOptions.push({ value: user.id, text: user.name + ' - ' + user.login });
		}

		await this.HELPBACK_GET_CITIES_DICT_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка городов' });
		});
		for(let city of this.helpbackCitiesDict) {
			this.cityOptions.push({ value: city.id, text: city.name });
		}

		for (let key in pipelineEnum) {
			this.pipelineOptions.push(pipelineEnum[key]);
		}
	},
};
</script>

<style>

</style>
