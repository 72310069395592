<template>
	<div class="element-box">
		<h5 class="form-header">
			Таблица sms шаблонов
		</h5>
		<div class="form-desc" v-if="canUseHelpdeskCustomSmsApi">
			Выберите нужный шаблон и нажмите на кнопку действия<br>
			<router-link :to="{ name: routeNames.helpdesk.sms.create }">Создать новый шаблон</router-link>
		</div>

		<div class="table-responsive" style>
			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Сервис
					</th>
					<th>
						Название
					</th>
					<th>
						Шаблон
					</th>
					<th v-if="canUseHelpdeskCustomSmsApi">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="template in helpdeskSmsTemplates" :key="template.id">
				<tr>
					<td>
						{{ template.service }}
					</td>
					<td>
						{{ template.name }}
					</td>
					<td class="text-left">
						{{ template.text }}
					</td>
					<td class="row-actions text-left" v-if="canUseHelpdeskCustomSmsApi">
						<router-link v-b-tooltip.html.top title="Редактировать"
												 :to="{ name: routeNames.helpdesk.sms.update, params: { template_id: template.id }} "><i
								class="os-icon os-icon-ui-49"></i></router-link>
						<a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(template)" class="danger"><i
								class="os-icon os-icon-ui-15"></i></a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskSmsTemplates.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpdeskTemplateTable',
	computed: {
		...mapGetters([
			'helpdeskSmsTemplates', 'canUseHelpdeskCustomSmsApi'
		]),
	},
	data() {
		return {
			filters: {
				limit: 50,
				page: 1
			},
			limits: [
				{value: 50, text: 'Шаблонов на странице'},
				{value: 10, text: '10'},
				{value: 25, text: '25'},
				{value: 50, text: '50'},
				{value: 100, text: '100'},
				{value: 250, text: '250'}
			],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_SMS_TEMPLATES_FROM_API', 'HELPDESK_DELETE_TEMPLATE', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 50;
			this.filters.page = 1;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getTemplates();
		},

		showDeleteQuestion(template) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить шаблон ' + template.id + '?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.HELPDESK_DELETE_TEMPLATE({id: template.id, filters: this.filters}).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Шаблон успешно удалён'});
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({
							type: 'error',
							title: 'Ошибка',
							message: 'Произошла ошибка при удалении шаблона'
						});
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании диалогового окна'
				});
			});
		},

		async previous() {
			if (this.filters.page > 0) {
				this.filters.page--;
				await this.getTemplates();
				this.canGetNext = true;
				if (this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getTemplates();
			if (this.helpdeskSmsTemplates.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getTemplates() {
			await this.HELPDESK_GET_SMS_TEMPLATES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка шаблонов'
				});
			});
		},
	},

	async mounted() {
		await this.getTemplates();
	},
};
</script>

<style>

</style>
