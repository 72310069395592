import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/branches/event-types';
import {HELPDESK_SET_BFL_RESP} from '@/store/helpdesk/branches/event-types';

function branchToFormData(
	{
		old_city_id,
		bfl_curator,
		project,
		amo_id,
		bfl_amo_id,
		amo_group_id,
		amo_group_name,
		name,
		status,
		city_id,
		date_open,
		work_hours,
		address,
		address_comment,
		address_sms,
		entity,
		first_day_pay,
		map_code,
		show_on_sites,
		status_coven,
		time_offset,
		image,
		preview,
		tariff1,
		tariff2,
		tariff3,
		yandex_map_url,
		// latitude,
		// longitude,
		entrance_photo_url,
		settings,
	}
) {
	let formData = new FormData();
	for (let i = 0; i < project.length; i++) {
		formData.append('project', project[i]);
	}
	for (let i = 0; i < bfl_curator.length; i++) {
		formData.append('bfl_curator', bfl_curator[i]);
	}
	if (amo_id !== '' && amo_id != null) {
		formData.append('amo_id', amo_id);
	}
	if (bfl_amo_id !== '' && bfl_amo_id != null) {
		formData.append('bfl_amo_id', bfl_amo_id);
	}
	if (amo_group_id !== '' && amo_group_id != null) {
		formData.append('amo_group_id', amo_group_id);
	}
	if (amo_group_name !== '' && amo_group_name != null) {
		formData.append('amo_group_name', amo_group_name);
	}
	formData.append('name', name);
	formData.append('status', status);
	if (city_id !== null && city_id !== '') {
		formData.append('city_id', city_id);
	}
	if (old_city_id !== null && old_city_id !== '') {
		formData.append('old_city_id', old_city_id);
	}
	//console.log(new Date(date_open.getTime() - (date_open.getTimezoneOffset() * 60000)).toISOString());
	if (date_open !== null && date_open !== '') {
		formData.append('date_open', new Date(date_open.getTime() - (date_open.getTimezoneOffset() * 60000)).toISOString());
	}
	if (work_hours !== '' && work_hours !== null) {
		formData.append('work_hours', work_hours);
	}
	if (address !== '' && address !== null) {
		formData.append('address', address);
	}
	if (address_comment !== '' && address_comment !== null) {
		formData.append('address_comment', address_comment);
	}
	if (address_sms !== '' && address_sms !== null) {
		formData.append('address_sms', address_sms);
	}
	if (entity !== '' && entity !== null) {
		formData.append('entity', entity);
	}
	if (first_day_pay !== '' && first_day_pay !== null) {
		formData.append('first_day_pay', first_day_pay);
	}
	if (map_code != null) {
		formData.append('map_code', map_code);
	}
	if (image != null) {
		formData.append('image', image);
	}
	if (preview != null) {
		formData.append('preview', preview);
	}
	if (tariff1 != null) {
		formData.append('tariff1', tariff1);
	}
	if (tariff2 != null) {
		formData.append('tariff2', tariff2);
	}
	if (tariff3 != null) {
		formData.append('tariff3', tariff3);
	}
	if (yandex_map_url != null) {
		formData.append('yandex_map_url', yandex_map_url);
	}
	if (entrance_photo_url != null) {
		formData.append('entrance_photo_url', entrance_photo_url);
	}

	if (settings.consultations_office != null) {
		formData.append('settings.consultations_office', settings.consultations_office);
	}
	if (settings.consultations_online != null) {
		formData.append('settings.consultations_online', settings.consultations_online);
	}
	if (settings.consultations_same_day != null) {
		formData.append('settings.consultations_same_day', settings.consultations_same_day);
	}
	if (settings.consultations_on_weekends != null) {
		formData.append('settings.consultations_on_weekends', settings.consultations_on_weekends);
	}
	if (settings.consultations_timeslot_bfl != null) {
		formData.append('settings.consultations_timeslot_bfl', settings.consultations_timeslot_bfl);
	}
	if (settings.consultations_timeslot_mpk != null) {
		formData.append('settings.consultations_timeslot_mpk', settings.consultations_timeslot_mpk);
	}
	if (settings.consultations_date_limit_bfl != null) {
		formData.append('settings.consultations_date_limit_bfl', settings.consultations_date_limit_bfl);
	}
	if (settings.consultations_date_limit_mpk != null) {
		formData.append('settings.consultations_date_limit_mpk', settings.consultations_date_limit_mpk);
	}
	if (settings.consultations_shift_start_mon != null) {
		formData.append('settings.consultations_shift_start_mon', settings.consultations_shift_start_mon);
	}
	if (settings.consultations_shift_end_mon != null) {
		formData.append('settings.consultations_shift_end_mon', settings.consultations_shift_end_mon);
	}
	if (settings.consultations_on_specialist_mon_bfl != null) {
		formData.append('settings.consultations_on_specialist_mon_bfl', settings.consultations_on_specialist_mon_bfl);
	}
	if (settings.consultations_on_specialist_mon_mpk != null) {
		formData.append('settings.consultations_on_specialist_mon_mpk', settings.consultations_on_specialist_mon_mpk);
	}
	if (settings.consultations_shift_start_tue != null) {
		formData.append('settings.consultations_shift_start_tue', settings.consultations_shift_start_tue);
	}
	if (settings.consultations_shift_end_tue != null) {
		formData.append('settings.consultations_shift_end_tue', settings.consultations_shift_end_tue);
	}
	if (settings.consultations_on_specialist_tue_bfl != null) {
		formData.append('settings.consultations_on_specialist_tue_bfl', settings.consultations_on_specialist_tue_bfl);
	}
	if (settings.consultations_on_specialist_tue_mpk != null) {
		formData.append('settings.consultations_on_specialist_tue_mpk', settings.consultations_on_specialist_tue_mpk);
	}
	if (settings.consultations_shift_start_wed != null) {
		formData.append('settings.consultations_shift_start_wed', settings.consultations_shift_start_wed);
	}
	if (settings.consultations_shift_end_wed != null) {
		formData.append('settings.consultations_shift_end_wed', settings.consultations_shift_end_wed);
	}
	if (settings.consultations_on_specialist_wed_bfl != null) {
		formData.append('settings.consultations_on_specialist_wed_bfl', settings.consultations_on_specialist_wed_bfl);
	}
	if (settings.consultations_on_specialist_wed_mpk != null) {
		formData.append('settings.consultations_on_specialist_wed_mpk', settings.consultations_on_specialist_wed_mpk);
	}
	if (settings.consultations_shift_start_thu != null) {
		formData.append('settings.consultations_shift_start_thu', settings.consultations_shift_start_thu);
	}
	if (settings.consultations_shift_end_thu != null) {
		formData.append('settings.consultations_shift_end_thu', settings.consultations_shift_end_thu);
	}
	if (settings.consultations_on_specialist_thu_bfl != null) {
		formData.append('settings.consultations_on_specialist_thu_bfl', settings.consultations_on_specialist_thu_bfl);
	}
	if (settings.consultations_on_specialist_thu_mpk != null) {
		formData.append('settings.consultations_on_specialist_thu_mpk', settings.consultations_on_specialist_thu_mpk);
	}
	if (settings.consultations_shift_start_fri != null) {
		formData.append('settings.consultations_shift_start_fri', settings.consultations_shift_start_fri);
	}
	if (settings.consultations_shift_end_fri != null) {
		formData.append('settings.consultations_shift_end_fri', settings.consultations_shift_end_fri);
	}
	if (settings.consultations_on_specialist_fri_bfl != null) {
		formData.append('settings.consultations_on_specialist_fri_bfl', settings.consultations_on_specialist_fri_bfl);
	}
	if (settings.consultations_on_specialist_fri_mpk != null) {
		formData.append('settings.consultations_on_specialist_fri_mpk', settings.consultations_on_specialist_fri_mpk);
	}
	if (settings.consultations_shift_start_sat != null) {
		formData.append('settings.consultations_shift_start_sat', settings.consultations_shift_start_sat);
	}
	if (settings.consultations_shift_end_sat != null) {
		formData.append('settings.consultations_shift_end_sat', settings.consultations_shift_end_sat);
	}
	if (settings.consultations_on_specialist_sat_bfl != null) {
		formData.append('settings.consultations_on_specialist_sat_bfl', settings.consultations_on_specialist_sat_bfl);
	}
	if (settings.consultations_on_specialist_sat_mpk != null) {
		formData.append('settings.consultations_on_specialist_sat_mpk', settings.consultations_on_specialist_sat_mpk);
	}
	if (settings.consultations_shift_start_sun != null) {
		formData.append('settings.consultations_shift_start_sun', settings.consultations_shift_start_sun);
	}
	if (settings.consultations_shift_end_sun != null) {
		formData.append('settings.consultations_shift_end_sun', settings.consultations_shift_end_sun);
	}
	if (settings.consultations_on_specialist_sun_bfl != null) {
		formData.append('settings.consultations_on_specialist_sun_bfl', settings.consultations_on_specialist_sun_bfl);
	}
	if (settings.consultations_on_specialist_sun_mpk != null) {
		formData.append('settings.consultations_on_specialist_sun_mpk', settings.consultations_on_specialist_sun_mpk);
	}
	if (settings.office_comment != null) {
		formData.append('settings.office_comment', settings.office_comment);
	}
	if (settings.manager_tg != null) {
		formData.append('settings.manager_tg', settings.manager_tg);
	}

	formData.append('time_offset', time_offset);
	formData.append('show_on_sites', show_on_sites);
	formData.append('status_coven', status_coven);

	return formData;
}

export const actions = {
	async HELPDESK_GET_BRANCHES_FROM_API({commit}, {ids, sort_name, city_id, new_city_id, status, project, limit, page}) {
		let filter = {
			ids: ids,
			city_id: city_id,
			new_city_id: new_city_id,
			sort_name: sort_name,
			status: status,
			project: project,
			limit: limit,
			page: page,
			with: 'settings',
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v3/branches', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_BRANCHES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_BRANCH_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v3/branch/' + id + '?with=settings,tariffs,weekends').then((resp) => {
			commit(events.HELPDESK_SET_BRANCH, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_BRANCH({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/branch/' + id).then(() => {
			dispatch('HELPDESK_GET_BRANCHES_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_BRANCH({commit}, {id: id, branch: branch}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v3/branch/' + id, branchToFormData(branch), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_BRANCH, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_BRANCH({commit}, branch) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v3/branch', branchToFormData(branch), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_BRANCH, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_BFL_USERS({commit}) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/bfl_users', {}).then((resp) => {
			commit(events.HELPDESK_SET_BFL_RESP, resp.data);
			return resp.data;
		});
	},
};
