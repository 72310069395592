<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Создание подписанта
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					ФИО: {{ signer.fio_nominative }}<br>
					ФИО (р.п.): {{ signer.fio_genitive }}<br>
					АМО ID: {{ signer.amo_user_id }}<br>
					Статус: {{ findSignerStatus(signer.active) }}<br>
					Город: {{ findSignerCity(signer.cities) }}<br>
				</div>
			</b-modal>

			<b-row>
				<b-col>
					<b-form-group id="name-input-group" label="ФИО, именительный падеж" label-for="signer-fio-nominative-input">
						<b-form-input
								id="signer-fio-nominative-input"
								name="signer-fio-nominative-input"
								v-model="signer.fio_nominative"
								:state="validateState('fio_nominative')"
								placeholder="Введите фио в именительном падеже"
								aria-describedby="input-signer-fio-nominative-live-feedback"
						></b-form-input>
						<b-form-invalid-feedback
								id="input-signer-fio-nominative-live-feedback"
						>Введите фио в именительном падеже
						</b-form-invalid-feedback>
					</b-form-group>
				</b-col>

				<b-col>
					<b-form-group id="name-genitive-input-group" label="ФИО, родительный падеж"
												label-for="signer-fio-genitive-input">
						<b-form-input
								id="signer-fio-genitive-input"
								name="signer-fio-genitive-input"
								v-model="signer.fio_genitive"
								:state="validateState('fio_genitive')"
								placeholder="Введите фио в родительном падеже"
								aria-describedby="input-signer-fio-genitive-live-feedback"
						></b-form-input>
						<b-form-invalid-feedback
								id="input-signer-fio-genitive-live-feedback"
						>Введите фио в родительном падеже
						</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group id="amo-user-id-input-group" label="АМО ID" label-for="signer-amo-user-id-input">
				<b-form-input
						id="signer-amo-user-id-input"
						name="signer-amo-user-id-input"
						v-model="signer.amo_user_id"
						placeholder="Введите id пользователя АМО"
						:state="validateState('amo_user_id')"
						aria-describedby="input-signer-amo-user-id-live-feedback"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-signer-amo-user-id-live-feedback"
				>Введите id пользователя АМО
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="active-input-group" label="Статус" label-for="signer-active-input">
				<b-form-select
						id="signer-active-input"
						name="signer-active-input"
						v-model="signer.active"
						:state="validateState('active')"
						:options="statusOptions"
						aria-describedby="input-signer-active-live-feedback"
				></b-form-select>
				<b-form-invalid-feedback
						id="input-signer-active-live-feedback"
				>Выберите статус
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="entity-input-group" label="Юридическое лицо" label-for="signer-entity-input">
				<b-form-checkbox-group
						id="signer-entity-input"
						name="signer-entity-input"
						v-model="signer.entity"
						:state="validateState('entity')"
						:options="entityOptions"
						aria-describedby="input-signer-entity-live-feedback"
				></b-form-checkbox-group>
				<b-form-invalid-feedback
						id="input-signer-entity-live-feedback"
				>Выберите юридическое лицо
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="signer-city-id-input-group" label="Город" label-for="signer-city-id-input">
				<b-form-checkbox
						v-model="allSelected"
						:indeterminate="indeterminate"
						inline
						plain
						aria-describedby="signer-city-id-input"
						aria-controls="signer-city-id-input"
						@change="checkAllCities">Выбрать все
				</b-form-checkbox>
				<b-form-checkbox-group
						id="signer-city-id-input"
						class="multiple-checkboxes borderElement"
						name="signer-city-id-input"
						v-model="signer.cities"
						:options="cityOptions"
						:state="validateState('cities')"
						plain
						stacked
						aria-describedby="input-signer-city-id-live-feedback"
				></b-form-checkbox-group>
				<b-form-invalid-feedback
						id="input-signer-city-id-live-feedback"
				>Должен быть выбран хотя бы один город
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToSignersTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';
import {helpdeskSignersStatusEnum} from '@/store/helpdesk/signers/constants';
import {incline, inclineFirstname, inclineLastname, inclineMiddlename} from 'lvovich';

export default {
	name: 'HelpDeskSignerCreateForm',
	mixins: [validationMixin],
	validations: {
		signer: {
			amo_user_id: {
				required,
				numeric
			},
			fio_nominative: {
				required
			},
			fio_genitive: {
				required
			},
			entity: {
				required
			},
			active: {
				required
			},
			cities: {
				required
			},
		}
	},
	data() {
		return {
			signer: {
				amo_user_id: null,
				fio_nominative: null,
				fio_genitive: null,
				entity: ['lt'],
				active: null,
				cities: []
			},
			allSelected: false,
			indeterminate: false,
			cityOptions: [],
			statusOptions: [
				{value: null, text: 'Выберите статус подписанта'},
			],
			entityOptions: [
				{value: 'mpk', text: 'МПК'},
				{value: 'lt', text: 'LegalTech'},
			],
		};
	},
	watch: {
		'signer.cities'(newValue) {
			if (newValue.length === 0) {
				this.indeterminate = false;
				this.allSelected = false;
			} else if (newValue.length === this.cityOptions.length) {
				this.indeterminate = false;
				this.allSelected = true;
			} else {
				this.indeterminate = true;
				this.allSelected = false;
			}
		},
		'signer.fio_nominative'(newValue) {
			if (newValue !== '') {
				let fio = newValue.split(' ');
				if (fio.length === 3) {
					let fio_genitive = incline({middle: fio[2], first: fio[1], last: fio[0]}, 'genitive');
					this.signer.fio_genitive = fio_genitive.last + ' ' + fio_genitive.first + ' ' + fio_genitive.middle;
				}
			}
		}
	},
	methods: {
		...mapActions([
			'HELPDESK_CREATE_SIGNER', 'HELPDESK_GET_CITIES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		findSignerCity(cities) {
			switch (cities.length) {
			case 0:
				return 'Не назначен';
			case 1:
				for (let city of this.cityOptions) {
					if (city.value === cities[0]) {
						return city.text;
					}
				}
				return 'Не найден';
			default:
				return 'Несколько';
			}
		},

		findSignerStatus(status) {
			let funded = this.statusOptions.find(x => x.value === status);
			if (funded === undefined) {
				return 'Неизвестный статус';
			}
			return funded.text;
		},

		checkAllCities(checked) {
			let citiesIds = [];
			this.cityOptions.forEach(function (cityOpt) {
				citiesIds.push(cityOpt.value);
			});
			this.signer.cities = checked ? citiesIds : [];
		},

		backToSignersTable() {
			this.$router.push({name: routeNames.helpdesk.signers.read});
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.signer[name];
			return $dirty ? !$error : null;
		},

		async submit() {
			this.$v.signer.$touch();
			if (this.$v.signer.$anyError) {
				return;
			}
			await this.HELPDESK_CREATE_SIGNER(this.signer).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Подписант успешно создан'});
				this.backToSignersTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании подписанта'
				});
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskCities'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_CITIES_FROM_API({}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка городов'
			});
		});
		for (let city of this.helpdeskCities) {
			this.cityOptions.push({value: city.id, text: city.name});
		}
		this.cityOptions = this.cityOptions.sort((a, b) => a.text.localeCompare(b.text));
		for (let e in helpdeskSignersStatusEnum) {
			this.statusOptions.push(helpdeskSignersStatusEnum[e]);
		}
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
