import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/priorities/event-types';

function priorityToFormData(priority) {
	let formData = new FormData();
	formData.append('project', priority.project);
	formData.append('priority', priority.priority);
	formData.append('criticality', priority.criticality);
	formData.append('ba_elaboration', priority.ba_elaboration);
	formData.append('ca_elaboration', priority.ca_elaboration);
	return formData;
}

export const actions = {
	async HELPDESK_GET_PRIORITIES_FROM_API({commit}, {sort_name, limit, page}) {
		let filter = {
			sort_name: sort_name,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/priorities', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_PRIORITIES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_PRIORITY_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/priority/' + id).then((resp) => {
			commit(events.HELPDESK_SET_PRIORITY, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_PRIORITY({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/priority/' + id).then(() => {
			dispatch('HELPDESK_GET_CITIES_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_PRIORITY({commit}, {id: id, priority: priority}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/priority/' + id, priorityToFormData(priority), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_PRIORITY, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_PRIORITY({commit}, priority) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/priority', priorityToFormData(priority), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_PRIORITY, resp.data);
			return resp.data;
		});
	},
};