<template>
  <div class="element-box">
    <b-form>
      <h5 class="form-header">
        Изменение основной информации сайтов
      </h5>
      <div class="form-desc">
        Измените необходимые данные и нажмите сохранить
      </div>

      <b-modal
          id="confirmation-modal"
          title="Подтвердите изменения"
          ok-title="Сохранить"
          @ok="submit"
          cancel-title="Отмена"
      >
        <div>
          Лицензия: {{ siteInfo.license }}<br>
          Количество клиентов: {{ siteInfo.amount_clients }}<br>
          Количество сотрудников: {{ siteInfo.amount_coworkers }}<br>
          Информация о торговом знаке: {{ siteInfo.copyright }}<br>
        </div>
      </b-modal>

      <b-form-group id="license-input-group" label="Информация о лицензии" label-for="site-info-license-input">
        <b-form-input
            id="site-info-license-input"
            name="site-info-license-input"
            v-model="$v.siteInfo.license.$model"
            :state="validateState('license')"
            aria-describedby="input-site-info-license-live-feedback"
            placeholder="Введите информацию о лицензии"
        ></b-form-input>
        <b-form-invalid-feedback
            id="input-site-info-license-live-feedback"
        >Это обязательное поле.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="amount-clients-input-group" label="Количество клиентов"
                    label-for="site-info-amount-clients-input">
        <b-form-input
            id="site-info-amount-clients-input"
            name="site-info-amount-clients-input"
            v-model="$v.siteInfo.amount_clients.$model"
            :state="validateState('amount_clients')"
            aria-describedby="input-site-info-amount-clients-live-feedback"
            placeholder="Введите количество клиентов"
        ></b-form-input>
        <b-form-invalid-feedback
            id="input-site-info-amount-clients-live-feedback"
        >Количество клиентов должно быть больше 0.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="amount-coworkers-input-group" label="Количество сотрудников"
                    label-for="site-info-amount-coworkers-input">
        <b-form-input
            id="site-info-amount-coworkers-input"
            name="site-info-amount-coworkers-input"
            v-model="$v.siteInfo.amount_coworkers.$model"
            :state="validateState('amount_coworkers')"
            aria-describedby="input-site-info-amount-coworkers-live-feedback"
            placeholder="Введите количество сотрудников"
        ></b-form-input>
        <b-form-invalid-feedback
            id="input-site-info-amount-coworkers-live-feedback"
        >Количество сотрудников должно быть больше 0.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="copyright-input-group" label="Информация о торговом знаке"
                    label-for="site-info-copyright-input">
        <b-form-input
            id="site-info-copyright-input"
            name="site-info-amount-coworkers-input"
            v-model="$v.siteInfo.copyright.$model"
            :state="validateState('copyright')"
            aria-describedby="input-site-info-copyright-live-feedback"
            placeholder="Введите информацию о товарном знаке"
        ></b-form-input>
        На сервере к полю добавится год открытия и текущий год:
        "{{ addYears(siteInfo.copyright) }}"
        <b-form-invalid-feedback
            id="input-site-info-copyright-live-feedback"
        >Это обязательное поле.
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="form-buttons-w">
        <b-button class="btn btn-primary" type="button" variant="primary" name="button"
                  v-b-modal="'confirmation-modal'">Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, minValue} from 'vuelidate/lib/validators';

export default {
	name: 'SiteInfoForm',
	mixins: [validationMixin],
	validations: {
		siteInfo: {
			license: {
				required
			},
			amount_clients: {
				required,
				minValue: minValue(1)
			},
			amount_coworkers: {
				required,
				minValue: minValue(1)
			},
			copyright: {
				required
			}
		}
	},
	data() {
		return {
			siteInfo: {
				license: '',
				amount_clients: 0,
				amount_coworkers: 0,
				copyright: ''
			}
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_UPDATE_SITE_INFO', 'HELPDESK_GET_SITE_INFO_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		validateState(name) {
			const {$dirty, $error} = this.$v.siteInfo[name];
			return $dirty ? !$error : null;
		},

		addYears(copyright) {
			return copyright+' 2009-'+new Date().getFullYear();
		},

		async submit() {
			this.$v.siteInfo.$touch();
			if (this.$v.siteInfo.$anyError) {
				return;
			}
			await this.HELPDESK_UPDATE_SITE_INFO(this.siteInfo).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Информация о сайтах успешно обновлена' });
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при обновлении информации о сайтах' });
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskSiteInfo'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_SITE_INFO_FROM_API().catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении информации о сайтах' });
		});
		this.siteInfo = this.helpdeskSiteInfo;
		this.siteInfo.copyright = this.helpdeskSiteInfo.copyright.slice(0, -10);
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
