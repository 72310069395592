<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Создание выходного
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Филиал: {{ findWeekendBranch() }}<br>
					Дата: {{ weekend.start_date_local }}<br>
				</div>
			</b-modal>

			<b-form-group id="name-input-group" label="Филиал" label-for="weekend-branch-id-input">
				<b-form-select
						id="weekend-branch-id-input"
						name="weekend-branch-id-input"
						v-model="weekend.branch_id"
						:options="branchOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="date-open-input-group" label="Дата" label-for="weekend-branch-date-input">
				<b-form-datepicker
						id="weekend-branch-date-input"
						v-model="weekend.date"
						placeholder="Введите дату"
						value-as-date
				></b-form-datepicker>
			</b-form-group>

			<template v-if="showTimeSelect">
				<div class="form-group">
					<label>Время начала</label>
					<div class="row">
						<div class="col col-6">
							<b-form-select
								id="weekend-branch-start-hours-input"
								v-model="weekend.begin_time_hours"
								:disabled="weekend.is_day"
								:options="hoursOptions"
							></b-form-select>
						</div>
						<div class="col col-6">
							<b-form-select
								id="weekend-branch-start-minutes-input"
								v-model="weekend.begin_time_minutes"
								:disabled="weekend.is_day"
								:options="minutesOptions"
							></b-form-select>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label>Время окончания</label>
					<div class="row">
						<div class="col col-6">
							<b-form-select
								id="weekend-branch-start-hours-input"
								v-model="weekend.end_time_hours"
								:options="hoursOptions"
							></b-form-select>
						</div>
						<div class="col col-6">
							<b-form-select
								id="weekend-branch-start-minutes-input"
								v-model="weekend.end_time_minutes"
								:options="minutesOptions"
							></b-form-select>
						</div>
					</div>
				</div>
			</template>

			<b-form-checkbox
				id="weekend-is-day-input"
				v-model="weekend.is_day"
				name="weekend-is-day-input"
				:value="true"
				:unchecked-value="false"
			>
				Весь день
			</b-form-checkbox>

			<b-form-group
				id="weekend-comment"
				label="Комментарий"
				label-for="weekend-comment-input"
			>
				<b-form-textarea
					id="weekend-comment-input"
					v-model="weekend.comment"
					rows="2"
				></b-form-textarea>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToWeekendsTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskWeekendCreateForm',
	mixins: [validationMixin],
	data() {
		const hoursOptions = [];
		for (let i = 0; i <= 23; i += 1) {
			hoursOptions.push({ value: i < 10 ? '0' + i : `${i}`, text: i < 10 ? `0${i}` : `${i}`});
		}
		const minutesOptions = [];
		for (let i = 0; i <= 59; i += 1) {
			minutesOptions.push({ value: i < 10 ? `0${i}` : `${i}`, text: i < 10 ? `0${i}` : `${i}`});
		}

		return {
			weekend: {
				branch_id: null,
				date: null,
				begin_time_hours: null,
				begin_time_minutes: null,
				end_time_hours: null,
				end_time_minutes: null,
				is_day: false,
				comment: null,
				start_date: null,
				start_date_local: null,
				end_date: null,
				end_date_local: null,
			},
			branchOptions: [
				{value: null, text: 'Не назначен'}
			],
			hoursOptions,
			minutesOptions,
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_CREATE_WEEKEND', 'HELPDESK_GET_BRANCHES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		findWeekendBranch() {
			for (let branch of this.branchOptions) {
				if (branch.value === this.weekend.branch_id) {
					return branch.text;
				}
			}
			return null;
		},

		backToWeekendsTable() {
			this.$router.push({name: routeNames.helpdesk.weekends.read});
		},

		async submit() {
			if (
				this.weekend.branch_id === null ||
				this.weekend.date === null ||
				(!this.weekend.is_day && (
					this.weekend.begin_time_hours === null ||
					this.weekend.begin_time_minutes === null ||
					this.weekend.end_time_hours === null ||
					this.weekend.end_time_minutes === null
				))
			) {
				return;
			}

			let branch = null;
			for (let b of this.helpdeskBranches) {
				if (this.weekend.branch_id === b.id) {
					branch = b;
					break;
				}
			}

			if (branch === null) {
				return;
			}

			const m = this.weekend.date.getMonth() + 1;
			let mn = m.toString()
			if (m < 10) {
				mn = `0${m}`;
			}

			const d = this.weekend.date.getDate();
			let dy = d.toString();
			if (d < 10) {
				dy = `0${d}`;
			}

			if (this.weekend.is_day) {
				this.weekend.start_date_local = new Date(Date.parse(`${this.weekend.date.getFullYear()}-${mn}-${dy}T00:00:00.000Z`)).toISOString();
				this.weekend.start_date = this.weekend.start_date_local;
				this.weekend.end_date_local = new Date(Date.parse(`${this.weekend.date.getFullYear()}-${mn}-${dy}T23:59:59.000Z`)).toISOString();
				this.weekend.end_date = this.weekend.end_date_local;
			} else {
				const start_date_local = Date.parse(`${this.weekend.date.getFullYear()}-${mn}-${dy}T${this.weekend.begin_time_hours}:${this.weekend.begin_time_minutes}:00.000Z`);
				const end_date_local = Date.parse(`${this.weekend.date.getFullYear()}-${mn}-${dy}T${this.weekend.end_time_hours}:${this.weekend.end_time_minutes}:00.000Z`);

				this.weekend.start_date_local = new Date(start_date_local).toISOString();
				this.weekend.end_date_local = new Date(end_date_local).toISOString();

				this.weekend.start_date = new Date(start_date_local - branch.time_offset * 3600000).toISOString();
				this.weekend.end_date = new Date(end_date_local - branch.time_offset * 3600000).toISOString();
			}

			await this.HELPDESK_CREATE_WEEKEND(this.weekend).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Выходной успешно создан'});
				this.backToWeekendsTable();
			}).catch((e) => {
				console.log(e);
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании выходного'
				});
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskBranches'
		]),
		showTimeSelect() {
			return !this.weekend.is_day;
		},
	},
	async mounted() {
		await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});
		for (let branch of this.helpdeskBranches) {
			this.branchOptions.push({value: branch.id, text: branch.name});
		}
	},
};
</script>

<style>

</style>
