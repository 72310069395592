<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Изменение данных телефона
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Филиал: {{ findPhoneBranch() }}<br>
					Домен телефона: {{ phone.domain }}<br>
					Телефон: {{ phone.phone }}<br>
				</div>
			</b-modal>

			<b-form-group id="name-input-group" label="Филиал" label-for="phone-branch-id-input">
				<b-form-select
						id="phone-branch-id-input"
						name="phone-branch-id-input"
						v-model="phone.branch_id"
						:options="branchOptions"
				></b-form-select>
			</b-form-group>

			<b-form-group id="domain-input-group" label="Домен телефона" label-for="phone-domain-input">
				<b-form-input
						id="phone-domain-input"
						name="phone-domain-input"
						v-model="phone.domain"
						placeholder="Введите домен, например priziva.net"
				></b-form-input>
			</b-form-group>

			<b-form-group id="phone-input-group" label="Телефон" label-for="phone-phone-input">
				<b-form-input
						id="phone-phone-input"
						name="phone-phone-input"
						v-model="$v.phone.phone.$model"
						:state="validateState('phone')"
						aria-describedby="input-phone-phone-live-feedback"
						placeholder="Введите телефон в формате '8(XXX)XXX-XX-XX' или '8(XXXX)XX-XX-XX"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-phone-domain-live-feedback"
				>Телефон должен быть в формате '8(XXX) XXX-XX-XX' или '8(XXXX) XX-XX-XX'.
				</b-form-invalid-feedback>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToPhonesTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

const phoneRegex = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /8\(([0-9]{3,4})\)([0-9]{2,3})-([0-9]{2})-([0-9]{2})/.test(value);
};

export default {
	name: 'HelpDeskPhoneUpdateForm',
	mixins: [validationMixin],
	validations: {
		phone: {
			phone: {
				required,
				phoneRegex
			}
		}
	},
	data() {
		return {
			phone: {
				branch_id: null,
				domain: null,
				phone: null
			},
			branchOptions: [
				{value: null, text: 'Не назначен'}
			]
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_UPDATE_PHONE', 'HELPDESK_GET_PHONE_FROM_API', 'HELPDESK_GET_BRANCHES_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		findPhoneBranch() {
			for (let branch of this.branchOptions) {
				if (branch.value === this.phone.branch_id) {
					return branch.text;
				}
			}
			return null;
		},

		backToPhonesTable() {
			this.$router.push({name: routeNames.helpdesk.phones.read});
		},

		validateState(name) {
			const {$dirty, $error} = this.$v.phone[name];
			return $dirty ? !$error : null;
		},

		async submit() {
			if (this.phone.domain === null || this.phone.domain === '') {
				this.phone.domain = 'default';
			}
			this.$v.phone.$touch();
			if (this.$v.phone.$anyError) {
				return;
			}
			await this.HELPDESK_UPDATE_PHONE({id: this.$route.params.phone_id, phone: this.phone}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Телефон успешно обновлён'});
				this.backToPhonesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении телефона'
				});
			});
		}
	},
	computed: {
		...mapGetters([
			'helpdeskPhone', 'helpdeskBranches'
		]),
	},
	async mounted() {
		await this.HELPDESK_GET_BRANCHES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка филиалов'
			});
		});
		for (let branch of this.helpdeskBranches) {
			this.branchOptions.push({value: branch.id, text: branch.name});
		}
		if (this.$route.params.phone_id !== '0') {
			await this.HELPDESK_GET_PHONE_FROM_API(this.$route.params.phone_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении телефона'
				});
			});
			this.phone = this.helpdeskPhone;
		}
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
