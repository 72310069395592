<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Создание скидки
			</h5>
			<div class="form-desc">
				Заполните необходимые данные и нажмите сохранить
			</div>

			<b-form-group id="name-input-group" label="Название" label-for="discount-name-input">
				<b-form-input
						id="discount-name-input"
						name="discount-name-input"
						v-model="discount.name"
						placeholder="Введите название"
				></b-form-input>
			</b-form-group>

			<b-form-group id="discount-input-group" label="Сумма" label-for="discount-sum-input">
				<b-form-input
						id="discount-sum-input"
						name="discount-sum-input"
						v-model="discount.sum"
						placeholder="Введите сумму скидки"
				></b-form-input>
			</b-form-group>

			<b-form-group id="expiration-input-group" label="Дата завершения" label-for="discount-expiration-input">
				<b-form-datepicker
						id="discount-expiration-input"
						name="discount-expiration-input"
						v-model="discount.expiration"
						placeholder="Выберите дату завершения действия скидки"
				></b-form-datepicker>
			</b-form-group>

			<b-form-group id="comment-input-group" label="Комментарий" label-for="discount-comment-input">
				<b-form-input
						id="discount-comment-input"
						name="discount-comment-input"
						v-model="discount.comment"
						placeholder="Введите комментарий"
				></b-form-input>
			</b-form-group>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									@click="submit">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToDiscountsTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'HelpDeskDiscountCreateForm',
	data() {
		return {
			discount: {
				name: null,
				sum: null,
				expiration: null,
				comment: null
			},
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_CREATE_DISCOUNT', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		backToDiscountsTable() {
			this.$router.push({name: routeNames.helpdesk.discounts.read});
		},

		async submit() {
			await this.HELPDESK_CREATE_DISCOUNT(this.discount).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Скидка успешно создана'});
				this.backToDiscountsTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании скидки'
				});
			});
		}
	},
	async mounted() {
	},
};
</script>

<style>

</style>
