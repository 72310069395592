import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import notifications from './notifications/index';
import passport from './passport/auth/index';
import passportUsers from './passport/users/index';
import passportPermissions from './passport/permissions/index';
import passportGroups from './passport/groups/index';
import passportNamespaces from './passport/namespaces/index';
import passportAttributes from './passport/attributes/index';
import passportScopes from './passport/scopes/index';
import helpbackResponsibles from './helpback/responsible/index';
import helpbackUsersDict from './helpback/users/index';
import helpbackCitiesDict from './helpback/cities/index';
import branches from '@/store/helpdesk/branches';
import cities from '@/store/helpdesk/cities';
import phones from '@/store/helpdesk/phones';
import signers from '@/store/helpdesk/signers';
import siteInfo from '@/store/helpdesk/siteinfo';
import sites from '@/store/helpdesk/sites';
import sms from '@/store/helpdesk/sms';
import hunterform from './helpdesk/hunterform/index';
import docgen from '@/store/term-docgen/docgen';
import priorities from '@/store/helpdesk/priorities';
import vpn from '@/store/helpdesk/vpn';
import terminal from '@/store/helpdesk/terminal';
import discounts from '@/store/helpdesk/discounts';
import tariffs from '@/store/helpdesk/tariffs';
import weekends from '@/store/helpdesk/weekends';
import okkFiles from '@/store/okk/files';
import okkUsers from '@/store/okk/users';
import staff from '@/store/helpdesk/staff';
import clusters from '@/store/helpdesk/clusters';

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: window.localStorage,
		})
	],
	modules: {
		notifications,
		passport,
		passportUsers,
		passportScopes,
		passportPermissions,
		passportGroups,
		passportNamespaces,
		passportAttributes,
		branches,
		cities,
		phones,
		signers,
		siteInfo,
		sites,
		sms,
		hunterform,
		priorities,
		helpbackResponsibles,
		helpbackUsersDict,
		helpbackCitiesDict,
		docgen,
		vpn,
		terminal,
		discounts,
		tariffs,
		weekends,
		okkFiles,
		okkUsers,
		staff,
		clusters,
	}
});
