import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_TASK(deal_id, not_finished) {
	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/tasks/' + deal_id.toString() + '?' + new URLSearchParams({not_finished}).toString()
	).then((resp) => {
		return {task: resp.data.task};
	}).catch((err) => {
		return {task: [], error: err};
	});
}

export function CREATE_TASK({name, contact_id}) {
	let deal = {
		name,
		contact_id
	};
	for (let key in deal) {
		if (deal[key] === undefined || deal[key] === null || deal[key].length === 0) {
			delete deal[key];
		}
	}
	return axios.post(VUE_APP_ADV_CAB_URL + '/api/v1/tasks', deal).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}

export function FINISH_TASK(task_id) {
	return axios.post(VUE_APP_ADV_CAB_URL + '/api/v1/tasks/' + task_id.toString() + '/finish').then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}
