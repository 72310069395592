<template>
	<div class="element-box">
		<h5 class="form-header">
			Список контактов
		</h5>
		<div class="form-desc">
			<a href="#" v-b-modal="'create-form'">Создать новый контакт</a>
			<b-modal
					id="create-form"
					title="Создание контакта"
					ok-title="Сохранить"
					cancel-title="Отмена"
					v-on:ok="createContact"
			>
				<b-form>
					<b-form-group label="Имя контакта" label-for="contact-name-filter">
						<b-form-input
								id="contact-name-filter"
								v-model="contact.name"
								placeholder="Имя контакта, который надо найти"
						></b-form-input>
					</b-form-group>

					<b-form-group label="Телефон" label-for="contact-phone-filter">
						<b-form-input
								id="contact-phone-filter"
								v-model="contact.phone"
								placeholder="Телефон контакта"
						></b-form-input>
					</b-form-group>

					<b-form-group label="VK" label-for="contact-vk-filter">
						<b-form-input
								id="contact-vk-filter"
								v-model="contact.vk"
								placeholder="VK контакта"
						></b-form-input>
					</b-form-group>

					<b-form-group label="Телеграм" label-for="contact-tg-filter">
						<b-form-input
								id="contact-tg-filter"
								v-model="contact.tg"
								placeholder="Телеграм контакта"
						></b-form-input>
					</b-form-group>

					<b-form-group label="Twitch" label-for="contact-twitch-filter">
						<b-form-input
								id="contact-twitch-filter"
								v-model="contact.twitch"
								placeholder="Twitch контакта"
						></b-form-input>
					</b-form-group>
				</b-form>
			</b-modal>
		</div>
		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация ответственных"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group label="Имя контакта" label-for="contact-name-filter">
									<b-form-input
											id="contact-name-filter"
											v-model="filters.name"
											placeholder="Имя контакта, который надо найти"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Телефон" label-for="contact-phone-filter">
									<b-form-input
											id="contact-phone-filter"
											v-model="filters.phone"
											placeholder="Телефон контакта"
									></b-form-input>
								</b-form-group>

								<b-form-group label="VK" label-for="contact-vk-filter">
									<b-form-input
											id="contact-vk-filter"
											v-model="filters.vk"
											placeholder="VK контакта"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Телеграм" label-for="contact-tg-filter">
									<b-form-input
											id="contact-tg-filter"
											v-model="filters.tg"
											placeholder="Телеграм контакта"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Twitch" label-for="contact-twitch-filter">
									<b-form-input
											id="contact-twitch-filter"
											v-model="filters.twitch"
											placeholder="Twitch контакта"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Создание сделок" label-for="deal-created_at-filter">
									<b-row>
										<b-col>
											<b-form-datepicker
													id="deal-created_at-filter"
													placeholder="С даты"
													v-model="filters.created_at_from"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
										<b-col>
											<b-form-datepicker
													placeholder="По дату"
													v-model="filters.created_at_to"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
									</b-row>
								</b-form-group>

								<b-form-group label="Статус оплаты" label-for="deal-pay_status-filter">
									<b-form-select
											id="deal-pay_status-filter"
											v-model="filters.pay_status"
											:options="paymentStatusOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Платформа" label-for="deal-platform-filter">
									<b-form-select
											id="deal-platform-filter"
											v-model="filters.platform"
											:options="platformOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Паблик" label-for="deal-public_name-filter">
									<b-form-input
											id="deal-public_name-filter"
											v-model="filters.public_name"
											placeholder="Название паблика"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Публикация постов" label-for="deal-date_post_at_from-filter">
									<b-row>
										<b-col>
											<b-form-datepicker
													id="deal-date_post_at_from-filter"
													placeholder="С даты"
													v-model="filters.date_post_at_from"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
										<b-col>
											<b-form-datepicker
													placeholder="По дату"
													v-model="filters.date_post_at_to"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="contacts-limit"
									name="contacts-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="getContacts(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Контакт
					</th>
					<th>
						Незавершённые задачи
					</th>
					<th>
						Активные сделки
					</th>
					<th>
						Отправлен в работу
					</th>
					<th>
						На оплате
					</th>
					<th>
						Оплачен
					</th>
					<th>
						На уточнении
					</th>
					<th>
						Возврат
					</th>
					<th>
						Реализовано
					</th>
					<th>
						Утиль
					</th>
				</tr>
				</thead>
				<tbody v-for="contact in contacts" :key="contact.id">
				<tr>
					<td>
						<router-link
								:to="{ name: routeNames.adv_cab.deals.read, params: { contact_id: contact.id }} ">
							{{ contact.name }}
						</router-link>
					</td>
					<td>
						{{ contact.task_cnt }}
					</td>
					<td>
						{{ contact.deal_cnt }}
					</td>
					<td>
						{{ contact.sended_to_work }}
					</td>
					<td>
						{{ contact.sended_to_pay }}
					</td>
					<td>
						{{ contact.paid }}
					</td>
					<td>
						{{ contact.minded }}
					</td>
					<td>
						{{ contact.refunded }}
					</td>
					<td>
						{{ contact.realized }}
					</td>
					<td>
						{{ contact.closed }}
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && contacts.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {routeNames} from '@/router/constants';
import {mapActions} from 'vuex';
import {GET_CONTACTS, CREATE_CONTACT} from '@/api/adv-cab/contacts';
import router from '@/router';

export default {
	name: 'AdvContactsTable',
	data() {
		return {
			contacts: [],
			counter: 0,
			contact: {
				name: '',
				phone: '',
				vk: '',
				tg: '',
				twitch: '',
			},
			filters: {
				name: '',
				phone: '',
				vk: '',
				tg: '',
				twitch: '',
				created_at_from: '',
				created_at_to: '',
				pay_status: null,
				platform: null,
				public_name: '',
				date_post_at_from: '',
				date_post_at_to: '',
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Контактов на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],
			canGetNext: true,
			canGetPrev: false,
			routeNames,

			platformOptions: [
				{value: null, text: 'Выберите платформу'},
				{value: 'tg', text: 'Telegram'},
				{value: 'vk', text: 'VK'},
				{value: 'yt', text: 'YouTube'},
				{value: 'twitch', text: 'Twitch'}
			],
			paymentStatusOptions: [
				{value: null, text: 'Выберите статус'},
				{value: 'paid', text: 'Оплачено'},
				{value: 'not_paid', text: 'Не оплачено'},
			],
		};
	},

	methods: {
		...mapActions([
			'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getContacts();
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getContacts();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},
		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getContacts();
			if (this.contacts.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getContacts() {
			const resp = await GET_CONTACTS(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка контактов'
				});
			});
			if (resp.error) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка контактов'
				});
				return;
			}
			this.contacts = resp.contacts;
			this.counter = resp.counter;
		},

		createContact() {
			return CREATE_CONTACT(this.contact).then((r) => {
				if (r.status === 200) {
					console.log(r);
					router.push({name: routeNames.adv_cab.deals.read, params: {contact_id: r.id}});
					return;
				}
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании контакта'
				});
			}).catch((err) => {
				console.log(err);
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании контакта'
				});
			});
		},
	},

	async mounted() {
		await this.getContacts();
	}
};
</script>

<style scoped>

</style>
