import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/clusters/event-types';

function clusterData(cluster) {
	return {
		name: cluster.name,
		description: cluster.description,
		type: cluster.type,
	};
}

export const actions = {
	async HELPDESK_GET_CLUSTERS_FROM_API({commit}, {id, type, limit, page, sort_name}) {
		let filter = {
			id: id,
			type: type,
			limit: limit,
			page: page,
			sort_name: sort_name
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/clusters', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_CLUSTERS, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_CLUSTER_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/cluster/' + id).then((resp) => {
			commit(events.HELPDESK_SET_CLUSTER, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_CLUSTER({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/cluster/' + id).then(() => {
			dispatch('HELPDESK_GET_CLUSTERS_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_CLUSTER({commit}, {id: id, cluster: cluster}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/cluster/' + id, clusterData(cluster), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_CLUSTER, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_CLUSTER({commit}, cluster) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/cluster', clusterData(cluster), {
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_CLUSTER, resp.data);
			return resp.data;
		});
	},
};
