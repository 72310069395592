<template>
	<div class="element-box">
		<h5 class="form-header">
			Карточка контакта
		</h5>
		<div class="form-desc">
			<a href="#" v-b-modal="'create-form'">Создать новую сделку</a>
			<b-modal
					id="create-form"
					title="Создание сделки"
					ok-title="Сохранить"
					cancel-title="Отмена"
					v-on:ok="createDeal"
			>
				<b-form>
					<b-form-group label="Название сделки" label-for="deal-name">
						<b-form-input
								id="deal-name"
								v-model="deal.name"
								placeholder="Обязательно введите название сделки"
						></b-form-input>
					</b-form-group>
				</b-form>
			</b-modal>
		</div>
		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация сделок"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group label="Имя контакта" label-for="deal-name-filter">
									<b-form-input
											id="deal-name-filter"
											v-model="filters.contact_name"
											placeholder="Имя контакта, к которому привязана сделка"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Название" label-for="deal-name-filter">
									<b-form-input
											id="deal-name-filter"
											v-model="filters.name"
											placeholder="Название сделки"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Ответственный" label-for="deal-name-filter">
									<b-form-select
											id="deal-name-filter"
											v-model="filters.responsible_id"
											:options="responsibleOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Этап" label-for="deal-name-filter">
									<b-form-select
											id="deal-name-filter"
											v-model="filters.status_id"
											:options="statusOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Создание сделки" label-for="deal-created_at-filter">
									<b-row>
										<b-col>
											<b-form-datepicker
													id="deal-created_at-filter"
													placeholder="С даты"
													v-model="filters.created_at_from"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
										<b-col>
											<b-form-datepicker
													placeholder="По дату"
													v-model="filters.created_at_to"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
									</b-row>
								</b-form-group>

								<b-form-group label="Статус оплаты" label-for="deal-pay_status-filter">
									<b-form-select
											id="deal-pay_status-filter"
											v-model="filters.pay_status"
											:options="paymentStatusOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Платформа" label-for="deal-platform-filter">
									<b-form-select
											id="deal-platform-filter"
											v-model="filters.platform"
											:options="platformOptions"
									></b-form-select>
								</b-form-group>

								<b-form-group label="Название паблика" label-for="deal-public_name-filter">
									<b-form-input
											id="deal-public_name-filter"
											v-model="filters.public_name"
											placeholder="Название паблика"
									></b-form-input>
								</b-form-group>

								<b-form-group label="Дата публикации постов" label-for="deal-date_post_at_from-filter">
									<b-row>
										<b-col>
											<b-form-datepicker
													id="deal-date_post_at_from-filter"
													placeholder="С даты"
													v-model="filters.date_post_at_from"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
										<b-col>
											<b-form-datepicker
													placeholder="По дату"
													v-model="filters.date_post_at_to"
													:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
													locale="ru"
											></b-form-datepicker>
										</b-col>
									</b-row>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">
						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="contacts-limit"
									name="contacts-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="getDeals(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Сделка
					</th>
					<th>
						Этап
					</th>
					<th>
						Статус оплаты
					</th>
					<th>
						Задача
					</th>
				</tr>
				</thead>
				<tbody v-for="deal in deals" :key="deal.id">
				<tr>
					<td>
						<router-link
								:to="{ name: routeNames.adv_cab.deals.update, params: {contact_id: $route.params.contact_id, deal_id: deal.id}} ">
							{{ deal.name }}
						</router-link>
					</td>
					<td>
						{{ deal.status }}
					</td>
					<td>
						{{ formatPaymentStatus(deal.payment_status) }}
					</td>
					<td>
						{{ getTaskText(deal.task) }}
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && deals.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {routeNames} from '@/router/constants';
import {mapActions} from 'vuex';
import {GET_DEALS, CREATE_DEAL} from '@/api/adv-cab/deals';
import {GET_MANAGERS, GET_STATUSES} from '@/api/adv-cab/common';

export default {
	name: 'AdvDealsTable',
	data() {
		return {
			deals: [],
			counter: 0,
			deal: {
				name: '',
				contact_id: Number(this.$route.params.contact_id),
			},
			filters: {
				contact_name: '',
				responsible_id: null,
				status_id: null,
				contact_id: Number(this.$route.params.contact_id),
				name: '',
				created_at_from: '',
				created_at_to: '',
				pay_status: null,
				platform: null,
				public_name: '',
				date_post_at_from: '',
				date_post_at_to: '',
				limit: 10,
				offset: 0
			},
			limits: [
				{value: 10, text: 'Сделок на странице'},
				{value: 10, text: '10'},
				{value: 15, text: '15'},
				{value: 30, text: '30'},
				{value: 50, text: '50'}
			],
			allManagers: [],
			allStatuses: [],

			platformOptions: [
				{value: null, text: 'Выберите платформу'},
				{value: 'tg', text: 'Telegram'},
				{value: 'vk', text: 'VK'},
				{value: 'yt', text: 'YouTube'},
				{value: 'twitch', text: 'Twitch'}
			],
			paymentStatusOptions: [
				{value: null, text: 'Выберите статус'},
				{value: 'paid', text: 'Оплачено'},
				{value: 'not_paid', text: 'Не оплачено'},
			],

			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},

	computed: {
		responsibleOptions() {
			const resps = [];

			for (const resp of this.allManagers) {
				resps.push({
					value: resp.id,
					text: `${resp.name}`
				});
			}
			resps.sort((a, b) => a.text.localeCompare(b.text));
			resps.unshift({
				value: null,
				text: 'Выберите ответственного'
			});
			return resps;
		},
		statusOptions() {
			const statuses = [];
			for (const status of this.allStatuses) {
				statuses.push({
					value: status.id,
					text: status.name,
				});
			}
			statuses.unshift({
				value: null,
				text: 'Выберите статус'
			});
			return statuses;
		},
	},

	methods: {
		...mapActions([
			'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit = 10;
			this.filters.offset = 0;
			this.canGetNext = true;
			this.canGetPrev = false;
			await this.getDeals();
		},

		async previous() {
			if (this.filters.offset >= this.filters.limit) {
				this.filters.offset -= this.filters.limit;
				await this.getDeals();
				this.canGetNext = true;
				if (this.filters.offset === 0) {
					this.canGetPrev = false;
				}
			}
		},
		async next() {
			this.canGetPrev = true;
			this.filters.offset += this.filters.limit;
			await this.getDeals();
			if (this.contacts.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		formatPaymentStatus(field) {
			if (field === null) {
				return 'Не оплачено';
			}
			return this.paymentStatusOptions.find((s) => s.value === field).text;
		},

		getTaskText(text) {
			if (text) {
				return text;
			}
			return '-';
		},

		async getDeals() {
			const resp = await GET_DEALS(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка сделок'
				});
			});
			if (resp.error) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка сделок'
				});
				return;
			}
			this.deals = resp.deals;
			this.counter = resp.counter;
		},

		async getManagers() {
			const resp = await GET_MANAGERS().catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка менеджеров'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка менеджеров'
				});
				return;
			}
			this.allManagers = resp.managers;
		},

		async getStatuses() {
			const resp = await GET_STATUSES().catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка статусов'
				});
			});
			if (resp.error !== undefined && resp.error !== null) {
				await this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка статусов'
				});
				return;
			}
			this.allStatuses = resp.statuses;
		},

		createDeal() {
			return CREATE_DEAL(this.deal).then((r) => {
				if (r.status === 200) {
					this.$router.push({
						name: routeNames.adv_cab.deals.update,
						params: {contact_id: this.$route.params.contact_id, deal_id: r.id}
					});
					return;
				}
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании сделки'
				});
			}).catch((err) => {
				console.log(err);
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при создании сделки'
				});
			});
		},
	},
	async mounted() {
		await Promise.all([this.getStatuses(), this.getManagers(), this.getDeals()]);
	},
};
</script>

<style scoped>

</style>
