import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/phones/event-types';

function phoneToFormData(phone) {
	let formData = new FormData();
	formData.append('branch_id', phone.branch_id);
	formData.append('domain', phone.domain);
	formData.append('phone', phone.phone);
	return formData;
}

export const actions = {
	async HELPDESK_GET_PHONES_FROM_API({commit}, { branch_id, domain, limit, page }) {
		let filter = {
			branch_id: branch_id,
			domain: domain,
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if(filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/phones', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_PHONES, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_PHONE_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL+'/api/v1/phone/'+id).then((resp) => {
			commit(events.HELPDESK_SET_PHONE, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_PHONE({dispatch}, { id, filters }) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/phone/'+id).then(() => {
			dispatch('HELPDESK_GET_PHONES_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_PHONE({commit}, { id: id, phone: phone }) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/phone/'+id, phoneToFormData(phone), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_PHONE, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_PHONE({commit}, phone) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/phone', phoneToFormData(phone), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_PHONE, resp.data);
			return resp.data;
		});
	},
};