import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';
import * as events from '@/store/helpdesk/signers/event-types';

function signerToFormData(signer) {
	let formData = new FormData();
	for (let i = 0; i < signer.cities.length; i++) {
		formData.append('cities', signer.cities[i]);
	}
	formData.append('fio_nominative', signer.fio_nominative);
	formData.append('fio_genitive', signer.fio_genitive);
	formData.append('amo_user_id', signer.amo_user_id);
	formData.append('active', signer.active);
	formData.append('entity', signer.entity.join(','));
	return formData;
}

export const actions = {
	async HELPDESK_GET_SIGNERS_FROM_API({commit}, {city_id, active, entity, limit, page}) {
		let filter = {
			city_id: city_id,
			active: active,
			entity: entity.join(','),
			limit: limit,
			page: page
		};
		for (let key in filter) {
			if (filter[key] === '') {
				filter[key] = null;
			}
		}
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/signers', {
			params: filter
		}).then((resp) => {
			commit(events.HELPDESK_SET_SIGNERS, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_GET_SIGNER_FROM_API({commit}, id) {
		return axios.get(VUE_APP_HELPDESK_API_URL + '/api/v1/signer/' + id).then((resp) => {
			commit(events.HELPDESK_SET_SIGNER, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_DELETE_SIGNER({dispatch}, {id, filters}) {
		return axios.delete(VUE_APP_HELPDESK_API_URL + '/api/v1/signer/' + id).then(() => {
			dispatch('HELPDESK_GET_SIGNERS_FROM_API', filters);
		});
	},

	async HELPDESK_UPDATE_SIGNER({commit}, {id: id, signer: signer}) {
		return axios.put(VUE_APP_HELPDESK_API_URL + '/api/v1/signer/' + id, signerToFormData(signer), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_SIGNER, resp.data);
			return resp.data;
		});
	},

	async HELPDESK_CREATE_SIGNER({commit}, signer) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/signer', signerToFormData(signer), {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((resp) => {
			commit(events.HELPDESK_SET_SIGNER, resp.data);
			return resp.data;
		});
	},
};