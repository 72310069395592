<template>
	<div class="element-box">
		<b-form>
			<h5 class="form-header">
				Изменение данных города
			</h5>
			<div class="form-desc">
				Измените необходимые данные и нажмите сохранить
			</div>

			<b-modal
					id="confirmation-modal"
					title="Подтвердите изменения"
					ok-title="Сохранить"
					@ok="submit"
					cancel-title="Отмена"
			>
				<div>
					Amo_id: {{ city.amo_id }}<br>
					ID группы AMO: {{ city.amo_group_id }}<br>
					Название группы AMO: {{ city.amo_group_name }}<br>
					Название города: {{ city.name }}<br>
					Регион города: {{ city.region_name }}<br>
					Смещение во времени: {{ city.time_offset }}<br>
					Абреввиатура: {{ city.abbr }}
					<div v-if="city.show_on_sites === true || city.show_on_sites === 'true'">
						Отображать город на сайтах: Да
					</div>
					<div v-else>
						Отображать город на сайтах: Нет
					</div>

					<div v-if="city.status_coven === true || city.status_coven === 'true'">
						Использовать город в coven: Да
					</div>
					<div v-else>
						Использовать город в coven: Нет
					</div>
				</div>
			</b-modal>

			<b-form-group id="amo-id-input-group" label="AMO id" label-for="city-amo-id-input">
				<b-form-input
						id="city-amo-id-input"
						name="city-amo-id-input"
						v-model="$v.city.amo_id.$model"
						:state="validateState('amo_id')"
						aria-describedby="input-city-amo-id-live-feedback"
						placeholder="Введите AMO id"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-amo-id-live-feedback"
				>AMO id должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="bfl-amo-id-input-group" label="BFL AMO id" label-for="city-bfl-amo-id-input">
				<b-form-input
						id="city-bfl-amo-id-input"
						name="city-bfl-amo-id-input"
						v-model="$v.city.bfl_amo_id.$model"
						:state="validateState('bfl_amo_id')"
						aria-describedby="input-city-bfl-amo-id-live-feedback"
						placeholder="Введите BFL AMO id"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-bfl-amo-id-live-feedback"
				>BFL AMO id должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-group-id-input-group" label="ID группы AMO" label-for="city-amo-group-id-input">
				<b-form-input
						id="city-amo-group-id-input"
						name="city-amo-group-id-input"
						v-model="$v.city.amo_group_id.$model"
						:state="validateState('amo_group_id')"
						aria-describedby="input-city-amo-group-id-live-feedback"
						placeholder="Введите ID группы АМО"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-amo-group-id-live-feedback"
				>ID AMO группы должен состоять только из цифр и не может быть меньше 0.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="amo-group-name-input-group" label="Название группы AMO" label-for="city-amo-group-name-input">
				<b-form-input
						id="city-amo-group-name-input"
						name="city-amo-group-name-input"
						v-model="$v.city.amo_group_name.$model"
						:state="validateState('amo_group_name')"
						aria-describedby="input-city-amo-group-name-live-feedback"
						placeholder="Введите название группы AMO"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-amo-group-name-live-feedback"
				>Название группы AMO должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="name-input-group" label="Название города для отображения" label-for="city-name-input">
				<b-form-input
						id="city-name-input"
						name="city-name-input"
						v-model="$v.city.name.$model"
						:state="validateState('name')"
						aria-describedby="input-city-name-live-feedback"
						placeholder="Введите название города для отображения"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-name-live-feedback"
				>Название должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="main-name-input-group" label="Название города для группировки" label-for="city-main-name-input">
				<b-form-input
						id="city-main-name-input"
						name="city-main-name-input"
						v-model="$v.city.main_name.$model"
						:state="validateState('main_name')"
						aria-describedby="input-city-main-name-live-feedback"
						placeholder="Введите название города для группировки"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-main-name-live-feedback"
				>Название должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="region-input-group" label="Регион" label-for="city-region-input">
				<b-form-input
						id="city-region-input"
						name="city-region-input"
						v-model="$v.city.region_name.$model"
						:state="validateState('region_name')"
						aria-describedby="input-city-region-live-feedback"
						placeholder="Введите регион"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-region-live-feedback"
				>Название должно состоять из русских букв.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="time-offset-input-group" label="Смещение времени UTC" label-for="city-time-offset-input">
				<b-form-input
						id="city-time-offset-input"
						name="city-time-offset-input"
						v-model="$v.city.time_offset.$model"
						:state="validateState('time_offset')"
						aria-describedby="input-city-time-offset-live-feedback"
						placeholder="Введите смещение времени"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-time-offset-live-feedback"
				>Смещение должно состоять из цифры от -12 до 14.
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="abbr-input-group" label="Аббревиатура" label-for="abbr-offset-input">
				<b-form-input
						id="city-abbr-input"
						name="city-abbr-input"
						v-model="$v.city.abbr.$model"
						:state="validateState('abbr')"
						aria-describedby="input-city-abbr-live-feedback"
						placeholder="Введите аббревиатуру"
				></b-form-input>
				<b-form-invalid-feedback
						id="input-city-abbr-live-feedback"
				>Аббревиатура обязательна
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group id="clusters-input-group" label="Кластеры" label-for="city-clusters-input">
				<b-form-tags id="city-clusters-input" v-model="cityClusters" no-outer-focus class="mb-2">
					<template v-slot="{ tags, disabled, addTag, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
								<b-form-tag
										@remove="onClusterDeleteClick({ option: tag, removeTag: removeTag })"
										:disabled="disabled"
										variant="info"
								>
									{{ JSON.parse(tag).name }}
								</b-form-tag>
							</li>
						</ul>

						<b-dropdown class="wrap-dropdown" size="sm" variant="outline-secondary" block menu-class="w-100">
							<template #button-content>
								Добавление кластеров
							</template>
							<b-dropdown-form @submit.stop.prevent="() => {}">
								<b-form-group
										label="Поиск кластеров"
										label-for="cluster-search-input"
										label-cols-md="auto"
										class="mb-0"
										label-size="sm"
										:description="searchClusterDesc"
										:disabled="disabled"
								>
									<b-form-input
											v-model="searchClusters"
											id="cluster-search-input"
											type="search"
											size="sm"
											autocomplete="off"
											placeholder="Начните вводить название кластера"
									></b-form-input>
								</b-form-group>
							</b-dropdown-form>
							<b-dropdown-item-button
									v-for="cluster in clusterAvailableOptions"
									:key="cluster.id"
									@click="onClusterAddClick({ option: cluster, addTag: addTag, removeTag: removeTag })"
							>{{ cluster.name }}
							</b-dropdown-item-button>
							<b-dropdown-text v-if="clusterAvailableOptions.length === 0">
								Нет прав доступа, соответствующим вашим критериям
							</b-dropdown-text>
						</b-dropdown>
					</template>
				</b-form-tags>
			</b-form-group>

			<b-form-checkbox
					id="city-show-on-sites-input"
					v-model="city.show_on_sites"
					name="city-show-on-sites-input"
					value="true"
					unchecked-value="false"
			>
				Отображать город на сайтах
			</b-form-checkbox>

			<b-form-checkbox
					id="city-status-coven-input"
					v-model="city.status_coven"
					name="city-status-coven-input"
					value="true"
					unchecked-value="false"
			>
				Использовать город в coven
			</b-form-checkbox>

			<div class="form-buttons-w">
				<b-button class="btn btn-primary" type="button" variant="primary" name="button"
									v-b-modal="'confirmation-modal'">Сохранить
				</b-button>
				<b-button class="btn btn-grey" @click="backToCitiesTable">Отмена</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required, minLength, minValue, maxValue, integer} from 'vuelidate/lib/validators';
import {routeNames} from '@/router/constants';

const rusNames = value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	return /^[а-яА-Я]+(?:[\s-][а-яА-Я]+)*$/.test(value);
};

export default {
	name: 'HelpDeskCityForm',
	mixins: [validationMixin],
	validations: {
		city: {
			amo_id: {
				integer,
				minValue: minValue(1)
			},
			bfl_amo_id: {
				integer,
				minValue: minValue(1)
			},
			amo_group_id: {
				integer,
				minValue: minValue(0)
			},
			amo_group_name: {
				minLength: minLength(2),
				rusNames
			},
			name: {
				required,
				minLength: minLength(2),
				rusNames
			},
			main_name: {
				required,
				minLength: minLength(2),
				rusNames
			},
			region_name: {
				required,
				minLength: minLength(2),
				rusNames
			},
			time_offset: {
				required,
				minValue: minValue(-12),
				maxValue: maxValue(14),
				integer
			},
			abbr: {
				required
			},
		}
	},
	data() {
		return {
			city: {
				amo_id: null,
				bfl_amo_id: null,
				amo_group_id: null,
				amo_group_name: null,
				name: '',
				main_name: 'нет',
				region_name: '',
				abbr: '',
				time_offset: null,
				show_on_sites: false,
				status_coven: false,
				cluster_id: null,
			},
			cityClusters: [],
			cityOriginalClusters: [],
			clusterOptions: [],
			searchClusters: '',
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_UPDATE_CITY', 'HELPDESK_GET_CITY_FROM_API', 'HELPDESK_GET_CLUSTERS_FROM_API', 'ADD_NOTIFICATION_TO_QUERY'
		]),
		validateState(name) {
			const {$dirty, $error} = this.$v.city[name];
			return $dirty ? !$error : null;
		},

		backToCitiesTable() {
			this.$router.push({name: routeNames.helpdesk.cities.read});
		},

		onClusterAddClick({option, addTag, removeTag}) {
			if (this.cityClusters.findIndex(x => x.id === option.id) > -1) {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'info',
					title: 'Информация',
					message: 'Кластер уже добавлен'
				});
				return;
			}

			for (let i = 0; i < this.cityClusters.length; i += 1) {
				let cluster = this.cityClusters[i];
				if (typeof cluster != 'object') {
					cluster = JSON.parse(cluster);
				}
				if (cluster.type === option.type) {
					this.onClusterDeleteClick({ option: this.cityClusters[i], removeTag: removeTag });
				}
			}

			this.cityClusters.push({id: option.id, name: option.name, type: option.type});

			let id = this.clusterOptions.findIndex(x => x.id === option.id);
			if (id > -1) {
				this.clusterOptions.splice(id, 1);
			}

			addTag(JSON.stringify(option));
			this.searchClusters = '';
		},

		onClusterDeleteClick({option, removeTag}) {
			// option приходит из tag-ов в виде строки, поэтому парсим
			let clusterToRemove = JSON.parse(option);

			for (let i = 0; i < this.cityClusters.length; i += 1) {
				let cluster = this.cityClusters[i];
				if (typeof cluster != 'object') {
					cluster = JSON.parse(cluster);
				}
				if (cluster.type === clusterToRemove.type) {
					this.cityClusters.splice(i, 1);
				}
			}

			this.clusterOptions.push(clusterToRemove);
			this.clusterOptions.sort((a, b) => a.id < b.id);

			removeTag(option);
		},

		async addCityClusters() {
			this.city.cluster_id = '';

			if (this.cityClusters && this.cityClusters.length > 0) {
				let values = [];
				this.cityClusters.forEach(function callback(cityCluster) {
					let cluster = cityCluster;
					if (typeof cluster != 'object') {
						cluster = JSON.parse(cluster);
					}
					values.push(cluster.id);
				});
				this.city.cluster_id = values.join(',');
			}
		},

		async submit() {
			await this.addCityClusters();
			this.$v.city.$touch();
			if (this.$v.city.$anyError) {
				return;
			}
			await this.HELPDESK_UPDATE_CITY({id: this.$route.params.city_id, city: this.city}).then(() => {
				this.ADD_NOTIFICATION_TO_QUERY({type: 'success', title: 'Успех', message: 'Город успешно обновлён'});
				this.backToCitiesTable();
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при обновлении города'
				});
			});
		}
	},

	computed: {
		...mapGetters([
			'helpdeskCity', 'helpdeskClusters'
		]),
		clustersCriteria() {
			return this.searchClusters.trim().toLowerCase();
		},
		clusterAvailableOptions() {
			const criteria = this.clustersCriteria;
			if (criteria) {
				return this.clusterOptions.filter(opt => opt.name.toLowerCase().indexOf(criteria) > -1);
			}
			return this.clusterOptions;
		},
		searchClusterDesc() {
			if (this.clustersCriteria && this.clusterAvailableOptions.length === 0) {
				return 'Нет прав доступа с заданным именем';
			}
			return '';
		},
	},

	async mounted() {
		if (this.$route.params.city_id !== '0') {
			await this.HELPDESK_GET_CITY_FROM_API(this.$route.params.city_id).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении города'
				});
			});
			this.city = this.helpdeskCity;

			await this.HELPDESK_GET_CLUSTERS_FROM_API({}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка кластеров'
				});
			});

			const cityClusters = [];
			if (this.city.clusters && this.city.clusters.length) {
				for (let i = 0; i < this.city.clusters.length; i += 1) {
					cityClusters.push(this.city.clusters[i].id);
				}
			}

			for (let i = 0; i < this.helpdeskClusters.length; i++) {
				if (cityClusters.includes(this.helpdeskClusters[i].id)) {
					this.cityClusters.push({
						id: this.helpdeskClusters[i].id,
						name: this.helpdeskClusters[i].name,
						type: this.helpdeskClusters[i].type,
					});
				} else {
					this.clusterOptions.push({
						id: this.helpdeskClusters[i].id,
						name: this.helpdeskClusters[i].name,
						type: this.helpdeskClusters[i].type,
					});
				}
			}
		}
		this.$nextTick(() => {
			this.$v.$reset();
		});
	},
};
</script>

<style>

</style>
