<template>
	<div class="element-box">
		<h5 class="form-header">
			Консультации в филиалах
		</h5>

		<div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация филиалов"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="branches-status-filter-group" label="Статус филиалов"
															label-for="branches-status-filter">
									<b-form-select
											id="branches-status-filter"
											v-model="filters.status"
											:options="statusEnum"
									></b-form-select>
								</b-form-group>
								<b-form-group id="branches-project-filter-group" label="Проект филиалов"
															label-for="branches-project-filter">
									<b-form-select
											id="branches-project-filter"
											v-model="filters.project"
											:options="projectOptions"
									></b-form-select>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="applyFilters"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

			<table class="table table-lightborder">
				<thead>
				<tr>
					<th>
						Название
						<i class="os-icon os-icon-arrow-up2"
							v-if="filters.sort_name === null || filters.sort_name !== 'desc'"
							@click.exact="filters.sort_name='desc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
						<i class="os-icon os-icon-arrow-down"
							v-if="filters.sort_name === null || filters.sort_name !== 'asc'"
							@click.exact="filters.sort_name='asc'; applyFilters()"
							@click.ctrl="filters.sort_name=null; applyFilters()"
						></i>
					</th>
					<th>
						Статус
					</th>
					<th>
						График
					</th>
					<th>
						Консультации
					</th>
					<th>
						Записи на одно время
					</th>
					<th>
						Длительность консультаций
					</th>
					<th>
						Комментарий
					</th>
					<th>
						TG руководителя
					</th>
					<th>
						Адрес и комментрий
					</th>
					<th v-if="canUpdate">
						Действия
					</th>
				</tr>
				</thead>
				<tbody v-for="branch in helpdeskAvailableBranchesWithCityId" :key="branch.id">
				<tr>
					<td>
						{{ branch.name }}
					</td>
					<td class="text-center">
						<div v-if="branch.status === 'isAvailable'">
							<div class="status-pill green" v-b-tooltip.html.top title="Открыт"></div>
						</div>
						<div v-else-if="branch.status === 'isRemote'">
							<div class="status-pill yellow" v-b-tooltip.html.top title="Дистанция"></div>
						</div>
						<div v-else-if="branch.status === 'isUnavailable'">
							<div class="status-pill red" v-b-tooltip.html.top title="Не обслуживается"></div>
						</div>
					</td>
					<td v-if="branch.work_hours !== null" class="text-left">
						{{ branch.work_hours }}
					</td>
					<td v-else class="text-left">
						Не определены
					</td>
					<td>
						Офис: {{ branch.settings.consultations_office ? 'да' : 'нет' }}
						Онлайн: {{ branch.settings.consultations_online ? 'да' : 'нет' }}
					</td>
					<td>
						<table>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;"></td>
								<td class="pt-0 pb-1" style="border: 0;">МПК</td>
								<td class="pt-0 pb-1" style="border: 0;">БФЛ</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">ПН</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_mon_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_mon_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">ВТ</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_tue_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_tue_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">СР</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_wed_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_wed_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">ЧТ</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_thu_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_thu_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">ПТ</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_fri_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_fri_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">СБ</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_sat_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_sat_bfl }}
								</td>
							</tr>
							<tr>
								<td class="pt-0 pb-1" style="border: 0;">ВС</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_sun_mpk }}
								</td>
								<td style="border: 1px solid rgba(83, 101, 140, 0.08);">
									{{ branch.settings.consultations_on_specialist_sun_bfl }}
								</td>
							</tr>
						</table>
					</td>
					<td>
						МПК: {{ branch.settings.consultations_timeslot_mpk }} мин.<br>
						БФЛ: {{ branch.settings.consultations_timeslot_bfl }} мин.
					</td>
					<td v-html="branch.settings.office_comment" />
					<td v-html="getBranchTG(branch.settings.manager_tg)" />
					<td v-if="branch.address !== null">
						{{ branch.address }}
						<div v-if="branch.address_comment">
							{{ branch.address_comment }}
						</div>
					</td>
					<td v-else>
						Не определён
					</td>
					<td class="row-actions text-left">
						<router-link
							v-if="canUpdateBranchConsultation(branch.id)"
							v-b-tooltip.html.top
							title="Редактировать"
							:to="{ name: routeNames.helpdesk.branches.update, params: { branch_id: branch.id }, hash: '#consultations-anchor' }"
						>
							<i class="os-icon os-icon-ui-49"></i>
						</router-link>
					</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && helpdeskAvailableBranchesWithCityId.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';
import {helpdeskBranchStatusEnum} from '@/store/helpdesk/branches/constants';
import {permissionNames} from "@/store/passport/auth/constants";

export default {
	name: 'HelpDeskBranchConsultationsTable',
	computed: {
		...mapGetters([
			'helpdeskAvailableBranchesWithCityId', 'permissionAvailable', 'helpdeskCities', 'helpdeskNewCities', 'attribute',
		]),
		canUpdate() {
			const avPerms = [
				permissionNames.CAN_USE_HELPDESK_BRANCHES_API,
				permissionNames.CAN_USE_HELPDESK_BRANCH_PROJECT_BFL,
				permissionNames.CAN_USE_HELPDESK_BRANCH_CURATOR_BFL
			];
			for (const perm of avPerms) {
				if (this.permissionAvailable(perm)) {
					return true;
				}
			}
			return false;
		},
		canUseHelpdeskChangeConsultation() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_CHANGE_CONSULTATION);
		},
		canDelete() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCHES_API);
		},
		canCreate() {
			return this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_BRANCHES_API);
		},
		newCityOptions() {
			const a = [];
			for (let city of this.helpdeskNewCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
		oldCityOptions() {
			const a = [];
			for (let city of this.helpdeskCities) {
				a.push({value: city.id, text: city.name + ' - ' + city.region_name});
			}
			a.sort((a, b) => a.text.normalize().localeCompare(b.text.normalize()));
			a.unshift({value: null, text: 'Выберите город...'});
			return a;
		},
	},
	data() {
		return {
			filters: {
				sort_name: 'asc',
				status: null,
				project: 'all',
				limit: 250,
				page: 1
			},
			limits: [
				{value: 50, text: 'Филиалов на странице'},
				{value: 10, text: '10'},
				{value: 25, text: '25'},
				{value: 50, text: '50'},
				{value: 100, text: '100'},
				{value: 250, text: '250'}
			],
			projectOptions: [
				{value: 'all', text: 'Все'},
				{value: 'mpk', text: 'Призыванет'},
				{value: 'bfl', text: 'Кредитанет'},
			],
			statusEnum: [],
			canGetNext: true,
			canGetPrev: false,
			updateBranchIds: [],
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_BRANCHES_FROM_API', 'HELPDESK_DELETE_BRANCH', 'ADD_NOTIFICATION_TO_QUERY',
			'HELPDESK_GET_CITIES_FROM_API', 'HELPDESK_GET_NEW_CITIES_FROM_API'
		]),

		applyFilters() {
			//this.filters.limit=50;
			this.filters.page = 1;
			this.canGetNext = true;
			this.canGetPrev = false;
			this.getBranches();
		},

		previous() {
			if (this.filters.page > 0) {
				this.filters.page--;
				this.getBranches();
				this.canGetNext = true;
				if (this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		canUpdateBranchConsultation(id) {
			let permission = this.permissionAvailable(permissionNames.CAN_USE_HELPDESK_CHANGE_CONSULTATION);
			if (permission && this.updateBranchIds && this.updateBranchIds.length > 0) {
				return this.updateBranchIds.includes(id);
			}
			return permission;
		},

		next() {
			this.canGetPrev = true;
			this.filters.page++;
			this.getBranches();
			if (this.helpdeskAvailableBranchesWithCityId.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		getBranches() {
			return this.HELPDESK_GET_BRANCHES_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({
					type: 'error',
					title: 'Ошибка',
					message: 'Произошла ошибка при получении списка филиалов'
				});
			});
		},

		getBranchTG(s) {
			if (s) {
				return s.replace('\n', '<br>');
			}

			return '';
		}, 
	},

	async mounted() {
		for (let q in this.$route.query) {
			if (q) {
				this.filters[q] = this.$route.query[q];
			}
		}
		if (this.filters.offset > 0) {
			this.canGetPrev = true;
		}

		let attributeBranches = this.attribute('user_working_in_offices');
		if (attributeBranches && attributeBranches.length > 0) {
			this.updateBranchIds = attributeBranches.replace(' ', '').split(',').map(Number);
		};

		await Promise.all([await this.HELPDESK_GET_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка старых городов'
			});
		}), this.HELPDESK_GET_NEW_CITIES_FROM_API({limit: 1000}).catch(() => {
			this.ADD_NOTIFICATION_TO_QUERY({
				type: 'error',
				title: 'Ошибка',
				message: 'Произошла ошибка при получении списка новых городов'
			});
		}), this.getBranches()]);
		this.statusEnum.push({value: null, text: 'Выберите статус филиала'});
		for (let e in helpdeskBranchStatusEnum) {
			this.statusEnum.push(helpdeskBranchStatusEnum[e]);
		}
	},
};
</script>

<style>

</style>
