export const routeNames = {
	helpdesk: {
		main: 'HelpDesk',
		branches: {
			read: 'HelpDeskBranchTable',
			update: 'HelpDeskBranchUpdate',
			create: 'HelpDeskBranchCreate',
			consultations: 'HelpDeskBranchConsultations',
		},
		cities: {
			read: 'HelpDeskCityTable',
			update: 'HelpDeskCityUpdate',
			create: 'HelpDeskCityCreate',
		},
		siteInfo: {
			update: 'HelpDeskSiteInfoUpdate',
		},
		sites: {
			read: 'HelpDeskSitesTable',
		},
		sms: {
			send: 'HelpDeskSmsSend',
			read: 'HelpDeskTemplateTable',
			update: 'HelpDeskTemplateUpdate',
			create: 'HelpDeskTemplateCreate',
		},
		phones: {
			read: 'HelpDeskPhoneTable',
			update: 'HelpDeskPhoneUpdate',
			create: 'HelpDeskPhoneCreate',
		},
		signers: {
			read: 'HelpDeskSignerTable',
			update: 'HelpDeskSignerUpdate',
			create: 'HelpDeskSignerCreate',
		},
		priorities: {
			read: 'HelpDeskPriorityTable',
			update: 'HelpDeskPriorityUpdate',
			create: 'HelpDeskPriorityCreate',
		},
		discounts: {
			read: 'HelpDeskDiscountTable',
			update: 'HelpDeskDiscountUpdate',
			create: 'HelpDeskDiscountCreate',
		},
		tariffs: {
			read: 'HelpDeskTariffTable',
			update: 'HelpDeskTariffTableUpdate',
			create: 'HelpDeskTariffTableCreate',
		},
		hunterForm: 'HelpDeskHunterForm',
		weekends: {
			read: 'HelpDeskWeekendTable',
			create: 'HelpDeskWeekendCreate',
		},
		clusters: {
			read: 'HelpDeskClusterTable',
			update: 'HelpDeskClusterUpdate',
			create: 'HelpDeskClusterCreate',
		},
	},
	passport: {
		main: 'Passport',
		users: {
			read: 'PassportUserTable',
			update: 'PassportUserUpdate',
			create: 'PassportUserCreate',
		},
		permissions: {
			read: 'PassportPermissionTable',
			update: 'PassportPermissionUpdate',
			create: 'PassportPermissionCreate',
		},
		groups: {
			read: 'PassportGroupTable',
			update: 'PassportGroupUpdate',
			create: 'PassportGroupCreate',
		},
		scopes: {
			read: 'PassportScopeTable',
			update: 'PassportScopeUpdate',
			create: 'PassportScopeCreate',
		},
		namespaces: {
			read: 'PassportNamespaceTable',
			update: 'PassportNamespaceUpdate',
			create: 'PassportNamespaceCreate',
		},
		attributes: {
			read: 'PassportAttributeTable',
			update: 'PassportAttributeUpdate',
			create: 'PassportAttributeCreate',
		},
		schedule: {
			read: 'PassportScheduleTable',
			update: 'PassportScheduleUpdate',
		},
		offices: {
			read: 'PassportOfficesTable',
			update: 'PassportOfficesUpdate',
		},
		clusters: {
			read: 'PassportClustersTable',
			update: 'PassportClustersUpdate',
		},
	},
	helpback: {
		main: 'HelpBack',
		responsibles: {
			read: 'HelpBackResponsibleTable',
			update: 'HelpBackResponsibleUpdate',
			create: 'HelpBackResponsibleCreate',
		},
	},
	term_docgen: {
		main: 'TermDocGen',
	},
	adv_cab: {
		contacts: {
			read: 'AdvContactsTable',
		},
		deals: {
			read: 'AdvDealsTable',
			update: 'AdvDealsForm',
		}
	},
	okk: {
		main: 'OKK',
		files: {
			read: 'FilesTable'
		},
		fines: {
			read: 'FinesTable'
		}
	}
};
