<template>
  <div class="element-box">
    <h5 class="form-header">
      Таблица скидок
    </h5>
    <div class="form-desc" v-if="canUseHelpdeskDiscountApi">
      Выберите нужную скидку и нажмите на кнопку действия<br>
      <router-link :to="{ name: routeNames.helpdesk.discounts.create }">Создать новую скидку</router-link>
    </div>

    <div class="table-responsive" style>

			<div class="controls-above-table">
				<div class="row">
					<div class="col-sm-6">
						<a class="btn btn-sm btn-secondary" v-b-modal="'filters-modal'" href="#">Фильтры</a>
						<b-modal
								id="filters-modal"
								title="Фильтрация скидок"
								ok-title="Применить"
								cancel-title="Отмена"
								v-on:ok="applyFilters"
						>
							<b-form>
								<b-form-group id="discount-name-filter-group" label="Название скидки" label-for="discount-name-filter">
									<b-form-input
											id="discount-name-filter"
											v-model="filters.name"
											placeholder="Введите название"
									></b-form-input>
								</b-form-group>
							</b-form>
						</b-modal>
					</div>
					<div class="col-sm-6">

						<form class="form-inline justify-content-sm-end">
							<b-form-select
									id="helpdesk-branches-get-limit"
									name="helpdesk-branches-get-limit"
									v-model="filters.limit"
									:options="limits"
									class="form-control form-control-sm rounded bright"
									@change="HELPDESK_GET_DISCOUNTS_FROM_API(filters)"
							></b-form-select>
						</form>
					</div>
				</div>
			</div>

      <table class="table table-lightborder">
        <thead>
        <tr>
          <th>
            Название
          </th>
          <th>
            Сумма
          </th>
          <th>
            Срок действия
          </th>
          <th v-if="canUseHelpdeskDiscountApi">
            Действия
          </th>
        </tr>
        </thead>
        <tbody v-for="discount in helpdeskDiscounts" :key="discount.id">
        <tr>
					<td>
						{{ discount.name }}
					</td>
					<td>
						{{ discount.sum }}
					</td>
          <td class="text-left">
            {{ discount.expiration }}
          </td>
          <td class="row-actions text-left" v-if="canUseHelpdeskDiscountApi">
            <router-link v-b-tooltip.html.top title="Редактировать"
                         :to="{ name: routeNames.helpdesk.discounts.update, params: { discount_id: discount.id }} "><i
                class="os-icon os-icon-ui-49"></i></router-link>
            <a href="#" v-b-tooltip.html.top title="Удалить" @click="showDeleteQuestion(discount)" class="danger"><i
                class="os-icon os-icon-ui-15"></i></a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

		<div class="controls-below-table">
			<div class="table-records-info">
			</div>
			<div class="table-records-pages">
				<ul>
					<li>
						<a href="#" @click="previous" v-if="canGetPrev">Назад</a>
					</li>
					<li>
						<a href="#" @click="next" v-if="canGetNext && discounts.length === filters.limit">Далее</a>
					</li>
				</ul>
			</div>
		</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {routeNames} from '@/router/constants';

export default {
	name: 'DiscountTable',
	computed: {
		...mapGetters([
			'helpdeskDiscounts', 'canUseHelpdeskDiscountApi'
		]),
	},
	data() {
		return {
			filters: {
				name: null,
				limit: 50,
				page: 1
			},
			limits: [
				{ value: 50, text: 'Скидок на странице' },
				{ value: 10, text: '10' },
				{ value: 25, text: '25' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 250, text: '250' }
			],
			discounts: [],
			canGetNext: true,
			canGetPrev: false,
			routeNames
		};
	},
	methods: {
		...mapActions([
			'HELPDESK_GET_DISCOUNTS_FROM_API', 'HELPDESK_DELETE_DISCOUNT', 'ADD_NOTIFICATION_TO_QUERY'
		]),

		async applyFilters() {
			this.filters.limit=50;
			this.filters.page=1;
			this.canGetNext=true;
			this.canGetPrev=false;
			await this.getDiscounts();
		},

		showDeleteQuestion(discount) {
			this.$bvModal.msgBoxConfirm('Вы уверены что хотите удалить скидку '+discount.name+'?', {
				title: 'Подтвердите действие',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Да',
				cancelTitle: 'Нет',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if(value === true) {
					this.HELPDESK_DELETE_DISCOUNT({ id: discount.id, filters: this.filters }).then(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'success', title: 'Успех', message: 'Скидка успешно удалена' });
					}).catch(() => {
						this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при удалении скидки' });
					});
				}
			}).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при создании диалогового окна' });
			});
		},

		async previous() {
			if(this.filters.page > 0) {
				this.filters.page--;
				await this.getDiscounts();
				this.canGetNext = true;
				if(this.filters.page === 1) {
					this.canGetPrev = false;
				}
			}
		},

		async next() {
			this.canGetPrev = true;
			this.filters.page++;
			await this.getDiscounts();
			if(this.discounts.length < this.filters.limit) {
				this.canGetNext = false;
			}
		},

		async getDiscounts() {
			await this.HELPDESK_GET_DISCOUNTS_FROM_API(this.filters).catch(() => {
				this.ADD_NOTIFICATION_TO_QUERY({ type: 'error', title: 'Ошибка', message: 'Произошла ошибка при получении списка скидок' });
			});
			for (const discount in this.helpdeskDiscounts) {
				this.discounts.push(discount);
			}
		},
	},

	async mounted() {
		await this.getDiscounts();
	},
};
</script>

<style>

</style>
