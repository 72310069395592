import axios from 'axios';
import {VUE_APP_ADV_CAB_URL} from '@/config';

export function GET_DEALS(
	{
		contact_id,
		contact_name,
		responsible_id,
		status_id,
		name,
		created_at_from,
		created_at_to,
		pay_status,
		platform,
		public_name,
		date_post_at_from,
		date_post_at_to,
		limit,
		offset,
	}
) {
	let filter = {
		contact_id,
		contact_name,
		responsible_id,
		status_id,
		name,
		created_at_from,
		created_at_to,
		pay_status,
		platform,
		public_name,
		date_post_at_from,
		date_post_at_to,
	};
	for (let key in filter) {
		if (filter[key] === undefined || filter[key] === null || filter[key].length === 0) {
			delete filter[key];
		}
	}
	filter.limit = limit;
	filter.offset = offset;

	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals?' + new URLSearchParams(filter).toString()
	).then((resp) => {
		return {counter: resp.data.counter, deals: resp.data.deals};
	}).catch((err) => {
		return {counter: 0, deals: [], error: err};
	});
}

export function GET_DEAL({deal_id}) {
	return axios.get(
		VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString()
	).then((resp) => {
		return {deal: resp.data};
	}).catch((err) => {
		return {deal: [], error: err};
	});
}

export function CREATE_DEAL({name, contact_id}) {
	let deal = {
		name,
		contact_id
	};
	for (let key in deal) {
		if (deal[key] === undefined || deal[key] === null || deal[key].length === 0) {
			delete deal[key];
		}
	}
	return axios.post(VUE_APP_ADV_CAB_URL + '/api/v1/deals', {deal: deal}).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}

export function UPDATE_DEAL(deal_id, {
	status_id,
	responsible_id,
	platform,
	group,
	link_bot,
	post_type,
	task_plan,
	publish_date,
	publish_time,
	time_alive,
	payment_type,
	currency,
	invoice_sum,
	requisites,
	exchange_rate_usdt,
	commission_usdt,
	transfer_amount_usdt,
	paid_amount_usdt,
	payment_status,
	payment_refund,
}, createdP, updatedP, deletedP) {
	let deal = {
		status_id,
		responsible_id,
		platform,
		'public': group,
		link_bot,
		post_type,
		task_plan,
		publish_date,
		publish_time,
		time_alive,
		payment_type,
		currency,
		invoice_sum,
		requisites,
		exchange_rate_usdt,
		commission_usdt,
		transfer_amount_usdt,
		paid_amount_usdt,
		payment_status,
		payment_refund,
	};
	for (let key in deal) {
		if (['invoice_sum', 'exchange_rate_usdt', 'commission_usdt', 'transfer_amount_usdt', 'paid_amount_usdt', 'payment_refund'].includes(key)) {
			if (deal[key] === null || deal[key] === '') {
				delete deal[key];
				continue;
			}
			deal[key] = Number(deal[key].toString().replace('.', ''));
			continue;
		}
		if (deal[key] === undefined || deal[key] === null || (Array.isArray(deal[key]) && deal[key].length === 0) || deal[key] === '') {
			delete deal[key];
		}
	}
	const create_public = createdP;
	for (let i in create_public) {
		for (let key in create_public[i]) {
			if (['post_price'].includes(key)) {
				if (create_public[i][key] === null || create_public[i][key] === '') {
					delete create_public[i][key];
					continue;
				}
				create_public[i][key] = Number(create_public[i][key].toString().replace('.', ''));
			}
		}
	}
	const update_public = updatedP;
	for (let i in update_public) {
		for (let key in update_public[i]) {
			if (['post_price'].includes(key)) {
				if (update_public[i][key] === null || update_public[i][key] === '') {
					delete update_public[i][key];
					continue;
				}
				update_public[i][key] = Number(update_public[i][key].toString().replace('.', ''));
			}
		}
	}
	return axios.put(VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString(), {
		deal: deal,
		create_public: create_public,
		update_public: updatedP,
		delete_public: deletedP,
	}).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}

function dealToFormData(deal) {
	let formData = new FormData();
	if (deal.payment_invoice != null) {
		formData.append('payment_invoice', deal.payment_invoice);
	}
	if (deal.payment_receipt != null) {
		formData.append('payment_receipt', deal.payment_receipt);
	}
	return formData;
}

export function UPDATE_DEAL_FILE(deal_id, {payment_invoice, payment_receipt}) {
	const deal = {payment_invoice, payment_receipt};
	for (let key in deal) {
		if (deal[key] === undefined || deal[key] === null) {
			delete deal[key];
		}
	}

	return axios.post(VUE_APP_ADV_CAB_URL + '/api/v1/deals/' + deal_id.toString() + '/files', dealToFormData(deal), {
		headers: {
			'Content-Type': 'multipart/form-data',
		}
	}).then((resp) => {
		return {status: 200, id: resp.data.id, entity: resp.data.entity};
	}).catch((reason) => {
		if (reason.response && reason.response.status) {
			return {status: reason.response.status, statusText: reason.response.statusText};
		}
		return {status: 500, statusText: null};
	});
}
