import axios from 'axios';
import {VUE_APP_HELPDESK_API_URL} from '@/config';

export const actions = {
	async HELPDESK_TERMINAL_TERMINATE(s, {uid}) {
		return axios.post(VUE_APP_HELPDESK_API_URL + '/api/v1/terminal/terminator', {
			passport_uid: uid,
			message: 'terminate',
		});
	},
};
